import { Box, CircularProgress, FormControl, Input as MuiInput } from "@mui/material";

import React, { memo } from "react";
import IconButton from "../icon-button/icon-button";
import EmojiIcon from "../../assets/icon/emoji";
import Button from "../button/button";
import AttachIcon from "../../assets/icon/attach";
import ScheduleIcon from "../../assets/icon/schedule";
import SendIcon from "../../assets/icon/send";
import { rotate45deg } from "../../config/constant";
import { useResponsive } from "../../hooks/use-media-query";
import Fab from "../fab/fab";

function MessageInput({
  onInputClick,
  onAttachClick,
  onSendClick,
  disableSendButton,
  AITempDi,
  loading,
  onOpenMenu,
  ...props
}: any) {
  const { isTabletAndMobile } = useResponsive();

  return (
    <FormControl fullWidth={true} sx={{ mb: isTabletAndMobile ? 0 : 1 }}>
      <MuiInput
        sx={{
          background: (theme) => theme.palette.background.paper,
          borderRadius: isTabletAndMobile ? 0 : "2rem",
          borderBottomLeftRadius: "0",
          borderBottomRightRadius: "0",
          fontSize: "small",
          padding: (theme) => (isTabletAndMobile ? 0 : theme.spacing(1, 2)),
          border: "1px solid #E5E5E5",
        }}
        placeholder="Write a text for your lead"
        multiline
        rows={4}
        disableUnderline
        startAdornment={
          <Box
            sx={{
              rotate: "135deg",
              animation: `${AITempDi ? rotate45deg : ""} 2s infinite linear`,
              mr: 1,
              mb: 6,
            }}
          >
            <img
              alt="infinity logo"
              src="/images/infinitive.svg"
              style={{ width: "1.5rem", height: "1.5rem" }}
              loading="lazy"
            />
          </Box>
        }
        onClick={onInputClick}
        {...props}
      />
      <Box
        bgcolor={(theme) => theme.palette.background.paper}
        mt={-1}
        zIndex={500}
        borderRadius={isTabletAndMobile ? 0 : "0 0 2rem 2rem"}
        border="1px solid #E5E5E5"
        borderTop={"none"}
        justifyContent={"end"}
        display={"flex"}
      >
        <Box display={"flex"}>
          {false && <IconButton icon={<EmojiIcon />} disabled />}
          <IconButton icon={<AttachIcon />} onClick={onAttachClick} />
        </Box>
        <Box display={"flex"} gap={1} p={1}>
          {isTabletAndMobile
            ? false && (
                <Fab icon={<ScheduleIcon />} disabled={disableSendButton} onClick={onOpenMenu} />
              )
            : false && (
                <Button
                  variant="outlined"
                  endIcon={<ScheduleIcon />}
                  sx={{ color: "#7B7B7B", borderColor: "#7B7B7B" }}
                  onClick={onOpenMenu}
                  disabled={disableSendButton}
                >
                  Schedule
                </Button>
              )}
          {isTabletAndMobile ? (
            <Fab
              icon={loading ? <CircularProgress /> : <SendIcon />}
              onClick={onSendClick}
              disabled={disableSendButton}
              color="primary"
            />
          ) : (
            <Button
              variant="contained"
              endIcon={loading ? <CircularProgress /> : <SendIcon />}
              onClick={onSendClick}
              disabled={disableSendButton}
            >
              Send
            </Button>
          )}
        </Box>
      </Box>
    </FormControl>
  );
}

export default memo(MessageInput);
