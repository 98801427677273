import * as React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import AllIcon from "../assets/icon/all-icon";

import { useDispatch, useSelector } from "react-redux";
import {
  allConLengthSet,
  feedBackDialogToggled,
  getAllConLength,
  getSortValue,
  sortValueSet,
} from "../redux/userSlice";
import { Box, Divider, Tooltip, Typography, styled, useTheme } from "@mui/material";
import { getUid } from "../redux/authSlice";
import { getDataByQL, getDataL } from "../utils/firebase";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useLocation, useNavigate } from "react-router";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import DirectionsCarRoundedIcon from "@mui/icons-material/DirectionsCarRounded";
import ContactUs from "../sections/dashboard/contact-us";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ModelTrainingRoundedIcon from "@mui/icons-material/ModelTrainingRounded";
import AppointmentIcon from "../assets/icon/appointment-icon";
import HotIcon from "../assets/icon/hot";
import ReengagedIcon from "../assets/icon/reengaged";
import ContactAttemptedIcon from "../assets/icon/contact-attempted-icon";
import StarFillIcon from "../assets/icon/star-fill";
import { useResponsive } from "../hooks/use-media-query";

const CustomListItemButton = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  width: 48,
  height: 58,
  borderRadius: 4,
  cursor: "pointer",
  alignItems: "center",
}));

const CustomListItemIcon = styled(ListItemIcon)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignSelf: "center",
}));

const LeftSidebar = ({ openSidebar }: any) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const sortValue = useSelector(getSortValue);
  const uid = useSelector(getUid);
  const allLength = useSelector(getAllConLength);
  const navigate = useNavigate();

  const { isTabletAndMobile } = useResponsive();

  const { pathname } = useLocation();
  const [contactOpen, setOpenContact] = React.useState(false);
  const [appointmentLength, setAppointmentLength] = React.useState(0);
  const [hotLength, setHotLength] = React.useState(0);
  const [contactAttemptedLength, setContactAttemptedLength] = React.useState(0);
  const [reEngaged, setReEngaged] = React.useState(0);
  const [start, setStar] = React.useState(0);

  function getData() {
    getDataL(`conversations/${uid}`).then((r: any) => dispatch(allConLengthSet(r)));
    getDataByQL(`conversations/${uid}`, "appointmentIsSet", true).then((r: any) =>
      setAppointmentLength(r)
    );
    getDataByQL(`conversations/${uid}`, "leadState", "hot").then((r: any) => setHotLength(r));
    getDataByQL(`conversations/${uid}`, "leadState", "contactAttemped").then((r: any) =>
      setContactAttemptedLength(r)
    );
    getDataByQL(`conversations/${uid}`, "leadState", "cold").then((r: any) => setReEngaged(r));
    getDataByQL(`conversations/${uid}`, "bookMarked", true).then((r: any) => setStar(r));
  }

  React.useEffect(() => {
    getData();
  }, []);

  const showSidebar =
    pathname === "/appointment" ||
    pathname === "/report" ||
    pathname === "/setting" ||
    pathname === "/cars" ||
    pathname === "/training-request";

  const handleClick = (status: string) => {
    if (sortValue === status) {
      dispatch(sortValueSet("All Message"));
    } else {
      dispatch(sortValueSet(status));
    }
  };

  const handleContact = () => {
    setOpenContact(!contactOpen);
  };

  const handleFeedback = () => {
    dispatch(feedBackDialogToggled());
  };

  return (
    <React.Fragment>
      {!showSidebar && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={openSidebar ? "self-start" : "center"}
          gap={2}
        >
          {allLength !== 0 && (
            <CustomListItemButton
              onClick={() => handleClick("All Message")}
              sx={{
                backgroundColor:
                  sortValue === "All Message" ? theme.palette.primary.contrastText : "",
              }}
            >
              <Tooltip title="All Message">
                <CustomListItemIcon>
                  <AllIcon fill={sortValue === "All Message" ? theme.palette.info.dark : ""} />
                  <Typography variant="caption">{allLength}</Typography>
                </CustomListItemIcon>
              </Tooltip>
              {openSidebar && <ListItemText primary="All" sx={{ color: "#0000008c" }} />}
            </CustomListItemButton>
          )}
          {appointmentLength !== 0 && (
            <CustomListItemButton
              onClick={() => handleClick("Appointment")}
              sx={{
                backgroundColor:
                  sortValue === "Appointment" ? theme.palette.primary.contrastText : "",
              }}
            >
              <Tooltip title="Appointments">
                <CustomListItemIcon>
                  <AppointmentIcon
                    sx={{
                      color: sortValue === "Appointment" ? theme.palette.primary.main : "#868686",
                    }}
                  />
                  <Typography variant="caption">{appointmentLength}</Typography>
                </CustomListItemIcon>
              </Tooltip>

              {openSidebar && <ListItemText primary="Appointments" sx={{ color: "#0000008c" }} />}
            </CustomListItemButton>
          )}
          {hotLength !== 0 && (
            <CustomListItemButton
              onClick={() => handleClick("Hot")}
              sx={{
                backgroundColor: sortValue === "Hot" ? theme.palette.primary.contrastText : "",
              }}
            >
              <Tooltip title="Hot Leads">
                <CustomListItemIcon>
                  <HotIcon fill={sortValue === "Hot" ? theme.palette.info.dark : ""} />
                  <Typography variant="caption">{hotLength}</Typography>
                </CustomListItemIcon>
              </Tooltip>
              {openSidebar && <ListItemText primary="Hot Leads" sx={{ color: "#0000008c" }} />}
            </CustomListItemButton>
          )}
          {reEngaged !== 0 && (
            <CustomListItemButton
              onClick={() => handleClick("Not Engaged")}
              sx={{
                backgroundColor:
                  sortValue === "Not Engaged" ? theme.palette.primary.contrastText : "",
              }}
            >
              <Tooltip title="Re-Engaged">
                <CustomListItemIcon>
                  <ReengagedIcon
                    fill={sortValue === "Not Engaged" ? theme.palette.info.dark : ""}
                  />
                  <Typography variant="caption">{reEngaged}</Typography>
                </CustomListItemIcon>
              </Tooltip>
              {openSidebar && <ListItemText primary="Re-Engaged" sx={{ color: "#0000008c" }} />}
            </CustomListItemButton>
          )}
          {contactAttemptedLength !== 0 && (
            <CustomListItemButton
              onClick={() => handleClick("contact attempted")}
              sx={{
                backgroundColor:
                  sortValue === "contact attempted" ? theme.palette.primary.contrastText : "",
              }}
            >
              <Tooltip title="Contact Attempted">
                <CustomListItemIcon>
                  <ContactAttemptedIcon
                    fill={sortValue === "contact attempted" ? theme.palette.info.dark : ""}
                  />
                  <Typography variant="caption">{contactAttemptedLength}</Typography>
                </CustomListItemIcon>
              </Tooltip>
              {openSidebar && (
                <ListItemText primary="Contact Attempted" sx={{ color: "#0000008c" }} />
              )}
            </CustomListItemButton>
          )}
          {start !== 0 && (
            <CustomListItemButton
              onClick={() => handleClick("bookMarked")}
              sx={{
                backgroundColor:
                  sortValue === "bookMarked" ? theme.palette.primary.contrastText : "",
              }}
            >
              <Tooltip title="Book Marked">
                <CustomListItemIcon>
                  <StarFillIcon fill={sortValue === "bookMarked" ? theme.palette.info.dark : ""} />
                  <Typography variant="caption">{start}</Typography>
                </CustomListItemIcon>
              </Tooltip>
              {openSidebar && <ListItemText primary="Book Marked" sx={{ color: "#0000008c" }} />}
            </CustomListItemButton>
          )}
          <Divider />
        </Box>
      )}

      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        {isTabletAndMobile && (
          <>
            <Divider />
            <CustomListItemButton onClick={() => navigate("/cars")}>
              <CustomListItemIcon>
                <DirectionsCarRoundedIcon color="primary" />
                <Typography variant="caption">Cars</Typography>
              </CustomListItemIcon>
            </CustomListItemButton>
            <CustomListItemButton onClick={() => navigate("/setting")}>
              <CustomListItemIcon>
                <SettingsRoundedIcon color="primary" />
                <Typography variant="caption">Setting</Typography>
              </CustomListItemIcon>
            </CustomListItemButton>
            <CustomListItemButton onClick={handleFeedback}>
              <CustomListItemIcon>
                <FeedbackIcon color="primary" />
                <Typography variant="caption">Feedback</Typography>
              </CustomListItemIcon>
            </CustomListItemButton>
            <Divider />
          </>
        )}
        <CustomListItemButton onClick={() => navigate("/training-request")}>
          <CustomListItemIcon>
            <ModelTrainingRoundedIcon color="primary" />
            <Typography variant="caption">Training</Typography>
          </CustomListItemIcon>
        </CustomListItemButton>
        <CustomListItemButton onClick={handleContact}>
          <CustomListItemIcon>
            <ContactMailIcon color="primary" />
            <Typography variant="caption">Contact us</Typography>
          </CustomListItemIcon>
        </CustomListItemButton>
      </Box>

      {contactOpen && <ContactUs open={contactOpen} handleClose={() => setOpenContact(false)} />}
    </React.Fragment>
  );
};

export default LeftSidebar;
