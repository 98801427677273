import React, { useEffect, useRef, useState } from "react";

interface LazyImageProps {
  src: string; // URL of the high-quality image
  alt: string; // Alternative text for the image
  placeholder: string; // URL of the placeholder image
  [key: string]: any; // Allows for any additional props
}

const LazyImage: React.FC<LazyImageProps> = ({ src, alt, placeholder, ...props }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null); // Explicitly allow null

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (imgRef.current) {
            // Check if imgRef.current is not null
            observer.unobserve(imgRef.current);
          }
        }
      });
    });

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <>
      <img
        ref={imgRef}
        src={isVisible ? src : placeholder} // Load actual image when visible
        alt={alt}
        style={{
          width: 150,
          height: 123,
          borderRadius: 5,
        }}
        {...props} // Spread any additional props
      />
    </>
  );
};

export default LazyImage;
