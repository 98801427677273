import { Box, Typography } from "@mui/material";
import React from "react";
import Dialog from "../../components/dialog/dialog";
import Button from "../../components/button/button";

import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import CustomizedProgressBars from "../../components/progress/progress-bar";
import PdfFileIcon from "../../assets/icon/pdf-file-icon";

export default function UploadFile({
  open,
  handleClose,
  onMessageHandler,
  fileInfo,
  objectUrl,
  upload,
  uploadProgressValue,
}: any) {
  const renderTitle = "Attach File";

  const renderContent = (
    <Box width={"100%"}>
      <Box
        alignContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        border={"1px solid #D9D9D9"}
        borderRadius={"8px"}
        m={1}
        textAlign={"center"}
        p={1}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"}>
            {fileInfo?.type === "application/pdf" ? (
              <PdfFileIcon />
            ) : (
              <img src={objectUrl} width={"50px"} height={"50px"} />
            )}
            <Box display={"flex"} flexDirection={"column"} pl={1}>
              <Typography variant="caption">{fileInfo.name}</Typography>
              <Typography textAlign={"left"} variant="caption" color={"#6D6D6D"}>
                {(parseInt(fileInfo?.size) / 1024).toFixed(2)} kb
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box p={1} gap={1} display={"flex"} flexDirection={"column"}>
          <Typography fontWeight={700} variant="body2">
            uploading {Math.round(uploadProgressValue)}%
          </Typography>
          <CustomizedProgressBars value={uploadProgressValue} />
        </Box>
      </Box>
    </Box>
  );

  const renderActions = () => {
    return (
      <Box gap={1} display={"flex"} justifyContent={"center"} width={"100%"}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          startIcon={<UploadRoundedIcon />}
          onClick={onMessageHandler}
          disabled={upload}
        >
          Send Attachment
        </Button>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      fullWidth
      renderContent={renderContent}
      renderTitle={renderTitle}
      handleClose={handleClose}
      renderActions={renderActions()}
    />
  );
}
