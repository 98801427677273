import React, { memo, useEffect } from "react";
import { Box, Chip, Grid, List, Typography } from "@mui/material";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import {
  getIsOpenChat,
  handleOpenChat,
  handleSearch,
  activeConIdSet,
  getSortValue,
  openCreateLeadToggled,
  sortValueSet,
} from "../../redux/userSlice";
import { Virtuoso } from "react-virtuoso";

import {
  child,
  endBefore,
  equalTo,
  get,
  limitToLast,
  onValue,
  orderByChild,
  query,
  ref,
  update,
} from "firebase/database";
import { getUid } from "../../redux/authSlice";
import { dbRef, firebaseDb } from "../../firebase/app";
import Snackbar from "../../components/snackbar/snackbar";
import { useLocation } from "react-router";
import ConversationItem from "./conversation-item";
import PageLoading from "../../components/pageLoading/pageLoading";
import SearchDialog from "./search-dialog";

import Fab from "../../components/fab/fab";
import FilterIcon from "../../assets/icon/filter";
import RenderFilters from "./render-filters-modal";
import { useResponsive } from "../../hooks/use-media-query";
import ControlledInput from "../../components/input/controlled-input";

const ConversationList = ({ setCurrentUser }: any) => {
  // State
  const [conversation, setConversation] = React.useState<any | null>([]);
  const [lastKey, setLastKey]: any = useState(null);
  const [orderBy, setOrderBy]: any = useState("lastMessage/timestamp");
  const [equal, setEqual]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [enableSearch, setEnableSearch] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { isTabletAndMobile } = useResponsive();

  const [error, setError] = useState({
    message: "",
    open: false,
    type: "",
  });

  // get conversation from path
  const { pathname } = useLocation();
  const cId = pathname.replace("/", "").trim();

  // Selector
  const sortValue = useSelector(getSortValue);
  const isOpenChat = useSelector(getIsOpenChat);
  const uid = useSelector(getUid);

  const dispatch = useDispatch();

  function handleSelectItem(pushId: string) {
    handleReadConversation(pushId);
  }

  const loadMoreItems = () => {
    fetchItems(conversation.length + 10, orderBy, equal);
  };

  const fetchItems = (limit = 10, orderBy: any, equal: any) => {
    let databaseRef = query(
      ref(firebaseDb, `conversations/${uid}`),
      orderByChild(orderBy),
      limitToLast(limit)
    );

    if (equal || equal === false) {
      databaseRef = query(databaseRef, equalTo(equal));
    }

    if (equal !== false && !equal && lastKey) {
      databaseRef = query(databaseRef, endBefore(lastKey));
    }

    onValue(
      databaseRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const values: any = [];
          snapshot.forEach(function (childSnapshot) {
            const childKey = childSnapshot.key;
            const childData = childSnapshot.val();
            if (!childData.lastMessage) return;
            childData["pushId"] = childKey;
            values.unshift(childData);
          });

          values.sort(
            (
              a: { lastMessage: { timestamp: number } },
              b: { lastMessage: { timestamp: number } }
            ) => b.lastMessage.timestamp - a.lastMessage.timestamp
          );

          setConversation(values);
          setLoading(false);
          setLastKey(Object.keys(data)[0]);
        } else setLoading(false);
      },
      (error: any) => console.log("get conversation", error)
    );
  };

  const handleGetItem = () => {
    if (cId)
      get(child(dbRef, `conversations/${uid}/${cId}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setCurrentUser(data);
            dispatch(handleOpenChat(true));
            dispatch(activeConIdSet(cId));
            handleReadConversation(cId);
          } else {
            console.log("No data available");
            dispatch(handleOpenChat(false));
            setError({
              open: true,
              message: "Conversation not found",
              type: "error",
            });
          }
        })
        .catch(() => {
          dispatch(handleOpenChat(false));
          setError({
            open: true,
            message: "Conversation not found",
            type: "error",
          });
        });
  };

  function handleReadConversation(activeConId: string) {
    dispatch(handleSearch(""));
    const conversationRef = ref(firebaseDb, `conversations/${uid}/${activeConId}`);
    update(conversationRef, { read: true });
  }

  useEffect(() => {
    fetchItems(10, "lastMessage/timestamp", null);
    handleGetItem();
  }, []);

  const renderChatItems = (data: any[]) => {
    if (data?.length === 0)
      return (
        <Typography fontWeight={700} textAlign={"center"} my={"2rem"}>
          No Leads Found
        </Typography>
      );

    const renderItem = (index: any | number) => (
      <ConversationItem
        key={data[index].pushId}
        index={data[index].pushId}
        con={data[index]}
        handleSelectItem={handleSelectItem}
        setCurrentUser={setCurrentUser}
      />
    );

    return (
      <Virtuoso
        style={{ height: "100%" }}
        data={data}
        itemContent={renderItem}
        atBottomStateChange={(e: any) => {
          if (e) loadMoreItems();
          else console.log(e);
        }}
      />
    );
  };

  useEffect(() => {
    switch (sortValue) {
      case "All Message":
        setOrderBy("lastMessage/timestamp");
        setEqual(null);
        fetchItems(10, "lastMessage/timestamp", null);
        break;
      case "Hot":
        setOrderBy("leadState");
        setEqual("hot");
        fetchItems(10, "leadState", "hot");
        break;
      case "Appointment":
        setOrderBy("appointmentIsSet");
        setEqual(true);
        fetchItems(10, "appointmentIsSet", true);
        break;
      case "contact attempted":
        setEqual("contactAttemped");
        setOrderBy("leadState");
        fetchItems(10, "leadState", "contactAttemped");
        break;
      case "Not Engaged":
        setOrderBy("leadState");
        setEqual("cold");
        fetchItems(10, "leadState", "cold");
        break;
      case "AIMode":
        setOrderBy("AIMode");
        setEqual(false);
        fetchItems(10, "AIMode", false);
        break;
      case "callRequest":
        setOrderBy("callRequest");
        setEqual(true);
        fetchItems(10, "callRequest", true);
        break;
      case "followUpEnabled":
        setOrderBy("followUpEnabled");
        setEqual(false);
        fetchItems(10, "followUpEnabled", false);
        break;
      case "bookMarked":
        setOrderBy("bookMarked");
        setEqual(true);
        fetchItems(10, "bookMarked", true);
        break;
      default:
        break;
    }
  }, [sortValue]);

  const handleClick = () => {
    dispatch(sortValueSet("All Message"));
  };

  const renderFilter = (
    <Fab icon={<FilterIcon />} color="white" size="small" onClick={() => setOpen(!open)} />
  );

  const sortValueHandler = () => {
    if (sortValue === "followUpEnabled") return "Follow Up Disabled";
    else if (sortValue === "callRequest") return "Call Back Request";
    else if (sortValue === "AIMode") return "A.I. Mode Disabled";
    else if (sortValue === "contact attempted") return "Contact Attempted";
    else if (sortValue === "Hot") return "Hot Lead";
    else if (sortValue === "Not Engaged") return "Re-Engaged";
    else if (sortValue === "bookMarked") return "Book Marked";
    else return sortValue;
  };

  return (
    <Grid
      item
      xs={isOpenChat ? false : 12}
      md={5}
      lg={4}
      width={"100%"}
      height={"100%"}
      sx={{
        display: { xs: isOpenChat ? "none" : "flex", md: "flex" },
        flexDirection: "column",
        justifyContent: "start",
        alignContent: "start",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      {loading ? (
        <PageLoading />
      ) : (
        <>
          {isTabletAndMobile ? (
            <Box display={"flex"} justifyContent={"space-around"} alignItems={"center"} gap={1}>
              <Box
                display={"flex"}
                component={"div"}
                onClick={() => setEnableSearch(!enableSearch)}
                flex={1}
                mb={-1}
              >
                <ControlledInput placeholder={"VIN, Phone, Name, Email"} />
              </Box>
              <Box display={"flex"} gap={1}>
                {renderFilter}
                <Fab
                  icon={<AddRoundedIcon color="info" />}
                  color="primary"
                  size="small"
                  onClick={() => dispatch(openCreateLeadToggled())}
                />
              </Box>
            </Box>
          ) : (
            <Box
              display={"flex"}
              justifyContent={"space-around"}
              alignItems={"center"}
              px={1}
              gap={1}
            >
              <Box
                display={"flex"}
                component={"div"}
                onClick={() => setEnableSearch(!enableSearch)}
                flex={1}
              >
                <ControlledInput placeholder={"VIN, Phone, Name, Email"} />
              </Box>
              {renderFilter}
            </Box>
          )}
          {sortValue !== "All Message" && (
            <Box display={"flex"}>
              <Chip label={sortValueHandler()} variant="outlined" onDelete={handleClick} />
            </Box>
          )}
          <List sx={{ width: "100%", height: "100%" }}>{renderChatItems(conversation)}</List>
        </>
      )}
      <Snackbar
        handleClose={() => setError({ message: "", open: false, type: "" })}
        open={error.open}
        message={error.message}
        type={error.type}
      />
      <SearchDialog open={enableSearch} handleClose={() => setEnableSearch(!enableSearch)} />
      <RenderFilters open={open} setOpen={() => setOpen(!open)} />
    </Grid>
  );
};

export default memo(ConversationList);
