import { Box, Typography } from "@mui/material";
import React from "react";

export default function RenderCircle({ length, ...props }: any) {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      width={18}
      height={18}
      borderRadius={"50%"}
      bgcolor={(theme) => theme.palette.primary.main}
      {...props}
    >
      <Typography variant="caption" color={"white"}>
        {length}
      </Typography>
    </Box>
  );
}
