import React from "react";
import Dialog from "../../components/dialog/dialog";
import { Box, Typography } from "@mui/material";
import { CustomBox } from "../../components/box/custom-box";

import CopyIcon from "../../assets/icon/copy";
import Button from "../../components/button/button";
import { handleCopyClipboard } from "../../utils/utils";

export default function SendToCrm({ open, handleClose }: any) {
  const renderTitle = (
    <Box>
      <Typography fontWeight={700} variant="body1">
        Conversation Link
      </Typography>
      <Typography variant="body2" color={"#686868"}>
        You can copy the conversation link and paste it into your CRM under this customer.
      </Typography>
    </Box>
  );
  const renderContent = (
    <CustomBox display={"flex"} width={"100%"} p={1} borderRadius={3} my={1}>
      <Box display={"flex"} alignItems={"center"} gap={1}>
        <CopyIcon />
        <Box>
          <Typography
            variant="body2"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            fontWeight={500}
          >
            Conversation Link
          </Typography>
          <Typography
            variant="caption"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            fontWeight={500}
            color={"#8F92A1"}
          >
            {window.location.href}
          </Typography>
        </Box>
      </Box>
    </CustomBox>
  );

  const renderActions = (
    <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
      <Button variant="outlined">Cancel</Button>
      <Button
        startIcon={<CopyIcon />}
        onClick={() => {
          handleCopyClipboard(window.location.href);
          handleClose();
        }}
        variant="contained"
      >
        copy to clipboard
      </Button>
    </Box>
  );

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      fullWidth
      renderContent={renderContent}
      renderTitle={renderTitle}
      renderActions={renderActions}
    />
  );
}
