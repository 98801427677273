import { Box, SelectChangeEvent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Selector from "../../components/select/select";
import CustomizedSwitches from "../../components/switch/customize-switch";
import {
  convertTo24Hour,
  generateClock,
  handleChangeFormat,
  transformArrays,
} from "../../utils/utils";
import Button from "../../components/button/button";
import ControlledBox from "../../components/box/controlled-box";
import axios from "axios";
import { useSelector } from "react-redux";
import { getToken, getUid } from "../../redux/authSlice";
import { getData } from "../../utils/firebase";
import Snackbar from "../../components/snackbar/snackbar";

const initialValue = [
  {
    day: "Monday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
  {
    day: "Tuesday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
  {
    day: "Wednesday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
  {
    day: "Thursday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
  {
    day: "Friday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
  {
    day: "Saturday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
  {
    day: "Sunday",
    start: "8:00",
    end: "5:00",
    morning: "am",
    midnight: "pm",
    closed: false,
    appointment_only: false,
  },
];

export default function BusinessHours() {
  const [week, setWeek]: any = React.useState(initialValue);
  const [isUpdate, setIsUpdate]: any = React.useState(false);

  const token = useSelector(getToken);
  const uid = useSelector(getUid);
  const [notify, setNotify] = useState({ message: "", open: false, type: "" });

  const handleGetData = (data: any) => {
    if (!data) return;
    const { appointmentOnly, workingHours } = data;
    const updateDate = transformArrays(workingHours, appointmentOnly);
    setWeek(updateDate);
    setIsUpdate(true);
  };

  useEffect(() => {
    getData(`dealers/${uid}/info`).then((r: any) => handleGetData(r));
  }, []);

  const postData = async () => {
    // Create an Axios instance with a base URL
    const apiClient = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL, // Replace with your base URL
      headers: {
        Authorization: ` ${token}`, // Replace with your actual token
        "Content-Type": "application/json",
      },
    });
    const value = [
      {
        section: "time_operation",
        data: {
          days: handleChangeFormat(week),
        },
      },
    ];

    try {
      if (isUpdate) await apiClient.put("", value);
      else await apiClient.post("", value);
      setNotify({ message: "success", open: true, type: "" });
    } catch (error: any) {
      setNotify({ message: error.response?.data?.error, open: true, type: "error" });
    }
  };

  const handleChangeDay = (day: string, updates: object) => {
    setWeek((prevWeek: any) =>
      prevWeek.map((item: any) => (item.day === day ? { ...item, ...updates } : item))
    );
  };

  const handleChange = (status: string, day: string) => {
    setWeek((prevWeek: any) =>
      prevWeek.map((item: any) => (item.day === day ? { ...item, [status]: !item[status] } : item))
    );
  };

  return (
    <>
      <Typography variant="h6" fontWeight={700}>
        Hours of Operations
      </Typography>
      <ControlledBox>
        {week?.map((w: any, id: any) => (
          <Box key={id} display={"flex"}>
            <Box
              width={113}
              height={80}
              border={"1px solid #E3E3E3"}
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              gap={1}
              borderRadius={1}
              p={1}
              my={1}
            >
              <Typography display={"flex"} variant="body2" color={"#7D7F80"}>
                {w.day}
              </Typography>
              <Typography display={"flex"} variant="body2" color={"#7D7F80"}>
                {convertTo24Hour(w?.start, w?.morning)} - {convertTo24Hour(w?.end, w?.midnight)}
              </Typography>
            </Box>
            <Box ml={1}>
              <Typography>From</Typography>
              <Box display={"flex"} gap={1}>
                <Selector
                  disabled={w.closed}
                  data={generateClock()}
                  width={85}
                  value={w.start}
                  onChange={(e: SelectChangeEvent) =>
                    handleChangeDay(w.day, {
                      start: e.target.value,
                      morning: w.morning,
                      end: w.end,
                      midnight: w.midnight,
                    })
                  }
                />
                <Selector
                  data={["am", "pm"]}
                  disabled={w.closed}
                  width={85}
                  value={w.morning}
                  onChange={(e: SelectChangeEvent) =>
                    handleChangeDay(w.day, {
                      start: w.start,
                      morning: e.target.value,
                      end: w.end,
                      midnight: w.midnight,
                    })
                  }
                />
              </Box>
            </Box>
            <Box ml={2}>
              <Typography>To</Typography>
              <Box display={"flex"} gap={1}>
                <Selector
                  data={generateClock()}
                  width={85}
                  value={w.end}
                  disabled={w.closed}
                  onChange={(e: SelectChangeEvent) =>
                    handleChangeDay(w.day, {
                      start: w.start,
                      morning: w.morning,
                      end: e.target.value,
                      midnight: w.midnight,
                    })
                  }
                />
                <Selector
                  data={["am", "pm"]}
                  width={85}
                  value={w.midnight}
                  disabled={w.closed}
                  onChange={(e: SelectChangeEvent) =>
                    handleChangeDay(w.day, {
                      start: w.start,
                      morning: w.morning,
                      end: w.end,
                      midnight: e.target.value,
                    })
                  }
                />
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} mx={1} alignItems={"center"}>
              <Box mr={1}>
                <Typography variant="body2" fontWeight={"bold"}>
                  Appointment Only
                </Typography>
              </Box>
              <CustomizedSwitches
                name="appointment_only"
                checked={w.appointment_only}
                onChange={() => handleChange("appointment_only", w.day)}
              />
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} mx={1} alignItems={"center"}>
              <Box mr={1}>
                <Typography variant="body2" fontWeight={"bold"}>
                  Closed
                </Typography>
              </Box>
              <CustomizedSwitches
                onChange={() => handleChange("closed", w.day)}
                checked={w.closed}
                name="closed"
              />
            </Box>
          </Box>
        ))}
      </ControlledBox>
      <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
        <Button variant="outlined" color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={postData}>
          Save Change
        </Button>
      </Box>
      <Snackbar
        handleClose={() => setNotify({ message: "", open: false, type: "" })}
        message={notify.message}
        open={notify.open}
        type={notify.type}
      />
    </>
  );
}
