import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import Button from "./button/button";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <Stack
      alignItems={"center"}
      display={"flex"}
      height={"50vh"}
      justifyContent={"center"}
      padding={"2rem"}
      spacing={"2rem"}
      bgcolor={"rgb(245, 245, 245)"}
    >
      <Box>
        <img
          src={"/images/drivee-logo-white.svg"}
          alt="user profile"
          width={"200px"}
          loading="lazy"
        />
      </Box>
      <Typography align="center" fontWeight={700} gutterBottom variant="h5">
        Page not found!
      </Typography>
      <Button
        onClick={() => navigate("/login")}
        color="primary"
        sx={{
          color: "white",
          fontSize: 12,
          fontStyle: "normal",
          fontWeight: 400,
        }}
        variant="contained"
      >
        Login
      </Button>
    </Stack>
  );
}
