import { SvgIcon } from "@mui/material";
import React from "react";

export default function YearIcon() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0.000109703 9.5235 0.0338043 9.0476 0.100833 8.57583L2.57583 8.92917C2.52545 9.28389 2.50011 9.64172 2.5 10C2.5 11.4834 2.93987 12.9334 3.76398 14.1668C4.58809 15.4001 5.75943 16.3614 7.12987 16.9291C8.50032 17.4968 10.0083 17.6453 11.4632 17.3559C12.918 17.0665 14.2544 16.3522 15.3033 15.3033C16.3522 14.2544 17.0665 12.918 17.3559 11.4632C17.6453 10.0083 17.4968 8.50032 16.9291 7.12987C16.3614 5.75943 15.4001 4.58809 14.1668 3.76398C12.9334 2.93987 11.4834 2.5 10 2.5C9.64172 2.50011 9.28389 2.52545 8.92917 2.57583L8.57583 0.100833C9.0476 0.0338043 9.5235 0.000109703 10 0C12.6513 0.00286757 15.1932 1.05736 17.0679 2.9321C18.9426 4.80684 19.9971 7.34872 20 10ZM7.07917 3.09L6.10417 0.7875C5.31441 1.12278 4.57108 1.5583 3.8925 2.08333L5.42083 4.06C5.92967 3.66681 6.48704 3.3408 7.07917 3.09ZM4.06083 5.41667L2.08333 3.89083C1.55843 4.56965 1.12318 5.31327 0.788333 6.10333L3.09 7.07833C3.34091 6.48505 3.66721 5.92657 4.06083 5.41667ZM8.75 5V8.75H5.83333V11.25H8.90833C9.22493 11.5287 9.63578 11.6763 10.0573 11.6629C10.4789 11.6495 10.8795 11.476 11.1778 11.1778C11.476 10.8795 11.6495 10.4789 11.6629 10.0573C11.6763 9.63578 11.5287 9.22493 11.25 8.90833V5H8.75Z"
        fill="#07A4FC"
      />
    </SvgIcon>
  );
}
