import { Menu as MuiMenu } from "@mui/material";
import React from "react";

type PropTypes = {
  anchorEl: any;
  handleClose: any;
  children: React.ReactNode;
};

export default function Menu(props: PropTypes) {
  const { anchorEl, handleClose, children } = props;

  return (
    <MuiMenu
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      id="menu-appbar"
      keepMounted
      onClose={handleClose}
      open={Boolean(anchorEl)}
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 1,
        "& .MuiPaper-root": {
          borderRadius: "20px",
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      {children}
    </MuiMenu>
  );
}
