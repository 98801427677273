import { SvgIcon } from "@mui/material";
import React from "react";

export default function EmailIcon() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      fontSize="large"
      sx={{
        color: (theme) => theme.palette.text.primary,
      }}
    >
      <rect width="32" height="32" rx="8" fill="#D0D0D0" fillOpacity="0.31" />
      <path d="M10.8 8.13348C9.26264 8.13348 8 9.39612 8 10.9335V18.1335C8 19.6708 9.26264 20.9335 10.8 20.9335H21.2C22.7374 20.9335 24 19.6708 24 18.1335V10.9335C24 9.39612 22.7374 8.13348 21.2 8.13348H10.8ZM21.2516 9.73668L16.725 14.2164C16.2326 14.704 15.7674 14.704 15.275 14.2164L10.7484 9.73668C14.3374 9.73028 18.0228 9.73268 21.2516 9.73668ZM9.60469 10.8538L14.1484 15.3538C15.1716 16.3669 16.8284 16.3669 17.8516 15.3538L22.3953 10.8538C22.4057 13.2721 22.4 15.7089 22.4 18.1335C22.4 18.8121 21.8787 19.3335 21.2 19.3335H10.8C10.1214 19.3335 9.60003 18.8121 9.60003 18.1335C9.60492 15.7096 9.59101 13.2714 9.60469 10.8538Z" />
    </SvgIcon>
  );
}
