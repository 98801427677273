import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../components/button/button";
import { getData } from "../../utils/firebase";
import { useSelector } from "react-redux";
import { getUid } from "../../redux/authSlice";
import { getActiveConId } from "../../redux/userSlice";
import { push, ref } from "firebase/database";
import { firebaseDb } from "../../firebase/app";
import { formatTimestamp } from "../../utils/time";
import Textarea from "../../components/textarea/textarea";
import Dialog from "../../components/dialog/dialog";

export default function GuideAI({ onClose, open }: any) {
  const [guideMode, setGuideMode] = useState("general");
  const uid = useSelector(getUid);
  const activeConId = useSelector(getActiveConId);
  const [state, setState]: any = useState({});
  const [askCar, setAskCar]: any = useState();
  const [askGeneral, seAskGeneral]: any = useState();

  useEffect(() => {
    if (!open) return;
    getLeadGeneralData();
    getLeadCarData();
  }, [open]);

  const getLeadCarData = () => {
    getData(`conversations/${uid}/${activeConId}/askCar`).then((r: any) => {
      if (r) {
        setAskCar(r);
        setGuideMode("askCar");
      }
    });
  };

  const getLeadGeneralData = () => {
    getData(`conversations/${uid}/${activeConId}/askGeneral`).then((r: any) => {
      if (r) {
        seAskGeneral(r);
        setGuideMode("general");
      }
    });
  };

  const sendDataToGeneral = (to: string, qId: string) => {
    push(ref(firebaseDb, `conversations/${uid}/${activeConId}/${to}/${qId}/answer`), {
      content: state[qId],
      timestamp: Date.now(),
    })
      .then(() => {
        if (to === "askCar") getLeadCarData();
        else getLeadGeneralData();
        setState((prevState: any) => ({
          ...prevState,
          [qId]: "", // Store the answer using the unique key
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const renderContent = (
    <Box>
      <Box
        display={"flex"}
        p={1}
        gap={1}
        justifyContent={"space-between"}
        bgcolor={"white"}
        m={1}
        sx={{
          borderRadius: "26px 24px 26px 26px",
        }}
      >
        <Button
          variant={guideMode === "general" ? "contained" : "outlined"}
          color="primary"
          size="small"
          sx={{ width: "100%" }}
          disabled={askGeneral ? Object.entries(askGeneral)?.length === 0 : true}
          onClick={() => {
            setGuideMode("general");
          }}
        >
          General
        </Button>
        <Button
          variant={guideMode === "askCar" ? "contained" : "outlined"}
          color="primary"
          size="small"
          sx={{ width: "100%" }}
          disabled={askCar ? Object.entries(askCar)?.length === 0 : true}
          onClick={() => {
            setGuideMode("askCar");
          }}
        >
          Car
        </Button>
      </Box>
      {askCar &&
        guideMode === "askCar" &&
        Object.entries(askCar).map(([key, item]: any) => {
          return (
            <Box
              key={key}
              overflow={"scroll"}
              p={1}
              m={1}
              bgcolor={"white"}
              justifyContent={"center"}
            >
              <Box p={1} borderRadius={1}>
                <Typography variant="body2">{item.question}</Typography>
              </Box>
              <Box
                justifyContent={"center"}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                gap={1}
              >
                {item.answer &&
                  Object.values(item.answer)?.map((element: any, index) => (
                    <Box key={index} bgcolor={"#EAF7FE"} p={1} borderRadius={1} width={"95%"}>
                      <Typography variant="body2">{element.content}</Typography>
                      <Typography variant="caption">
                        {formatTimestamp(element.timestamp)}
                      </Typography>
                    </Box>
                  ))}
              </Box>
              <Textarea
                rows={8}
                label="Your answer"
                id="description"
                placeholder="Enter your answer"
                type="text"
                onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                  setState((prevState: any) => ({
                    ...prevState,
                    [key]: e.target.value, // Store the answer using the unique key
                  }))
                }
              />
              <Box display={"flex"} m={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => sendDataToGeneral("askCar", key)}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          );
        })}
      {askGeneral &&
        guideMode === "general" &&
        Object.entries(askGeneral).map(([key, item]: any) => {
          return (
            <Box
              key={key}
              overflow={"scroll"}
              p={1}
              m={1}
              bgcolor={"white"}
              justifyContent={"center"}
            >
              <Box p={1} borderRadius={1}>
                <Typography variant="body2">{item.question}</Typography>
              </Box>
              <Box
                justifyContent={"center"}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                gap={1}
              >
                {item.answer &&
                  Object.values(item.answer)?.map((element: any, index) => (
                    <Box key={index} bgcolor={"#EAF7FE"} p={1} borderRadius={1} width={"95%"}>
                      <Typography variant="body2">{element.content}</Typography>
                      <Typography variant="caption">
                        {element.timestamp && formatTimestamp(element.timestamp)}
                      </Typography>
                    </Box>
                  ))}
              </Box>
              <Textarea
                rows={8}
                label="Your answer"
                id="description"
                placeholder="Enter your answer"
                type="text"
                onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                  setState((prevState: any) => ({
                    ...prevState,
                    [key]: e.target.value, // Store the answer using the unique key
                  }))
                }
              />
              <Box display={"flex"} m={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => sendDataToGeneral("askGeneral", key)}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          );
        })}
    </Box>
  );

  return (
    <Dialog
      open={open}
      handleClose={onClose}
      fullWidth
      renderContent={renderContent}
      renderTitle={"Guid A.I."}
    />
  );
}
