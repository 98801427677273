import { SvgIcon } from "@mui/material";
import React from "react";

export default function SendIcon({ ...props }) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <svg>
        <path
          d="M15.8325 8.17561L10.109 13.9602L3.59944 9.88865C2.66675 9.30512 2.86077 7.88841 3.91572 7.5799L19.3712 3.05374C20.3373 2.77061 21.2326 3.67381 20.9456 4.64297L16.3731 20.0877C16.0598 21.1442 14.6512 21.3329 14.0732 20.3962L10.106 13.9612"
          stroke="#FAFAFA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
