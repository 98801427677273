import { Box, Typography } from "@mui/material";
import React from "react";
import Dialog from "../../components/dialog/dialog";
import Button from "../../components/button/button";

import ErrorUploadIcon from "../../assets/icon/error-upload-file";
import UploadFileIcon from "../../assets/icon/upload-file";

export default function ErrorUploadFile({ open, handleClose, onOpenFilePicker }: any) {
  const renderTitle = "Attach File";

  const renderContent = (
    <Box width={"100%"} borderRadius={2} textAlign={"center"} p={1} bgcolor={"#FEF2F2"} py={3}>
      <Box display={"flex"} justifyContent={"center"} mb={1}>
        <ErrorUploadIcon />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        gap={1}
        alignItems={"center"}
      >
        <Typography variant="body2" fontWeight={400}>
          Choose file for upload :
        </Typography>
        <Typography variant="body2" color={"#EC0000"} fontWeight={700}>
          Maximum size: 5MB
        </Typography>
      </Box>
    </Box>
  );

  const renderActions = () => {
    return (
      <Box
        gap={1}
        display={"flex"}
        justifyContent={"center"}
        width={"100%"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography variant="body2" fontWeight={400}>
          You can choose an other file for upload:
        </Typography>
        <Box>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#CDEDFE",
              color: "#07A4FC",
            }}
            startIcon={<UploadFileIcon />}
            onClick={() => {
              onOpenFilePicker();
              handleClose();
            }}
          >
            Choose File
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      fullWidth
      renderContent={renderContent}
      renderTitle={renderTitle}
      handleClose={handleClose}
      renderActions={renderActions()}
    />
  );
}
