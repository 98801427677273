import { SvgIcon } from "@mui/material";
import React from "react";

export default function SendToCRMIcon({ ...props }) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      {...props}
    >
      <svg>
        <path
          d="M9.25 11.375C9.62577 11.8773 10.1052 12.293 10.6557 12.5938C11.2063 12.8946 11.8151 13.0734 12.4408 13.1183C13.0666 13.1631 13.6946 13.0728 14.2824 12.8535C14.8702 12.6343 15.404 12.2912 15.8475 11.8475L18.4725 9.22247C19.2694 8.39734 19.7104 7.2922 19.7004 6.14509C19.6905 4.99798 19.2304 3.90067 18.4192 3.08951C17.608 2.27835 16.5107 1.81824 15.3636 1.80827C14.2165 1.7983 13.1114 2.23928 12.2863 3.03622L10.7812 4.53247"
          fill="#EAF7FE"
        />
        <path
          d="M9.25 11.375C9.62577 11.8773 10.1052 12.293 10.6557 12.5938C11.2063 12.8946 11.8151 13.0734 12.4408 13.1183C13.0666 13.1631 13.6946 13.0728 14.2824 12.8535C14.8702 12.6343 15.404 12.2912 15.8475 11.8475L18.4725 9.22247C19.2694 8.39734 19.7104 7.2922 19.7004 6.14509C19.6905 4.99798 19.2304 3.90067 18.4192 3.08951C17.608 2.27835 16.5107 1.81824 15.3636 1.80827C14.2165 1.7983 13.1114 2.23928 12.2863 3.03622L10.7812 4.53247"
          stroke="#07A4FC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7504 9.62501C12.3746 9.12265 11.8952 8.70698 11.3447 8.40619C10.7941 8.1054 10.1853 7.92654 9.5596 7.88172C8.93385 7.83691 8.30578 7.92719 7.71799 8.14646C7.1302 8.36572 6.59644 8.70883 6.15292 9.15251L3.52792 11.7775C2.73098 12.6026 2.29 13.7078 2.29997 14.8549C2.30994 16.002 2.77005 17.0993 3.58121 17.9105C4.39237 18.7216 5.48968 19.1817 6.63679 19.1917C7.7839 19.2017 8.88903 18.7607 9.71417 17.9638L11.2104 16.4675"
          fill="#EAF7FE"
        />
        <path
          d="M12.7504 9.62501C12.3746 9.12265 11.8952 8.70698 11.3447 8.40619C10.7941 8.1054 10.1853 7.92654 9.5596 7.88172C8.93385 7.83691 8.30578 7.92719 7.71799 8.14646C7.1302 8.36572 6.59644 8.70883 6.15292 9.15251L3.52792 11.7775C2.73098 12.6026 2.29 13.7078 2.29997 14.8549C2.30994 16.002 2.77005 17.0993 3.58121 17.9105C4.39237 18.7216 5.48968 19.1817 6.63679 19.1917C7.7839 19.2017 8.88903 18.7607 9.71417 17.9638L11.2104 16.4675"
          stroke="#07A4FC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
