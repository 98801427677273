import { SvgIcon } from "@mui/material";
import React from "react";

export default function SendEmailIcon({ ...props }) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M5.95167 4.00171C3.91767 4.00171 2.32568 4.51571 2.04468 6.4707C1.95468 7.0967 2.15267 7.71272 2.60767 8.15771C2.83367 8.37871 3.07268 8.64171 3.38868 8.90771C4.18168 9.57671 5.19268 10.3287 6.13868 11.0017C8.74169 12.8517 10.7967 14.0017 12.0137 14.0017C13.2307 14.0017 15.2857 12.8517 17.8887 11.0017C18.8357 10.3287 19.8467 9.57571 20.6387 8.90771C20.9547 8.64171 21.1937 8.37971 21.4197 8.15771C21.8747 7.71272 22.0727 7.0967 21.9827 6.4707C21.7017 4.51571 20.1097 4.00171 18.0757 4.00171H5.95167ZM2.01367 10.1577V16.0017C2.01367 18.2107 3.80468 20.0017 6.01368 20.0017H18.0137C20.2227 20.0017 22.0137 18.2107 22.0137 16.0017V10.1577C21.1937 10.9367 20.2777 11.7407 18.9827 12.6577C16.1467 14.6657 13.5997 16.0017 12.0137 16.0017C10.4277 16.0017 7.88069 14.6657 5.04469 12.6577C3.74868 11.7407 2.83368 10.9367 2.01367 10.1577Z"
        fill="#FAFAFA"
      />
    </SvgIcon>
  );
}
