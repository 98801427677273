import { Box, OutlinedInput, Typography } from "@mui/material";
import React, { useState } from "react";
import Dialog from "../../components/dialog/dialog";
import { useSelector } from "react-redux";
import { ref, update } from "firebase/database";
import { firebaseDb } from "../../firebase/app";
import { getUid } from "../../redux/authSlice";
import { getData } from "../../utils/firebase";
import Snackbar from "../../components/snackbar/snackbar";
import Button from "../../components/button/button";

export default function AIMoreData({ handleClose, open, vin }: any) {
  const [carInfo, setCarInfo]: any = useState("");
  const [message, setMessage] = useState({ message: "", open: false, type: "" });

  const uid = useSelector(getUid);

  const handleChange = (e: any) => {
    const { value } = e.target;
    setCarInfo(value);
  };

  React.useEffect(() => {
    const handleGetItem = () => {
      getData(`cars/${uid}/${vin}/carInfo`)
        .then((r) => {
          if (r === undefined) setCarInfo("");
          else setCarInfo(r);
        })
        .catch((r) => console.log(r));
    };

    if (open) handleGetItem();
  }, [open]);

  const handleSendData = () => {
    const data = {
      carInfo: carInfo,
    };
    if (carInfo && vin) {
      update(ref(firebaseDb, `cars/${uid}/${vin}`), data).then(() => {
        setMessage({
          message: "Car info successfully added.",
          open: true,
          type: "",
        });
        handleClose();
      });
    }
    setCarInfo("");
  };

  const renderTitle = "Tell A.I. more about this car";

  const renderContent = (
    <Box>
      <Typography variant="body2" component={"p"} lineHeight={1} mb={1}>
        Please provide more detailed information about this vehicle. So the A.I. system provides
        more accurate and relevant responses to the users&rsquo; inquiries.
      </Typography>
      <OutlinedInput
        sx={{ width: "100%" }}
        onChange={handleChange}
        value={carInfo}
        multiline
        rows={8}
        name="carInfo"
        id="carInfo"
        placeholder="Type here…"
        type="text"
      />
    </Box>
  );
  const renderActions = (
    <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
      <Button onClick={handleSendData} color="primary" variant="contained" sx={{ width: "100%" }}>
        Submit
      </Button>
    </Box>
  );
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        handleClose={handleClose}
        renderActions={renderActions}
        renderContent={renderContent}
        renderTitle={renderTitle}
      />
      <Snackbar
        handleClose={() => setMessage({ message: "", open: false, type: "" })}
        message={message.message}
        open={message.open}
        type={message.type}
      />
    </>
  );
}
