import React from "react";
import { Typography, Box } from "@mui/material";
import Dialog from "../../components/dialog/dialog";
import { push, ref, update } from "firebase/database";
import { firebaseDb } from "../../firebase/app";
import { useSelector } from "react-redux";
import { getUid } from "../../redux/authSlice";
import Button from "../../components/button/button";

export default function SoldDialog({ open, handleClose, sold, vin, data, setData }: any) {
  const uid = useSelector(getUid);

  const removeItemByVin = () => {
    const newData = Object.values(data).filter((item: any) => item.vin !== vin);
    setData(newData);
  };

  const soldHandler = () => {
    const timestamp = Date.now();
    const data = {
      isCarSold: sold ? false : true,
    };
    const historyData = {
      soldChangedByDealer: true,
      ts: timestamp,
      oldVal: sold,
      newVal: !sold,
    };
    const currentUserRef = ref(firebaseDb, `cars/${uid}/${vin}`);
    const historyRef = ref(firebaseDb, `cars/${uid}/${vin}/carHistory`);
    push(historyRef, historyData).then(() => {
      update(currentUserRef, data)
        .then(() => {
          removeItemByVin();
        })
        .catch((e) => console.log(e));
    });
    handleClose();
  };

  const renderTitle = "Car Sold Status";

  const renderContent = (
    <>
      {sold ? (
        <Typography variant="body2" fontWeight={700}>
          Do you want to change the status of the car to unsold?
        </Typography>
      ) : (
        <Typography variant="body2" fontWeight={700}>
          Do you want to change the status of the car to sold?
        </Typography>
      )}
    </>
  );
  const renderActions = (
    <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
      <Button variant="contained" color="primary" onClick={soldHandler} sx={{ width: "100%" }}>
        {"Confirmed"}
      </Button>
    </Box>
  );

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      renderActions={renderActions}
      renderContent={renderContent}
      renderTitle={renderTitle}
    />
  );
}
