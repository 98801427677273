import * as React from "react";
import { BottomNavigation as MuiBottomNavigation } from "@mui/material";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ReportIcon from "../assets/icon/report";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import { bottomHeight } from "../config/constant";
import NavMenu from "../components/nav-menu";
import PersonIcon from "@mui/icons-material/Person";

export default function BottomNavigation() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1, height: bottomHeight }}
      elevation={3}
    >
      <MuiBottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="Inbox"
          icon={<ChatRoundedIcon />}
          onClick={() => navigate("/")}
        />
        <BottomNavigationAction
          label="Appointment"
          icon={<CalendarMonthRoundedIcon />}
          onClick={() => navigate("/appointment")}
        />
        <BottomNavigationAction
          label="Report"
          icon={<ReportIcon />}
          onClick={() => navigate("/report")}
        />
        <BottomNavigationAction
          onClick={handleOpenUserMenu}
          label="Profile"
          icon={<PersonIcon />}
        />
      </MuiBottomNavigation>
      <NavMenu anchorEl={anchorEl} handleClose={handleCloseMenu} />
    </Paper>
  );
}
