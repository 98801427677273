import { Box, FormControl, Typography } from "@mui/material";
import React from "react";
import ControlledInput from "../../components/input/controlled-input";
import ControlledBox from "../../components/box/controlled-box";
import MuiPhone from "../../components/input/mui-phone-input";
import { validateMobileNumber } from "../../utils/utils";

export default function DealershipInformation({ state, onChange, setState, onValidate }: any) {
  return (
    <Box gap={1} display={"flex"} flexDirection={"column"}>
      <Box display={"flex"}>
        <Typography variant="h6" color={"#505050"} fontWeight={700}>
          Dealership Information
        </Typography>
      </Box>
      <Box display={"flex"}>
        <ControlledBox>
          <Box display={"flex"} gap={2}>
            <ControlledInput
              placeholder="Enter Your Name"
              value={state?.name}
              label="Dealership name"
              name="name"
              onChange={onChange}
            />
            <FormControl fullWidth={true}>
              <MuiPhone
                value={state?.phone_number}
                label={"Phone Number"}
                id="phone_number"
                placeholder="Enter your phone number"
                onChange={(e: any) => {
                  setState({ ...state, phone_number: e });
                  if (validateMobileNumber(e)) onValidate(true);
                  else onValidate(false);
                }}
                error={validateMobileNumber(state?.phone_number) ? "" : "error"}
              />
            </FormControl>
            <ControlledInput
              placeholder="Enter Your Email"
              label="Dealership email"
              value={state?.email}
              onChange={onChange}
              name="email"
            />
          </Box>
        </ControlledBox>
      </Box>
    </Box>
  );
}
