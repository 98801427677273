import { SvgIcon } from "@mui/material";
import React from "react";

export default function DialIcon() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      sx={{
        width: 32,
        height: 32,
        color: (theme) => theme.palette.text.primary,
      }}
    >
      <rect width="32" height="32" rx="8" fill="#D0D0D0" fillOpacity="0.31" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0967 16.3201C20.9568 16.3201 20.8107 16.2755 20.6708 16.2436C20.3876 16.181 20.1094 16.0979 19.8382 15.995C19.2275 15.7722 18.5476 16.0471 18.2618 16.6324L18.122 16.9193C17.5029 16.5739 16.934 16.1449 16.4313 15.6444C15.9322 15.1402 15.5044 14.5696 15.16 13.9487L15.427 13.7702C16.0106 13.4836 16.2848 12.8018 16.0626 12.1893C15.9617 11.9167 15.8789 11.6378 15.8147 11.3542C15.7829 11.214 15.7575 11.0674 15.7384 10.9207C15.579 9.99309 14.7701 9.31977 13.8316 9.33346H11.9248C11.3705 9.32824 10.8413 9.56518 10.4752 9.98258C10.1091 10.4 9.94213 10.9565 10.0179 11.5072C10.7145 17.0011 15.0379 21.3213 20.5183 21.9999H20.7598C21.2285 22.0006 21.6811 21.8281 22.031 21.5154C22.4378 21.1506 22.6692 20.6284 22.6666 20.0811V18.1687C22.6506 17.2556 21.993 16.4813 21.0967 16.3201ZM21.3333 20.0345C21.3331 20.2146 21.2551 20.3859 21.1192 20.5047C20.9773 20.6277 20.7894 20.6848 20.6027 20.6615C15.7502 20.0421 11.9365 16.2275 11.3374 11.3941C11.3171 11.2085 11.374 11.0227 11.4948 10.8799C11.6142 10.7446 11.7863 10.667 11.9672 10.6667H13.8568C14.159 10.66 14.4233 10.8679 14.4867 11.1621C14.5119 11.3335 14.5434 11.5028 14.5812 11.67C14.6539 12.0005 14.7508 12.3253 14.8709 12.6419L13.9891 13.0494C13.8368 13.119 13.7186 13.2461 13.6607 13.4026C13.6028 13.5591 13.6099 13.7321 13.6805 13.8834C14.587 15.8163 16.1478 17.3701 18.0895 18.2725C18.2429 18.3352 18.4149 18.3352 18.5682 18.2725C18.7269 18.216 18.8562 18.0986 18.9272 17.9465L19.3178 17.0686C19.6434 17.1846 19.9757 17.2809 20.313 17.3571C20.4809 17.3947 20.651 17.426 20.8231 17.4511C21.1186 17.5142 21.3275 17.7774 21.3207 18.0781L21.3333 20.0345Z"
      />
    </SvgIcon>
  );
}
