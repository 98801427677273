import { SvgIcon } from "@mui/material";
import React from "react";

export default function StartIcon({ ...props }) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M13.9451 15.758C13.5933 15.9483 12.956 16.0732 11.9711 15.4992L10.0352 14.3861C9.84669 14.2758 9.45613 14.2824 9.27482 14.3956L7.3701 15.5545C6.22899 16.2474 5.55532 15.9826 5.25016 15.7687C4.94499 15.5548 4.47664 14.9971 4.75695 13.6935L5.18869 11.7168C5.22987 11.5127 5.12944 11.1728 4.98153 11.0255L3.36495 9.45166C2.55215 8.66341 2.61051 7.97247 2.71284 7.62913C2.81517 7.28579 3.15679 6.68419 4.27803 6.48711L6.31556 6.12042C6.50243 6.08757 6.77027 5.8821 6.8544 5.71534L7.95655 3.43677C8.45993 2.39149 9.14367 2.22637 9.49856 2.22392C9.85345 2.22148 10.5382 2.36655 11.0724 3.39642L12.2333 5.64111C12.3162 5.80848 12.5969 6.00529 12.7799 6.03521L14.8304 6.35119C15.9487 6.52709 16.3046 7.11523 16.4199 7.45457C16.5352 7.79391 16.6051 8.4837 15.8137 9.29065L14.2398 10.9072C14.0924 11.0552 13.9991 11.4012 14.0474 11.6025L14.5282 13.5707C14.8412 14.8642 14.3911 15.4335 14.0881 15.658C14.0534 15.6919 14.0038 15.7263 13.9451 15.758ZM8.81671 13.5065C9.32103 13.2338 10.043 13.2374 10.5294 13.5201L12.4653 14.6332C13.0185 14.9555 13.3617 14.9594 13.4897 14.8599C13.6151 14.7693 13.7057 14.4323 13.5606 13.8136L13.0797 11.8453C12.9492 11.3096 13.1385 10.6085 13.5256 10.2173L15.0995 8.60066C15.4028 8.29263 15.539 7.984 15.4727 7.77732C15.4064 7.57064 15.109 7.41315 14.6773 7.34349L12.6268 7.02748C12.1232 6.95118 11.5734 6.5512 11.3383 6.10235L10.1774 3.85765C9.97084 3.46158 9.71846 3.21913 9.50083 3.22313C9.28321 3.22714 9.04342 3.47048 8.84457 3.8736L7.74242 6.15217C7.52424 6.60364 6.98273 7.01776 6.47854 7.10852L4.44101 7.47521C4.01401 7.55454 3.72398 7.71895 3.66204 7.92676C3.60011 8.13457 3.74691 8.43414 4.05494 8.73746L5.67153 10.3113C6.06865 10.6953 6.27896 11.3925 6.15891 11.9273L5.72721 13.904C5.58926 14.5318 5.69236 14.8626 5.82346 14.9509C5.9487 15.0423 6.30313 15.0249 6.84436 14.6944L8.74904 13.5355C8.77567 13.5287 8.79912 13.516 8.81671 13.5065Z"
        fill="#868686"
      />
    </SvgIcon>
  );
}
