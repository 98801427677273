import { SvgIcon } from "@mui/material";
import React from "react";

export default function CallBackIcon({ ...props }) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1417_31012)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9479 13.8153C14.7717 14.229 14.5264 14.6003 14.2092 14.9181C12.1345 16.9928 7.77262 15.9943 4.467 12.6893C1.162 9.38338 0.163558 5.02151 2.23825 2.94682C2.55637 2.62963 2.92731 2.38432 3.34106 2.20807C3.76262 2.02838 4.24293 2.17807 4.48731 2.56495L6.31137 5.45463C6.46731 5.70182 6.43262 6.01463 6.22637 6.22088L4.75856 7.6887C4.55762 7.88963 4.51856 8.19088 4.66293 8.43713C5.58762 10.0165 7.13981 11.5684 8.71918 12.4934C8.96543 12.6378 9.26668 12.5987 9.46762 12.3978L10.9354 10.9299C11.1417 10.7237 11.4545 10.689 11.7017 10.8449L14.5914 12.669C14.9782 12.9134 15.1279 13.3934 14.9479 13.8153ZM15.6326 7.18026C15.3139 5.84963 14.637 4.58932 13.6014 3.55463C12.5667 2.51901 11.3061 1.84245 9.97575 1.52338C9.54168 1.41901 9.10043 1.35338 8.65856 1.32588C8.54325 1.3187 8.45231 1.23276 8.43793 1.11963L8.31918 0.207135C8.30668 0.109635 8.24043 0.0343223 8.14543 0.0086973C8.05075 -0.0169277 7.95575 0.0149473 7.89575 0.0927598L6.20575 2.28682C6.12606 2.39057 6.14543 2.54026 6.24825 2.61995L8.44325 4.31026C8.52043 4.37057 8.62137 4.37745 8.70637 4.32807C8.79043 4.27932 8.83575 4.18901 8.82325 4.09245L8.71887 3.28713C8.70918 3.21182 8.73293 3.14276 8.787 3.0887C8.84012 3.03463 8.91012 3.01151 8.98543 3.02151C9.18731 3.04901 9.38762 3.0862 9.58606 3.13307C10.6251 3.38276 11.6129 3.91432 12.4279 4.72838C13.242 5.54338 13.7736 6.5312 14.0232 7.57026C14.2623 8.5712 14.2411 9.62464 13.9586 10.6203C13.9239 10.7434 13.9923 10.8718 14.1136 10.9109L15.2411 11.2784C15.3039 11.2987 15.3661 11.2934 15.4254 11.2624C15.4839 11.2306 15.5239 11.1828 15.5423 11.1181C15.9136 9.83776 15.9436 8.47713 15.6326 7.18026Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1417_31012">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
