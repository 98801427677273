import React from "react";
import ManageSearchRoundedIcon from "@mui/icons-material/ManageSearchRounded";
import { Avatar } from "@mui/material";

export const handleBGColor = (leadState: any) => {
  switch (leadState) {
    case "hot":
      return "#FD9292";
    case "contactAttemped":
      return "#EDC045";
    case "cold":
      return "#07A4FC";
    default:
      return "#07A4FC";
  }
};

const handleSrc = (leadState: any) => {
  switch (leadState) {
    case "cold":
      return "./images/cold-lead-state.svg";
    case "hot":
      return "./images/hot-lead-state.svg";
    case "contactAttemped":
      return "./images/warm-lead-state.svg";
    default:
      return "./images/cold-lead-state.svg";
  }
};

export const handleBackground = (
  con: { leadState: string | undefined },
  search = false,
  size = "large"
) => {
  if (search)
    return (
      <ManageSearchRoundedIcon fontSize="large" sx={{ alignItems: "center", display: "flex" }} />
    );
  return (
    <Avatar
      sx={{ width: size === "large" ? 45 : 27, height: size === "large" ? 45 : 27 }}
      src={handleSrc(con?.leadState)}
      alt="cold-lead-state"
    />
  );
};

export function formatNumber(num: { toLocaleString: () => any }) {
  if (!num) return "-";
  return num.toLocaleString();
}

export function removeTrailingZeros(num: { toString: () => string }) {
  if (!num) return "-";
  return `$${num
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    .split(".")[0]
    .replace("$", "")}`;
}

export const handleCopyClipboard = (text: string) => {
  navigator.clipboard
    .writeText(text)
    .then(function () {
      // console.log('Text copied to clipboard');
    })
    .catch(function (err) {
      console.error("Error copying text to clipboard", err);
    });
};

export const transformDays = (day: string) => {
  if (day === "fri") return "Friday";
  else if (day === "mon") return "Monday";
  else if (day === "sat") return "Saturday";
  else if (day === "sun") return "Sunday";
  else if (day === "thu") return "Thursday";
  else if (day === "tue") return "Tuesday";
  else if (day === "wed") return "Wednesday";
};

export const transformArrays = (workingHours: object, appointmentOnly: string) => {
  const generateData = Object.entries(workingHours).map(([key, value]: any) => {
    const [startTime, endTime] = value.split("-"); // Split the value
    const start = startTime.slice(0, -2); // Get the start time (without AM/PM)
    const end = endTime.slice(0, -2); // Get the end time (without AM/PM)
    const morning = startTime.slice(-2).toLowerCase(); // Get AM/PM for start
    const midnight = endTime.slice(-2).toLowerCase(); // Get AM/PM for end
    const closed = value === "0AM-0AM" ? true : false;
    return {
      start,
      end,
      morning,
      midnight,
      closed,
      day: key && transformDays(key),
    };
  });
  const appointmentDays = appointmentOnly.split(",").map(transformDays);
  // check appointment_only
  return generateData.map((i) => {
    return {
      appointment_only: appointmentDays.includes(i.day),
      ...i,
    };
  });
};

export const handleChangeFormat = (array: any[]) => {
  // Use map to create a new array with an added property
  const updatedArrays = array.map(({ start, end, morning, midnight, closed, ...rest }: any) => {
    let timeRange = null;
    if (closed) timeRange = "0AM-0AM";
    else timeRange = `${start}${morning.toUpperCase()}-${end}${midnight.toUpperCase()}`;
    return {
      ...rest, // Spread the existing properties
      time_range: timeRange, // Optional (if not closed)
    };
  });
  return updatedArrays;
};

export const handleName = (name: string | any) => {
  // Check if the input is a string
  if (typeof name !== "string") {
    return name?.charAt(0);
  }

  // Split the full name into first and last name
  const nameParts = name.trim().split(" ");

  // Check if the name has at least two parts
  if (nameParts.length < 2) {
    return name.charAt(0)?.toUpperCase() + name.charAt(1)?.toUpperCase();
  }

  // Get the first character of the first and last name
  const firstInitial = nameParts[0][0]?.toUpperCase();
  const lastInitial = nameParts[1][0]?.toUpperCase();

  // Return the initials
  return `${firstInitial}${lastInitial}`;
};

export function validateMobileNumber(number: string) {
  const regex = /^\+\d{1,3}\d{10}$/;
  return regex.test(number);
}

export function formatPhoneNumber(number: string) {
  if (validateMobileNumber(number)) {
    // Remove any non-digit characters
    const cleaned = number.replace(/\D/g, "");
    // Check if the cleaned number has the expected length
    if (cleaned.length === 11 && cleaned.startsWith("1")) {
      const areaCode = cleaned.slice(1, 4);
      const centralOfficeCode = cleaned.slice(4, 7);
      const lineNumber = cleaned.slice(7);
      return `1 (${areaCode}) ${centralOfficeCode}-${lineNumber}`;
    } else {
      throw new Error("Invalid phone number format");
    }
  } else return "";
}

function convertDate(dateString: any) {
  // Split the date string into components
  const [year, month] = dateString.split("-").map(Number);
  // Array of month abbreviations
  const monthAbbreviations = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // Get the month abbreviation (subtracting 1 because months are zero-indexed)
  const monthAbbreviation = monthAbbreviations[month - 1];
  // Format the output
  return `Holidays for ${year} - ${monthAbbreviation}`;
}

const groupBy = (data: any) => {
  return Object.entries(data).reduce((accumulator: any, [key, value]: any) => {
    const newValue = { ...value, id: key }; // Create a new object with the added id
    const date = value.date;
    // Extract the month and year from the date
    const [month, , year] = date.split("/").map(Number);
    // Create a month key in the format "YYYY - month abbreviation"
    const monthKey = `${year}-${month}`;
    if (!accumulator[monthKey]) {
      accumulator[monthKey] = [];
    }

    // Create a status key based on appointment_only and closed
    let statusKey = "";
    if (newValue.appointment_only) statusKey = "appointment_only";
    else if (newValue.closed) statusKey = "closed";
    else statusKey = "other";

    if (!accumulator[monthKey][statusKey]) {
      accumulator[monthKey][statusKey] = [];
    }

    accumulator[monthKey][statusKey].push(newValue);
    return accumulator;
  }, {}); //initial state
};

export async function fetchData(query: any, token: string) {
  const path = `${process.env.REACT_APP_SEARCH_BASE_URL}?search=${query}`;
  try {
    const response = await fetch(path, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // adjust if necessary
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  } finally {
    console.error("There was a problem with the fetch operation:");
  }
}

export const convertTo24Hour = (time: string, period: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  if (!hours) return "";
  let convertedHours = hours; // Use let for hours since it may change

  if (period.toLowerCase() === "pm" && convertedHours < 12) {
    convertedHours += 12;
  } else if (period.toLowerCase() === "am" && convertedHours === 12) {
    convertedHours = 0;
  }

  // Format hours and minutes to ensure two digits
  const formattedHours = String(convertedHours).padStart(2, "0");
  let formattedMinutes = String(minutes).padStart(2, "0");
  if (formattedMinutes === "undefined") formattedMinutes = "";

  return `${formattedHours || ""}:${formattedMinutes || ""}`;
};

export const handleAddQueryParam = (newValue: string) => {
  // Update the URL without triggering a rerender
  const newUrl = newValue;
  window.history.pushState({ path: newUrl }, "", newUrl);
};

export function customizeSourceName(source: string) {
  if (source === "OFFERUP") return "OfferUp";
  // Join the words back into a single string
  if (source === "CARFAX, INC")
    return source.slice(0, 6); // remove , INC
  // Join the words back into a single string
  else return source;
}

export function generateUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = crypto.getRandomValues(new Uint8Array(1))[0] % 16 | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const generateClock = () => {
  const clockData = [];
  for (let hour = 0; hour < 12; hour++) {
    for (const minutes of [0, 30]) {
      const time = `${hour}:${minutes.toString().padStart(2, "0")}`;
      clockData.push(time);
    }
  }
  return clockData;
};

export const formatToTwoDigits = (num: any) => {
  return String(num).padStart(2, "0");
};

const transformName = (lead: any) => {
  let updatedLeadName = "";
  switch (lead) {
    case "ace_spfi":
      updatedLeadName = "Acespfi";
      break;
    case "appointments":
      updatedLeadName = "Appointments";
      break;
    case "auto_list":
      updatedLeadName = "َAuto list";
      break;
    case "auto_source":
      updatedLeadName = "َAuto source";
      break;
    case "buy_sell_auto_mart":
      updatedLeadName = "Buy sell automart";
      break;
    case "car_soup":
      updatedLeadName = "Car soup";
      break;
    case "car_zing":
      updatedLeadName = "Car zing";
      break;
    case "cars":
      updatedLeadName = "Cars";
      break;
    case "cars_for_sale":
      updatedLeadName = "Cars for sale";
      break;
    case "cars_inc":
      updatedLeadName = "Cars inc";
      break;
    case "claz":
      updatedLeadName = "Claz";
      break;
    case "craige_list":
      updatedLeadName = "Craige list";
      break;
    case "dealer_assistan_request":
      updatedLeadName = "Dealer assistant request";
      break;
    case "facebook_marketplacet":
      updatedLeadName = "Facebook market placet";
      break;
    case "hot_leads":
      updatedLeadName = "Hot leads";
      break;
    case "first_responder":
      updatedLeadName = "First responder";
      break;
    case "james_edition":
      updatedLeadName = "James edition";
      break;
    case "kbb_listing":
      updatedLeadName = "Kbb listing";
      break;
    case "manual_entry":
      updatedLeadName = "Manual entry";
      break;
    case "msn_autos":
      updatedLeadName = "Msn autos";
      break;
    case "offer_up":
      updatedLeadName = "Offer up";
      break;
    case "true_cars":
      updatedLeadName = "True cars";
      break;
    case "v12":
      updatedLeadName = "V12";
      break;
    default:
      break;
  }
  return updatedLeadName;
};

export { convertDate, groupBy, transformName };
