import { Box } from "@mui/material";
import Dialog from "../../components/dialog/dialog";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import React, { useState } from "react";
import ControlledInput from "../../components/input/controlled-input";

export default function ResetPassword({ open, handleClose }: any) {
  const [type, setType] = useState("password");

  function handleTypeInput(name: string) {
    if (name === type) {
      setType("");
    } else {
      setType(name);
    }
  }

  const renderTitle = "change password";
  const renderContent = (
    <Box display={"grid"} gap={"15px"} mx={"auto"} my={"1rem"} width={"400px"}>
      <ControlledInput
        endAdornment={
          <RemoveRedEyeIcon onClick={() => handleTypeInput("current")} sx={{ cursor: "pointer" }} />
        }
        title="current password*"
        type={type === "current" ? "text" : "password"}
      />
      <ControlledInput
        endAdornment={
          <RemoveRedEyeIcon onClick={() => handleTypeInput("new")} sx={{ cursor: "pointer" }} />
        }
        title="new password*"
        type={type === "new" ? "text" : "password"}
      />
      <ControlledInput
        endAdornment={
          <RemoveRedEyeIcon
            onClick={() => handleTypeInput("duplicate")}
            sx={{ cursor: "pointer" }}
          />
        }
        title="duplicate NewPassword*"
        type={type === "duplicate" ? "text" : "password"}
      />
    </Box>
  );
  const renderActions = (
    <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={"30px"}></Box>
  );
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      renderTitle={renderTitle}
      renderContent={renderContent}
      renderActions={renderActions}
      maxWidth={"90%"}
    />
  );
}
