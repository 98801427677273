import { useMediaQuery } from "@mui/material";

export const useResponsive = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:601px) and (max-width:960px)");
  const isTabletAndMobile = useMediaQuery("(max-width:1209px)");
  const isDesktop = useMediaQuery("(min-width:961px)");
  const isSmall = useMediaQuery("(max-width:899px)");

  return { isMobile, isTablet, isDesktop, isTabletAndMobile, isSmall };
};
