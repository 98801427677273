import { Box, Divider, Typography, useTheme } from "@mui/material";
import React from "react";
import AppointmentIcon from "../../assets/icon/appointment-icon";
import Dialog from "../../components/dialog/dialog";
import { getDataByQL } from "../../utils/firebase";
import { useDispatch, useSelector } from "react-redux";
import { getUid } from "../../redux/authSlice";
import { getSortValue, sortValueSet } from "../../redux/userSlice";
import CallBackIcon from "../../assets/icon/call-back";
import AIDisabledIcon from "../../assets/icon/ai-disabled";
import FollowupIDisabledIcon from "../../assets/icon/followup-disabled";
import HotIcon from "../../assets/icon/hot";
import ContactAttemptedIcon from "../../assets/icon/contact-attempted-icon";
import ReengagedIcon from "../../assets/icon/reengaged";
import { getMode } from "../../redux/layoutSlice";
import StartIcon from "../../assets/icon/star";
import StarFillIcon from "../../assets/icon/star-fill";

function IconText({ icon, title, length, onClick, selected }: any) {
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <Box display={"flex"} gap={1}>
          {icon}
          <Typography
            variant="body2"
            fontWeight={500}
            color={(theme) => (selected ? theme.palette.primary.main : "")}
          >
            {title}
          </Typography>
        </Box>
        {length && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            width={30}
            height={30}
            borderRadius={"50%"}
            bgcolor={(theme) => (selected ? theme.palette.primary.main : "#868686")}
          >
            <Typography variant="caption" color={"white"}>
              {length}
            </Typography>
          </Box>
        )}
      </Box>
      {selected && <Divider sx={{ background: (theme) => theme.palette.primary.main }} />}
    </>
  );
}

export default function RenderFilters({ open, setOpen }: any) {
  const [appointmentLength, setAppointmentLength] = React.useState(0);
  const [hotLength, setHotLength] = React.useState(0);
  const [contactAttemptedLength, setContactAttemptedLength] = React.useState(0);
  const [AIModeDisabled, setAIModeDisabled] = React.useState(0);
  const [followUpDisabled, setFollowUpDisabled] = React.useState(0);
  const [callRequest, setCallRequest] = React.useState(0);
  const [reEngaged, setReEngaged] = React.useState(0);
  const [bookMarked, setBookMarked] = React.useState(0);
  const dispatch = useDispatch();
  const theme = useTheme();

  const sortValue = useSelector(getSortValue);
  const mode = useSelector(getMode);
  const uid = useSelector(getUid);

  function getData() {
    getDataByQL(`conversations/${uid}`, "appointmentIsSet", true).then((r: any) =>
      setAppointmentLength(r)
    );
    getDataByQL(`conversations/${uid}`, "leadState", "hot").then((r: any) => setHotLength(r));
    getDataByQL(`conversations/${uid}`, "leadState", "contactAttemped").then((r: any) =>
      setContactAttemptedLength(r)
    );
    getDataByQL(`conversations/${uid}`, "leadState", "cold").then((r: any) => setReEngaged(r));
    getDataByQL(`conversations/${uid}`, "AIMode", false).then((r: any) => setAIModeDisabled(r));
    getDataByQL(`conversations/${uid}`, "callRequest", true).then((r: any) => setCallRequest(r));
    getDataByQL(`conversations/${uid}`, "followUp", false).then((r: any) => setFollowUpDisabled(r));
    getDataByQL(`conversations/${uid}`, "bookMarked", true).then((r: any) => setBookMarked(r));
  }

  React.useEffect(() => {
    getData();
  }, []);

  const handleClick = (status: string) => {
    if (sortValue === status) {
      dispatch(sortValueSet("All Message"));
    } else {
      dispatch(sortValueSet(status));
    }
  };

  const renderContent = (
    <Box gap={1} display={"flex"} flexDirection={"column"} width={"100%"}>
      <Box
        p={1}
        bgcolor={mode === "dark" ? "#18171D" : "#F2F2F2"}
        gap={1}
        display={"flex"}
        flexDirection={"column"}
        borderRadius={3}
      >
        <IconText
          icon={
            <AppointmentIcon
              sx={{ color: sortValue === "Appointment" ? theme.palette.primary.main : "#868686" }}
            />
          }
          title={"Appointment Booked"}
          length={appointmentLength}
          onClick={() => handleClick("Appointment")}
          selected={sortValue === "Appointment"}
        />
        <IconText
          icon={
            <CallBackIcon
              sx={{ color: sortValue === "callRequest" ? theme.palette.primary.main : "#868686" }}
            />
          }
          title={"Call Back Requested"}
          length={callRequest}
          onClick={() => handleClick("callRequest")}
          selected={sortValue === "callRequest"}
        />
        <IconText
          icon={
            <AIDisabledIcon
              sx={{ color: sortValue === "AIMode" ? theme.palette.primary.main : "#868686" }}
            />
          }
          title={"A.I. Disabled"}
          length={AIModeDisabled}
          onClick={() => handleClick("AIMode")}
          selected={sortValue === "AIMode"}
        />
        <IconText
          icon={
            <FollowupIDisabledIcon
              sx={{
                color: sortValue === "followUpEnabled" ? theme.palette.primary.main : "#868686",
              }}
            />
          }
          title={"Follow Up Disabled"}
          length={followUpDisabled}
          onClick={() => handleClick("followUpEnabled")}
          selected={sortValue === "followUpEnabled"}
        />
      </Box>
      <Box
        p={1}
        bgcolor={mode === "dark" ? "#18171D" : "#F2F2F2"}
        gap={1}
        display={"flex"}
        flexDirection={"column"}
        borderRadius={3}
      >
        <IconText
          icon={<HotIcon fill={sortValue === "Hot" ? theme.palette.primary.main : "#868686"} />}
          title={"Hot Leads"}
          length={hotLength}
          onClick={() => handleClick("Hot")}
          selected={sortValue === "Hot"}
        />
        <IconText
          icon={
            <ContactAttemptedIcon
              fill={sortValue === "contact attempted" ? theme.palette.primary.main : "#868686"}
            />
          }
          title={"Warm Leads"}
          length={contactAttemptedLength}
          onClick={() => handleClick("contact attempted")}
          selected={sortValue === "contact attempted"}
        />
        <IconText
          icon={
            <ReengagedIcon
              fill={sortValue === "Not Engaged" ? theme.palette.primary.main : "#868686"}
            />
          }
          title={"Re-Engaged"}
          length={reEngaged}
          onClick={() => handleClick("Not Engaged")}
          selected={sortValue === "Not Engaged"}
        />
        <IconText
          icon={
            sortValue === "bookMarked" ? (
              <StarFillIcon fill={theme.palette.primary.main} />
            ) : (
              <StartIcon
                fill={sortValue === "bookMarked" ? theme.palette.primary.main : "#868686"}
              />
            )
          }
          title={"Book Marked"}
          length={bookMarked}
          onClick={() => handleClick("bookMarked")}
          selected={sortValue === "bookMarked"}
        />
      </Box>
    </Box>
  );

  return (
    <Dialog
      open={open}
      fullWidth
      handleClose={() => setOpen(false)}
      renderContent={renderContent}
      renderTitle={"Filter"}
    />
  );
}
