import { Box } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

export default function TextEditor({ onChange, value }: any) {
  return (
    <Box
      sx={{
        "& .ql-toolbar.ql-snow": {
          border: "none !important",
          borderBottom: "1px solid #ccc !important",
        },
        "& .ql-container.ql-snow ": {
          border: "none !important",
        },
      }}
    >
      <ReactQuill
        value={value}
        onChange={onChange}
        style={{ height: "400px", border: "1px solid #E2E8F0 ", borderRadius: 12 }} // Optional height adjustment
      />
    </Box>
  );
}
