import { Box, Typography } from "@mui/material";
import React from "react";

export default function IconLabel({ icon, label }: any) {
  return (
    <Box display={"flex"} gap={1}>
      {icon}
      <Typography variant="body2" fontWeight={500}>
        {label}
      </Typography>
    </Box>
  );
}
