import React from "react";
import { IconButton as MuiIconButton } from "@mui/material";

export default function IconButton({ icon, color, ...rest }: any) {
  return (
    <MuiIconButton
      sx={{
        color: color,
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
      {...rest}
    >
      {icon}
    </MuiIconButton>
  );
}
