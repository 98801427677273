import { SvgIcon } from "@mui/material";
import React from "react";

export default function NoteIcon({ ...props }) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M21.3751 0.188477H4.50011C3.15011 0.188477 2.06261 1.27598 2.06261 2.62598V7.80098C2.06261 11.2885 1.63136 14.776 0.787606 18.1697L0.675106 18.6385C0.525106 19.2572 0.656356 19.8947 1.05011 20.401C1.44386 20.9072 2.04386 21.1885 2.68136 21.1885H17.0064C18.3001 21.1885 19.4251 20.3072 19.7439 19.051L19.8939 18.4322C20.7564 14.9635 21.2064 11.3822 21.2064 7.80098V1.31348H21.3939C22.1251 1.31348 22.7064 1.89473 22.7064 2.62598V21.376C22.7064 22.1072 22.1251 22.6885 21.3939 22.6885H4.50011C4.18136 22.6885 3.93761 22.9322 3.93761 23.251C3.93761 23.5697 4.18136 23.8135 4.50011 23.8135H21.3751C22.7251 23.8135 23.8126 22.726 23.8126 21.376V2.62598C23.8126 1.27598 22.7251 0.188477 21.3751 0.188477ZM14.6814 12.976C14.5501 13.2572 14.2126 13.3697 13.9314 13.2385C13.8376 13.201 13.7626 13.1635 13.7064 13.1635C13.5001 13.1447 13.2189 13.4072 12.9376 13.6885C12.5626 14.0447 12.1501 14.4572 11.5501 14.5135C11.4939 14.5135 11.4376 14.5135 11.4001 14.5135C10.5751 14.5135 10.0126 13.801 9.50636 13.1822C9.26261 12.8635 8.88761 12.4135 8.66261 12.3572C8.51261 12.4885 8.25011 12.8635 8.02511 13.1822C7.85636 13.4072 7.66886 13.6697 7.46261 13.9322C7.27511 14.176 6.91886 14.2135 6.67511 14.026C6.43136 13.8385 6.39386 13.4822 6.58136 13.2385C6.76886 12.9947 6.95636 12.7322 7.12511 12.5072C7.70636 11.7197 8.00636 11.2885 8.47511 11.2322C9.31886 11.1197 9.88136 11.8322 10.3876 12.4697C10.7251 12.8822 11.1564 13.4072 11.4376 13.3885C11.6439 13.3697 11.9064 13.1072 12.1689 12.8635C12.5814 12.4697 13.1064 11.9635 13.8376 12.0385C14.0439 12.0572 14.2501 12.1135 14.4564 12.226C14.7189 12.3572 14.8314 12.6947 14.6814 12.976ZM16.1251 14.4385C15.8064 14.4385 15.5626 14.1947 15.5626 13.876C15.5626 13.5572 15.8064 13.3135 16.1251 13.3135C16.4439 13.3135 16.6876 13.5572 16.6876 13.876C16.6876 14.1947 16.4439 14.4385 16.1251 14.4385Z"
        fill="#07A4FC"
      />
    </SvgIcon>
  );
}
