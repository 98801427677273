import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { Box } from "@mui/material";

function ServerDay(
  props: PickersDayProps<Dayjs> & {
    highlightedDays?: number[];
    selectedDays?: Dayjs[];
    onDayClick?: (day: Dayjs) => void;
  }
) {
  const {
    day,
    outsideCurrentMonth,
    selectedDays = [],
    onDayClick,
    highlightedDays = [],
    ...other
  } = props;

  const isSelected = selectedDays.some((selectedDay) => selectedDay.isSame(day, "day"));
  const isHighlighted = !outsideCurrentMonth && highlightedDays.includes(day.date());

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isSelected ? "" : undefined}
      color={isHighlighted ? "primary" : "default"}
    >
      <div onClick={() => onDayClick?.(day)}>
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          selected={isSelected}
          sx={{ backgroundColor: isHighlighted ? "rgba(255, 0, 0, 0.5)" : "inherit" }} // Highlighting styles
        />
      </div>
    </Badge>
  );
}

export default function DateRangePicker({ selectedDays, setSelectedDays, monthDaysList }: any) {
  const [isLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState<number[]>([]);

  const handleSetHighlightedDays = (selectedMonth: number) => {
    let flag = false;
    monthDaysList.map((i: any) => {
      if (selectedMonth === i.monthNumber) {
        flag = true;
        setHighlightedDays(i.days);
      }
    });
    // If the day does not exist in the month."
    if (!flag) setHighlightedDays([]);
  };

  React.useEffect(() => {
    const date = new Date();
    const month = date.getMonth();
    const humanReadableMonth = month + 1;
    handleSetHighlightedDays(humanReadableMonth);
  }, []);

  const handleMonthChange = (date: Dayjs) => {
    const current = dayjs(); // Assuming currentMonth is a Dayjs object
    const newDate = dayjs(date); // newMonth should also be a Dayjs object

    // Calculate the difference in months
    const monthDiff = newDate.diff(current, "month");

    // Allow change only if it's within 3 months
    if (Math.abs(monthDiff) <= 3) {
      const monthNumber = newDate.month() + 1; // Get month number (1-12)
      handleSetHighlightedDays(monthNumber); // This should be a Dayjs object
    }
  };

  const handleDayClick = (day: Dayjs) => {
    setSelectedDays((prev: Dayjs[]) => {
      if (prev.some((selectedDay) => selectedDay.isSame(day, "day"))) {
        // Remove the day if it's already selected
        return prev.filter((selectedDay) => !selectedDay.isSame(day, "day"));
      }
      // Add the day if it's not selected
      return [...prev, day];
    });
  };

  return (
    <Box width={"100%"}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          loading={isLoading}
          onMonthChange={handleMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: (dayProps) => (
              <ServerDay
                {...dayProps}
                highlightedDays={highlightedDays}
                selectedDays={selectedDays}
                onDayClick={handleDayClick}
              />
            ),
          }}
        />
      </LocalizationProvider>
    </Box>
  );
}
