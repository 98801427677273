import { Button as MuiButton } from "@mui/material";
import React from "react";

export default function Button({ children, sx, ...props }: any) {
  return (
    <MuiButton
      sx={{
        fontSize: 14,
        borderRadius: "58px",
        boxShadow: "none",
        minWidth: 80,
        ...sx,
      }}
      {...props}
    >
      {children}
    </MuiButton>
  );
}
