import { SvgIcon } from "@mui/material";
import React from "react";

export default function ContactAttemptedIcon({ fill }: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.0702 9.82167C18.9881 9.82178 18.9068 9.80563 18.831 9.77417C18.7552 9.7427 18.6864 9.69654 18.6285 9.63833L16.861 7.87C16.8013 7.81232 16.7538 7.74333 16.721 7.66706C16.6883 7.59079 16.6711 7.50877 16.6704 7.42579C16.6697 7.3428 16.6856 7.26051 16.7171 7.18371C16.7485 7.10692 16.795 7.03716 16.8537 6.9785C16.9124 6.91985 16.9822 6.87347 17.059 6.84208C17.1358 6.81069 17.2181 6.79492 17.3011 6.79568C17.3841 6.79644 17.4661 6.81372 17.5424 6.84651C17.6186 6.8793 17.6875 6.92695 17.7452 6.98667L19.5127 8.75417C19.5999 8.84173 19.6592 8.95317 19.6832 9.07442C19.7072 9.19567 19.6947 9.3213 19.6474 9.43548C19.6001 9.54966 19.52 9.64727 19.4173 9.71599C19.3145 9.78472 19.1938 9.82149 19.0702 9.82167Z"
        fill={fill}
      />
      <path
        d="M16.7133 10.4108C16.5898 10.4107 16.4691 10.374 16.3664 10.3053C16.2637 10.2366 16.1837 10.1391 16.1364 10.0249C16.0892 9.9108 16.0768 9.78522 16.1008 9.66405C16.1249 9.54289 16.1844 9.43157 16.2717 9.34417L17.745 7.87083C17.8635 7.76043 18.0202 7.70033 18.1821 7.70319C18.344 7.70604 18.4985 7.77164 18.613 7.88615C18.7275 8.00066 18.7931 8.15515 18.796 8.31706C18.7988 8.47898 18.7387 8.63569 18.6283 8.75417L17.1558 10.2275C17.0978 10.2858 17.0289 10.332 16.9529 10.3635C16.877 10.395 16.7955 10.4111 16.7133 10.4108Z"
        fill={fill}
      />
      <path
        d="M7.83333 14.7083H4.5C4.33424 14.7083 4.17527 14.6425 4.05806 14.5253C3.94085 14.4081 3.875 14.2491 3.875 14.0833C3.875 13.9176 3.94085 13.7586 4.05806 13.6414C4.17527 13.5242 4.33424 13.4583 4.5 13.4583H7.83333C7.99909 13.4583 8.15807 13.5242 8.27528 13.6414C8.39249 13.7586 8.45833 13.9176 8.45833 14.0833C8.45833 14.2491 8.39249 14.4081 8.27528 14.5253C8.15807 14.6425 7.99909 14.7083 7.83333 14.7083Z"
        fill={fill}
      />
      <path
        d="M8.6665 12.2083H3.6665C3.50074 12.2083 3.34177 12.1425 3.22456 12.0253C3.10735 11.9081 3.0415 11.7491 3.0415 11.5833C3.0415 11.4176 3.10735 11.2586 3.22456 11.1414C3.34177 11.0242 3.50074 10.9583 3.6665 10.9583H8.6665C8.83226 10.9583 8.99123 11.0242 9.10845 11.1414C9.22566 11.2586 9.2915 11.4176 9.2915 11.5833C9.2915 11.7491 9.22566 11.9081 9.10845 12.0253C8.99123 12.1425 8.83226 12.2083 8.6665 12.2083Z"
        fill={fill}
      />
      <path
        d="M8.6665 17.2083H3.6665C3.50074 17.2083 3.34177 17.1425 3.22456 17.0253C3.10735 16.9081 3.0415 16.7491 3.0415 16.5833C3.0415 16.4176 3.10735 16.2586 3.22456 16.1414C3.34177 16.0242 3.50074 15.9583 3.6665 15.9583H8.6665C8.83226 15.9583 8.99123 16.0242 9.10845 16.1414C9.22566 16.2586 9.2915 16.4176 9.2915 16.5833C9.2915 16.7491 9.22566 16.9081 9.10845 17.0253C8.99123 17.1425 8.83226 17.2083 8.6665 17.2083Z"
        fill={fill}
      />
      <path
        d="M13.6667 3.04167H10.3333C9.52792 3.04167 8.875 3.69458 8.875 4.5V4.70833C8.875 5.51375 9.52792 6.16667 10.3333 6.16667H13.6667C14.4721 6.16667 15.125 5.51375 15.125 4.70833V4.5C15.125 3.69458 14.4721 3.04167 13.6667 3.04167Z"
        fill={fill}
      />
      <path
        d="M11.9998 8.45833C11.5398 8.45833 11.1665 8.17833 11.1665 7.83333V6.16667C11.1665 5.82167 11.5398 5.54167 11.9998 5.54167C12.4598 5.54167 12.8332 5.82167 12.8332 6.16667V7.83333C12.8332 8.17833 12.4598 8.45833 11.9998 8.45833Z"
        fill={fill}
      />
      <path
        d="M12.0002 9.29167C11.0525 9.29167 10.126 9.57269 9.33806 10.0992C8.55007 10.6257 7.93591 11.3741 7.57324 12.2496C7.21057 13.1252 7.11568 14.0886 7.30057 15.0181C7.48546 15.9476 7.94182 16.8014 8.61194 17.4716C9.28207 18.1417 10.1359 18.598 11.0654 18.7829C11.9948 18.9678 12.9583 18.8729 13.8339 18.5103C14.7094 18.1476 15.4578 17.5334 15.9843 16.7454C16.5108 15.9575 16.7918 15.031 16.7918 14.0833C16.7964 13.4528 16.6755 12.8277 16.4363 12.2443C16.1972 11.6609 15.8444 11.1308 15.3985 10.685C14.9527 10.2391 14.4226 9.88635 13.8392 9.64715C13.2558 9.40796 12.6307 9.28712 12.0002 9.29167ZM14.076 13.1092L13.0285 14.0183C13.0285 14.0408 13.0418 14.06 13.0418 14.0833C13.0418 14.2894 12.9807 14.4908 12.8663 14.6621C12.7518 14.8334 12.5891 14.9669 12.3988 15.0457C12.2085 15.1245 11.999 15.1452 11.7969 15.105C11.5949 15.0648 11.4093 14.9656 11.2636 14.8199C11.1179 14.6742 11.0187 14.4886 10.9785 14.2866C10.9383 14.0845 10.9589 13.875 11.0378 13.6847C11.1166 13.4944 11.2501 13.3317 11.4214 13.2172C11.5927 13.1028 11.7941 13.0417 12.0002 13.0417C12.0681 13.048 12.1352 13.0617 12.2002 13.0825L13.2577 12.1658C13.3192 12.1092 13.3915 12.0655 13.4702 12.0373C13.549 12.0091 13.6326 11.997 13.7161 12.0017C13.7996 12.0065 13.8813 12.0279 13.9564 12.0648C14.0315 12.1016 14.0984 12.1532 14.1532 12.2164C14.2079 12.2796 14.2495 12.3532 14.2753 12.4327C14.3011 12.5123 14.3107 12.5962 14.3035 12.6795C14.2964 12.7628 14.2725 12.8439 14.2334 12.9178C14.1943 12.9918 14.1408 13.0563 14.076 13.1092Z"
        fill={fill}
      />
      <path
        d="M12.0001 20.9583C10.5949 20.961 9.22291 20.5306 8.07094 19.7258C7.9349 19.631 7.84211 19.486 7.81296 19.3228C7.78381 19.1596 7.82071 18.9915 7.91552 18.8554C8.01034 18.7194 8.15531 18.6266 8.31854 18.5974C8.48178 18.5683 8.6499 18.6052 8.78594 18.7C9.62942 19.2871 10.6176 19.632 11.6433 19.697C12.6689 19.7621 13.6928 19.545 14.6038 19.0692C15.5147 18.5934 16.2779 17.8771 16.8104 16.9981C17.3429 16.1191 17.6245 15.111 17.6245 14.0833C17.6245 13.0556 17.3429 12.0475 16.8104 11.1686C16.2779 10.2896 15.5147 9.57332 14.6038 9.09751C13.6928 8.6217 12.6689 8.40454 11.6433 8.46962C10.6176 8.5347 9.62942 8.87953 8.78594 9.46667C8.71858 9.51361 8.64264 9.54683 8.56244 9.56443C8.48224 9.58203 8.39937 9.58366 8.31854 9.56923C8.23772 9.5548 8.16052 9.52459 8.09137 9.48032C8.02222 9.43606 7.96247 9.37861 7.91552 9.31125C7.86857 9.24389 7.83535 9.16795 7.81775 9.08775C7.80016 9.00756 7.79853 8.92468 7.81296 8.84385C7.84211 8.68062 7.9349 8.53565 8.07094 8.44083C8.95652 7.82437 9.97477 7.425 11.0433 7.27505C12.1119 7.12509 13.2007 7.22876 14.2218 7.57765C15.2428 7.92655 16.1674 8.51089 16.9208 9.28339C17.6741 10.0559 18.2351 10.9949 18.5582 12.0244C18.8814 13.0539 18.9577 14.145 18.7809 15.2094C18.6042 16.2739 18.1794 17.2818 17.5409 18.1516C16.9024 19.0214 16.0681 19.7287 15.1055 20.2163C14.1429 20.7039 13.0791 20.9581 12.0001 20.9583Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
