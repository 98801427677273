import React, { Suspense, useCallback, useRef } from "react";
import { Box, Fab, Grid, IconButton, Stack, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  getIsOpenChat,
  handleOpenChat,
  getActiveConId,
  activeConIdSet,
  AImodeSet,
} from "../../redux/userSlice";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import constants from "../../config/constant";

import { off, onChildChanged, onValue, push, query, ref, set, update } from "firebase/database";
import { firebaseDb, firebaseSecondDb } from "../../firebase/app";
import { getUid } from "../../redux/authSlice";
import { To, useNavigate } from "react-router";
import ChatItem from "./chat-item";
import { formatPhoneNumber, handleBackground, handleCopyClipboard } from "../../utils/utils";
import ChatGroupContent from "./chat-group-content";
import { GroupedVirtuoso, GroupedVirtuosoHandle } from "react-virtuoso";
import { groupBy, uniq } from "underscore";
import { createPushId, getData } from "../../utils/firebase";
import Snackbar from "../../components/snackbar/snackbar";
import PhoneIcon from "../../assets/icon/phone";
import CopyIcon from "../../assets/icon/copy";
import { getContactName } from "../../utils/string";
import { getMode } from "../../redux/layoutSlice";
import Button from "../../components/button/button";
import CallbackRequestIcon from "../../assets/icon/callback-request";
import GuidAIIcon from "../../assets/icon/guid-A.I";
import GuideAI from "./guide-AI";
import UploadFile from "./upload-file";
import { useResponsive } from "../../hooks/use-media-query";
import { useFilePicker } from "use-file-picker";
import { handleUploadByPercent } from "../../utils/gcs";
import ErrorUploadFile from "./error-upload-file";
import SendToCrm from "./send-to-crm";
import SendEmailIcon from "../../assets/icon/send-email";
import SendEmailModal from "./send-email";
import Animation from "../../components/animation/emergency-animation";
import MessageInput from "../../components/input/message-input";
import ScheduleMessage from "./schedule-message";
import SendToCRMIcon from "../../assets/icon/send-to-crm";
import LeadInfoIcon from "../../assets/icon/lead-info";
import AISettingIcon from "../../assets/icon/ai-seting";
import ReportIssueIcon from "../../assets/icon/report-issue";

const generateGroupedMessages = (chats: any) => {
  const uniqueBy = uniq(Object.values(chats), function (x: any) {
    return x?.timestamp;
  });
  const newData = uniqueBy;
  const groupedMessages = groupBy(newData, (message: any) => {
    const date = new Date(message?.timestamp);
    const today = new Date();

    // Reset time to compare only dates
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Compare dates
    if (date >= today) {
      return "Today";
    } else if (date >= yesterday) {
      return "Yesterday";
    } else {
      const year = date.toLocaleString("en-US", { year: "numeric" });
      const month = date.toLocaleString("en-US", { month: "2-digit" });
      const day = date.toLocaleString("en-US", { day: "2-digit" });
      return `${month}.${day}.${year}`;
    }
  });

  const groupCounts = Object.values(groupedMessages).map((item) => item.length);
  const groups = Object.keys(groupedMessages);
  return { groupCounts, groups, newData };
};

const renderChatItem = (
  chats: any,
  setIsScrollBottom: any,
  listRef: React.Ref<GroupedVirtuosoHandle> | undefined,
  messagingMode: string
) => {
  const { groupCounts, groups, newData } = generateGroupedMessages(chats);

  return (
    <>
      <GroupedVirtuoso
        // atTopStateChange={e => {
        //   if (e) handleRemoveMessageList()
        // }}
        // startReached={e => console.log('startReached', e)}
        initialTopMostItemIndex={Object.values(chats).length - 1}
        //
        atBottomStateChange={(e: any) => {
          if (e) setIsScrollBottom(e);
          else setIsScrollBottom(e);
        }}
        followOutput={"smooth"}
        groupContent={(index: any) => (
          <ChatGroupContent groups={groups.filter((item) => item !== "undefined")} index={index} />
        )}
        groupCounts={groupCounts}
        itemContent={(index) => {
          return <ChatItem value={newData[index]} messagingMode={messagingMode} />;
        }}
        style={{ height: "100%" }}
        // endReached={e => console.log('endReached', e)}
        ref={listRef}
      />
    </>
  );
};

const ChatList = ({
  currentUser,
  openAISetting,
  setOpenAISetting,
  openLeadInfo,
  setOpenLeadInfo,
  handleOpenBug,
}: any) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages]: any = useState({});
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  const [AITempDi, setAITempDi] = useState(false);
  const [state, setState] = useState({ message: "", open: false, type: "" });
  const [loading, setLoading] = useState(false);
  const [modeStatus, setModeStatus] = useState("messaging");
  const [messagingMode, setMessagingMode] = useState("text");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [openErrorUploadFile, setOpenErrorUploadFile] = useState(false);
  const [openSendToCrm, setOpenSendToCrm] = React.useState(false);
  const [openSendEmail, setOpenSendEmail] = React.useState(false);
  const { isTabletAndMobile } = useResponsive();
  const mode = useSelector(getMode);
  const listRef: any = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpenChat = useSelector(getIsOpenChat);
  const uid = useSelector(getUid);
  const activeConId = useSelector(getActiveConId);
  const [filesContent, setFilesContent]: File[] | any = useState(null);
  const [fileInfo, setFileInfo] = useState({ name: "", size: "", type: "" });
  const [objectUrl, setObjectUrl] = useState("");
  const [generatePushId, setGeneralPushId] = useState("");
  const [upload, setUpload] = useState(false);
  const [uploadProgressValue, setUploadProgressValue] = useState(0);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSendToCrm = () => {
    setOpenSendToCrm(!openSendToCrm);
  };

  const handleSendToEmail = () => {
    setOpenSendEmail(!openSendEmail);
  };

  const getMessages = (activeConId: any) => {
    const databaseRef = query(ref(firebaseDb, `messages/${uid}/${activeConId}`));
    onValue(
      databaseRef,
      async (snapshot) => {
        if (snapshot.exists()) {
          const values: any = [];
          snapshot.forEach(function (childSnapshot) {
            const childKey = childSnapshot.key;
            const childData = childSnapshot.val();
            if (!childData.timestamp) return;
            childData["pushId"] = childKey;
            values.push(childData);
          });

          setMessages(values);
        } else {
          console.log("error");
        }
      },
      { onlyOnce: true }
    );
  };

  useEffect(() => {
    const chatRef = ref(firebaseDb, `messages/${uid}/${activeConId}`);
    onChildChanged(chatRef, (snapshot) => {
      const data = snapshot.val();
      const key = snapshot.key;
      data["pushId"] = key;
      if (data?.timestamp && data?.pushId) {
        setMessages((prevState: any) => [...prevState, data]);
      }
    });

    return () => {
      setModeStatus("messaging");
      setMessagingMode("text");
      off(chatRef);
    };
  }, [activeConId, messages]);

  const sendUserMessages = () => {
    sendMessageHandler(null);
  };

  const sendMessageHandler = (generateUID: string | any, fileName?: string) => {
    if (!generateUID && message.trim() === "") return;
    setLoading(true);
    const data = {
      content: message.trim(),
      receive: false,
      read: true,
      sender: "Sender",
      service: {
        SMS: true,
        email: false,
        widget: false,
      },
    };

    if (generateUID) {
      // Adding mediaUrl
      const updatedData = {
        ...data,
        mediaURL: `media/${uid}/${activeConId}/${generateUID}/${fileName}`, // New property
        MMS: true,
      };
      set(ref(firebaseDb, `messages/${uid}/${activeConId}/${generateUID}`), updatedData).then(
        () => {
          setMessage("");
          setLoading(false);
          handleUploadFileDialog();
        }
      );

      return;
      // handle thumbnail
      const isPDF = fileName?.includes(".pdf");
      if (isPDF) return;
      const NewData = {
        bucketName: "salesagent-dev",
        blobName: `media/${uid}/${activeConId}/${generateUID}/${fileName}`,
        outputBlobName: `media/${uid}/${activeConId}/${generateUID}/thumbnail/${fileName}`,
      };
      set(
        ref(firebaseSecondDb, `services/thumbnail/${uid}/${activeConId}/${generateUID}`),
        NewData
      );
      //
    } else {
      push(ref(firebaseDb, `messages/${uid}/${activeConId}`), data).then(() => {
        setMessage("");
        setLoading(false);
      });
    }
  };

  const handleChange = useCallback((e: any) => {
    setMessage(e.target.value);
  }, []);

  function handleNavigate(to: To) {
    navigate(to);
  }

  function handleBack() {
    dispatch(handleOpenChat(false));
    handleNavigate("/");
    dispatch(activeConIdSet(undefined));
  }

  const handleKeyPress = (e: { key: string; shiftKey: any }) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Handle Shift + Enter (e.g., add a new line)
        setMessage((prev) => prev + "\n"); // Adds a newline character
      } else {
        // Handle Enter (e.g., send the message)
        sendUserMessages();
      }
    }
  };

  // listen for the setAITempDi
  useEffect(() => {
    const currentRef = ref(firebaseDb, `conversations/${uid}/${activeConId}/AIMode`);
    onValue(currentRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        dispatch(AImodeSet(data));
      }
    });
    return () => {
      off(currentRef);
    };
  }, [activeConId]);

  // listen for the setAITempDi
  useEffect(() => {
    const currentRef = ref(firebaseDb, `conversations/${uid}/${activeConId}/AITemporaryDisable`);
    onValue(currentRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setAITempDi(data);
      }
    });
    return () => {
      off(currentRef);
    };
  }, [activeConId]);

  useEffect(() => {
    if (activeConId) {
      getData(`conversations/${uid}/${activeConId}/AITemporaryDisable`).then((r: any) =>
        setAITempDi(r)
      );
      getMessages(activeConId);
    }
  }, [activeConId]);

  function changeAITemporaryDisabled() {
    if (AITempDi) return;
    const conversationRef = ref(firebaseDb, `conversations/${uid}/${activeConId}`);
    update(conversationRef, { AITemporaryDisable: true }).then(() => {
      setState({ message: "A.I. temporary disabled for 3 minutes", open: true, type: "" });
      setAITempDi(true);
    });
  }

  const scrollToIndex = () => {
    listRef?.current?.scrollIntoView({
      behavior: "smooth",
      index: Object.values(messages).length,
    });
  };

  const handleUploadFileDialog = () => {
    setOpenUploadFile(!openUploadFile);
  };

  const handleErrorFileDialog = () => {
    setOpenErrorUploadFile(!openErrorUploadFile);
  };

  // uploader
  const { openFilePicker, clear } = useFilePicker({
    accept: constants.CHAT_DROPZONE_ACCEPTED_FILES, // Accept all image file types and PDF files
    multiple: false, // Set to true if you want to allow multiple files
    onFilesSelected: (files: React.SetStateAction<null> | any) => {
      const file: any = files.plainFiles[0]; // Get the first selected file
      // Handle the uploaded file(s)
      if (file) {
        const { name, size, type } = file; // Destructure name and size
        // Size validation
        if (size > constants.MAX_FILE_SIZE) {
          handleErrorFileDialog();
          return; // Exit if file is too large
        } else {
          setFileInfo({ name, size, type }); // Store file info in state
          setFilesContent(files.plainFiles);
        }
      }
    },
  });

  useEffect(() => {
    if (filesContent?.length) {
      const file = URL.createObjectURL(filesContent[0]);
      setObjectUrl(file);
      setOpenUploadFile(true);
      uploadHandler();
    }
  }, [filesContent]);

  const uploadHandler = () => {
    setUpload(true);
    const pushId: any = createPushId();
    setGeneralPushId(pushId);
    const path = `media/${uid}/${activeConId}/${pushId}/${fileInfo?.name}`;
    handleUploadByPercent(filesContent, path, setUploadProgressValue).then(() => {
      setUpload(false);
      clear();
    });
  };

  const sendMessages = () => {
    sendMessageHandler(generatePushId, fileInfo?.name);
  };

  const renderStatus = (
    <Box display={"flex"} p={{ xs: "8px 0", md: 1 }} width={"100%"} flexWrap={"wrap"} gap={1}>
      {false && (
        <>
          <Button
            variant={"outlined"}
            color="primary"
            size="small"
            startIcon={<CallbackRequestIcon />}
          >
            Callback Req
          </Button>
          {(currentUser?.askCar || currentUser?.askGeneral) && (
            <Button
              variant={modeStatus === "guide-AI" ? "contained" : "outlined"}
              color="primary"
              size="small"
              startIcon={<GuidAIIcon />}
              onClick={() => {
                setModeStatus(modeStatus === "guide-AI" ? "messaging" : "guide-AI");
              }}
              endIcon={(currentUser?.askCar || currentUser?.askGeneral) && <Animation />}
            >
              Guid A.I.
            </Button>
          )}
        </>
      )}
      <Button
        variant={modeStatus === " Send to CRM" ? "contained" : "outlined"}
        color="primary"
        size="small"
        onClick={handleSendToCrm}
        startIcon={<SendToCRMIcon />}
      >
        Send to CRM
      </Button>
    </Box>
  );

  const renderMobile = (
    <Suspense>
      <Box
        borderRadius={3}
        width={"100%"}
        bgcolor={(theme) => theme.palette.background.paper}
        p={1}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box display={"flex"} width={"100%"}>
          <Box mt={-1}>
            <IconButton
              onClick={handleBack}
              sx={{
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <ArrowBackIcon fontSize="medium" />
            </IconButton>
          </Box>
          {handleBackground(currentUser, false, "small")}
          <Box width={"100%"}>
            <Typography fontWeight={"bold"} fontSize={18} ml={1}>
              {getContactName(currentUser)}
            </Typography>
            <Box display={"flex"} alignItems={"center"} width={"100%"}>
              <Box display={"flex"}>
                <Box mr={1} display={"flex"} alignItems={"center"}>
                  <PhoneIcon sx={{ fontSize: 18 }} />
                </Box>
                <Typography
                  fontWeight={600}
                  variant="body1"
                  display={"flex"}
                  alignItems={"center"}
                  color="#8F92A1"
                >
                  {currentUser?.phoneNumber && formatPhoneNumber(currentUser?.phoneNumber)}
                </Typography>
                <IconButton
                  onClick={() => {
                    handleCopyClipboard(currentUser?.phoneNumber);
                    setState({
                      message: "Phone successfully copy.",
                      open: true,
                      type: "",
                    });
                  }}
                >
                  <CopyIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
        {renderStatus}
        <Box display={"flex"} width={"100%"} justifyContent={"space-between"} flexWrap={"wrap"}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setOpenLeadInfo(!openLeadInfo)}
            startIcon={<LeadInfoIcon />}
          >
            Lead Info
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setOpenAISetting(!openAISetting)}
            startIcon={<AISettingIcon />}
          >
            A.I. Setting
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleOpenBug}
            startIcon={<ReportIssueIcon />}
          >
            Report Issue
          </Button>
        </Box>
      </Box>
    </Suspense>
  );

  const renderMode = (
    <>
      {false && (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          bgcolor={"white"}
          m={1}
          p={1}
          borderRadius={8}
          gap={1}
        >
          <Box width={"100%"}>
            <Button
              variant="outlined"
              sx={{ bgcolor: messagingMode === "text" ? "#EAF7FE" : "", width: "100%" }}
              color="primary"
              size="small"
              onClick={() => setMessagingMode("text")}
            >
              Text
            </Button>
          </Box>
          <Box width={"100%"}>
            <Button
              onClick={() => setMessagingMode("email")}
              variant="outlined"
              sx={{ bgcolor: messagingMode === "email" ? "#EAF7FE" : "", width: "100%" }}
              color="primary"
              size="small"
            >
              Email
            </Button>
          </Box>
        </Box>
      )}
      <Stack
        sx={{
          height: "100%",
          transition: "margin .5s ease",
          pt: 1,
        }}
      >
        {Object.values(messages)?.length > 0 &&
          renderChatItem(messages, setIsScrollBottom, listRef, messagingMode)}
      </Stack>
      {!isScrollBottom && (
        <Box
          display={"flex"}
          justifyContent={"end"}
          p={1}
          position={"absolute"}
          right={0}
          bottom={"4rem"}
        >
          <Fab color="inherit" aria-label="add" onClick={scrollToIndex}>
            <ArrowDownwardRoundedIcon />
          </Fab>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"end"}
          sx={{
            width: isTabletAndMobile ? "100%" : "95%",
            p: isTabletAndMobile ? "0" : "12px 8px",
          }}
        >
          {messagingMode === "text" && (
            <MessageInput
              onInputClick={changeAITemporaryDisabled}
              onAttachClick={openFilePicker}
              onSendClick={sendUserMessages}
              disableSendButton={message.trim() === ""}
              AITempDi={AITempDi}
              loading={loading}
              value={message}
              onKeyDown={handleKeyPress}
              onChange={handleChange}
              onOpenMenu={handleOpenMenu}
            />
          )}
          {messagingMode === "email" && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<SendEmailIcon />}
              sx={{ width: 160, height: 48 }}
              onClick={handleSendToEmail}
            >
              New Email
            </Button>
          )}
        </Box>
      </Box>
      <ScheduleMessage
        anchorEl={anchorEl}
        handleClose={handleCloseMenu}
        value={message.trim()}
        setMessage={setMessage}
      />
    </>
  );

  return (
    <>
      <Grid
        item
        xs={12}
        md={7}
        lg={isOpenChat ? 5 : 8}
        display={"flex"}
        height={"100%"}
        flexDirection={"column"}
        sx={{
          display: { xs: !isOpenChat ? "none" : "flex", md: "flex" },
        }}
      >
        {activeConId && isTabletAndMobile && renderMobile}
        {activeConId && !isTabletAndMobile && renderStatus}
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          position={"relative"}
          bgcolor={(theme) => theme.palette.common.white}
          borderRadius={3}
          margin={"auto"}
          sx={{
            width: "98%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {!isOpenChat ? (
            <Box textAlign={"center"} margin={"auto"}>
              <Box display={"flex"} justifyContent={"center"}>
                <Box display={"flex"} width={"100%"} justifyContent={"center"}>
                  <img
                    alt="infinity logo"
                    src={mode === "dark" ? "/images/light-logo.png" : "/images/dark-logo.png"}
                    loading="lazy"
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            renderMode
          )}
          <Snackbar
            handleClose={() => setState({ message: "", open: false, type: "" })}
            message={state.message}
            open={state.open}
            type={state.type}
            mobile={isTabletAndMobile}
          />
        </Box>
      </Grid>
      <UploadFile
        open={openUploadFile}
        handleClose={handleUploadFileDialog}
        message={message}
        onChange={handleChange}
        onMessageHandler={sendMessages}
        fileInfo={fileInfo}
        objectUrl={objectUrl}
        upload={upload}
        uploadProgressValue={uploadProgressValue}
      />
      <ErrorUploadFile
        open={openErrorUploadFile}
        handleClose={handleErrorFileDialog}
        onOpenFilePicker={openFilePicker}
      />
      <SendToCrm open={openSendToCrm} handleClose={handleSendToCrm} />
      <SendEmailModal open={openSendEmail} handleClose={handleSendToEmail} />
      <GuideAI onClose={() => setModeStatus("messaging")} open={modeStatus === "guide-AI"} />
    </>
  );
};

export default ChatList;
