import { SvgIcon } from "@mui/material";
import React from "react";

export default function MakeIcon() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M0.284348 9.56522C0.309565 9.48435 0.32087 9.4 0.349565 9.32L2.69565 2.86174C3.00203 2.02374 3.55823 1.29996 4.28912 0.788187C5.02 0.27641 5.89036 0.00129291 6.78261 0H14.087C14.9793 0.00123387 15.8498 0.276429 16.5808 0.788387C17.3117 1.30034 17.8678 2.02438 18.1739 2.86261L20.5217 9.32C20.5504 9.4 20.5617 9.48435 20.587 9.56522H0.284348ZM17.3913 11.3043V13.0435C17.3913 13.2741 17.2997 13.4953 17.1366 13.6584C16.9735 13.8214 16.7524 13.913 16.5217 13.913C16.2911 13.913 16.0699 13.8214 15.9069 13.6584C15.7438 13.4953 15.6522 13.2741 15.6522 13.0435V11.3043H5.21739V13.0435C5.21739 13.2741 5.12578 13.4953 4.9627 13.6584C4.79963 13.8214 4.57845 13.913 4.34783 13.913C4.1172 13.913 3.89603 13.8214 3.73295 13.6584C3.56988 13.4953 3.47826 13.2741 3.47826 13.0435V11.3043H0V13.0435C0.00111163 13.8885 0.248439 14.7149 0.711738 15.4216C1.17504 16.1282 1.83423 16.6846 2.6087 17.0226V18.2609C2.6087 18.7221 2.79192 19.1645 3.11808 19.4906C3.44423 19.8168 3.88658 20 4.34783 20C4.80907 20 5.25143 19.8168 5.57758 19.4906C5.90373 19.1645 6.08696 18.7221 6.08696 18.2609V17.3913H14.7826V18.2609C14.7826 18.7221 14.9658 19.1645 15.292 19.4906C15.6181 19.8168 16.0605 20 16.5217 20C16.983 20 17.4253 19.8168 17.7515 19.4906C18.0776 19.1645 18.2609 18.7221 18.2609 18.2609V17.0226C19.0353 16.6846 19.6945 16.1282 20.1578 15.4216C20.6211 14.7149 20.8685 13.8885 20.8696 13.0435V11.3043H17.3913Z"
        fill="#07A4FC"
      />
    </SvgIcon>
  );
}
