const sortByTimestamp = (list: any) => {
  return list
    .sort(function (x: { updatedDate: number }, y: { updatedDate: number }) {
      return x.updatedDate - y.updatedDate;
    })
    .reverse();
};

export function formatTimestamp(timestamp: string | number | Date) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const convertTime = (timestamp: number, locale = "en-US") => {
  const now: any = new Date();
  const messageDate: any = new Date(timestamp);
  const timeDifference = now - messageDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (seconds < 60) {
    return "just now";
  } else if (minutes === 1) {
    return "1 minute ago";
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours === 1) {
    return "1 hour ago";
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days === 1) {
    return "1 day ago";
  } else if (days < 30) {
    return `${days} days ago`;
  } else if (months === 1) {
    return "1 month ago";
  } else if (months < 12) {
    return `${months} months ago`;
  } else {
    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return messageDate.toLocaleDateString(locale, options);
  }
};

const sortByTime = (list: any) => {
  return list.sort(function (x: { timestamp: number }, y: { timestamp: number }) {
    return x?.timestamp - y?.timestamp;
  });
};
export { sortByTimestamp, convertTime, sortByTime };
