import { SvgIcon } from "@mui/material";
import React from "react";

export default function Messenger() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M20.0003 0H4.00006C2.93917 0 1.92174 0.421433 1.17159 1.17159C0.421433 1.92175 0 2.93918 0 4.00006L0 16.0002C0 17.0611 0.421433 18.0785 1.17159 18.8287C1.92174 19.5788 2.93917 20.0003 4.00006 20.0003H6.9001L11.3512 23.7633C11.5317 23.9161 11.7606 24 11.9972 24C12.2337 24 12.4626 23.9161 12.6432 23.7633L17.1002 20.0003H20.0003C21.0612 20.0003 22.0786 19.5788 22.8287 18.8287C23.5789 18.0785 24.0003 17.0611 24.0003 16.0002V4.00006C24.0003 2.93918 23.5789 1.92175 22.8287 1.17159C22.0786 0.421433 21.0612 0 20.0003 0ZM7.0001 5.00007H12.0002C12.2654 5.00007 12.5197 5.10543 12.7073 5.29297C12.8948 5.48051 13.0002 5.73486 13.0002 6.00008C13.0002 6.26531 12.8948 6.51966 12.7073 6.7072C12.5197 6.89474 12.2654 7.0001 12.0002 7.0001H7.0001C6.73488 7.0001 6.48052 6.89474 6.29298 6.7072C6.10544 6.51966 6.00008 6.26531 6.00008 6.00008C6.00008 5.73486 6.10544 5.48051 6.29298 5.29297C6.48052 5.10543 6.73488 5.00007 7.0001 5.00007ZM17.0002 15.0002H7.0001C6.73488 15.0002 6.48052 14.8949 6.29298 14.7073C6.10544 14.5198 6.00008 14.2654 6.00008 14.0002C6.00008 13.735 6.10544 13.4806 6.29298 13.2931C6.48052 13.1055 6.73488 13.0002 7.0001 13.0002H17.0002C17.2655 13.0002 17.5198 13.1055 17.7074 13.2931C17.8949 13.4806 18.0002 13.735 18.0002 14.0002C18.0002 14.2654 17.8949 14.5198 17.7074 14.7073C17.5198 14.8949 17.2655 15.0002 17.0002 15.0002ZM17.0002 11.0002H7.0001C6.73488 11.0002 6.48052 10.8948 6.29298 10.7073C6.10544 10.5197 6.00008 10.2654 6.00008 10.0001C6.00008 9.73492 6.10544 9.48056 6.29298 9.29303C6.48052 9.10549 6.73488 9.00013 7.0001 9.00013H17.0002C17.2655 9.00013 17.5198 9.10549 17.7074 9.29303C17.8949 9.48056 18.0002 9.73492 18.0002 10.0001C18.0002 10.2654 17.8949 10.5197 17.7074 10.7073C17.5198 10.8948 17.2655 11.0002 17.0002 11.0002Z" />
    </SvgIcon>
  );
}
