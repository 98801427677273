import { SvgIcon } from "@mui/material";
import React from "react";

export default function FilterIcon({ ...props }) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" fill="#07A4FC" />
    </SvgIcon>
  );
}
