import React, { useEffect, useState } from "react";
import { getUid } from "../redux/authSlice";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Typography } from "@mui/material";
import Calendar from "../components/calendar";
import { getDataOE } from "../utils/firebase";
import { getContactName, truncateString } from "../utils/string";

const Appointment = () => {
  const uid = useSelector(getUid);

  const [events, setEvents]: any[] = useState([]);
  const [loading, setLoading]: any[] = useState(true);

  const handleGetItem = () => {
    getDataOE(`conversations/${uid}`, "appointmentIsSet", true)
      .then((r: any) => {
        const values: any = [];
        Object.entries(r)
          .sort((a: any, b: any) => b[1] - a[1])
          .forEach(([key, item]: any) => {
            const childKey = key;
            const childData = item;
            const value: any = {};
            if (!childData?.appointmentIsSet) return;
            if (!childData?.appointmentTime) return;

            const unixTimestamp = childData.appointmentTime;
            const eventDate = new Date(unixTimestamp);
            const startDate = eventDate.toISOString();
            value.start = startDate;
            value.key = childKey;
            value.title = truncateString(getContactName(childData), 20);
            values.push(value);
          });

        setEvents(values);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetItem();
  }, [uid]);

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
      }}
      borderRadius={3}
    >
      <Typography variant="h5" fontWeight={600} p={1}>
        Appointment
      </Typography>
      <Box p={{ xs: 0, md: 1, display: "flex", justifyContent: "center" }}>
        {!loading ? <Calendar events={events} /> : <CircularProgress />}
      </Box>
    </Box>
  );
};

export default Appointment;
