import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { format, parseISO } from "date-fns";

type PropTypes = {
  dailyStatistics: any;
  dailyStatisticsStatus: string;
};

export default function LineChartPreview({ dailyStatistics, dailyStatisticsStatus }: PropTypes) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={dailyStatistics}>
        <XAxis dataKey="timestamp" tickFormatter={(value) => format(parseISO(value), "MMM d")} />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey={dailyStatisticsStatus} stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
}
