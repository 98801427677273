import { SvgIcon } from "@mui/material";
import React from "react";

export default function VINIcon() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="14"
      viewBox="0 0 23 14"
      fill="none"
    >
      <path
        d="M14.8227 9.9001C14.4913 9.9001 14.2227 9.63147 14.2227 9.3001V0.500098C14.2227 0.279184 14.4017 0.100098 14.6227 0.100098H16.0147L19.8879 5.90414C20.1076 6.23325 20.6207 6.07777 20.6207 5.6821V0.500098C20.6207 0.279184 20.7997 0.100098 21.0207 0.100098H21.8127C22.144 0.100098 22.4127 0.368727 22.4127 0.700098V9.3001C22.4127 9.63147 22.144 9.9001 21.8127 9.9001H20.6207L16.7472 4.10752C16.5273 3.77878 16.0147 3.9344 16.0147 4.32987V9.3001C16.0147 9.63147 15.746 9.9001 15.4147 9.9001H14.8227Z"
        fill="#07A4FC"
      />
      <path
        d="M10.8313 9.9001C10.6656 9.9001 10.5312 9.76578 10.5312 9.6001V0.400098C10.5312 0.234413 10.6656 0.100098 10.8313 0.100098H12.0233C12.1889 0.100098 12.3233 0.234412 12.3233 0.400098V9.6001C12.3233 9.76578 12.1889 9.9001 12.0233 9.9001H10.8313Z"
        fill="#07A4FC"
      />
      <path
        d="M4.00344 9.9001C3.75154 9.9001 3.52646 9.74275 3.43994 9.50618L0.294794 0.906176C0.151622 0.51469 0.441449 0.100098 0.858294 0.100098H1.4915C1.74686 0.100098 1.97424 0.26173 2.05817 0.502908L4.31317 6.98319C4.43766 7.34095 4.94347 7.34134 5.06853 6.98378L7.33543 0.502021C7.41962 0.2613 7.64678 0.100098 7.90179 0.100098H8.50771C8.92455 0.100098 9.21438 0.514691 9.07121 0.906177L5.92606 9.50618C5.83954 9.74276 5.61446 9.9001 5.36256 9.9001H4.00344Z"
        fill="#07A4FC"
      />
      <path
        d="M0.790039 11.1999C0.790039 11.0342 0.924354 10.8999 1.09004 10.8999L1.49004 10.8999C1.65572 10.8999 1.79004 11.0342 1.79004 11.1999L1.79004 13.5999C1.79004 13.7656 1.65572 13.8999 1.49004 13.8999L1.09004 13.8999C0.924354 13.8999 0.790039 13.7656 0.790039 13.5999L0.790039 11.1999Z"
        fill="#07A4FC"
      />
      <path
        d="M2.68984 11.1999C2.68984 11.0342 2.82416 10.8999 2.98984 10.8999L3.78984 10.8999C3.95553 10.8999 4.08984 11.0342 4.08984 11.1999L4.08984 13.5999C4.08984 13.7656 3.95553 13.8999 3.78984 13.8999L2.98984 13.8999C2.82416 13.8999 2.68984 13.7656 2.68984 13.5999L2.68984 11.1999Z"
        fill="#07A4FC"
      />
      <path
        d="M4.98965 11.1999C4.98965 11.0342 5.12396 10.8999 5.28965 10.8999L8.08965 10.8999C8.25533 10.8999 8.38965 11.0342 8.38965 11.1999L8.38965 13.5999C8.38965 13.7656 8.25533 13.8999 8.08965 13.8999L5.28965 13.8999C5.12396 13.8999 4.98965 13.7656 4.98965 13.5999L4.98965 11.1999Z"
        fill="#07A4FC"
      />
      <path
        d="M9.29004 11.1999C9.29004 11.0342 9.42435 10.8999 9.59004 10.8999L10.49 10.8999C10.6557 10.8999 10.79 11.0342 10.79 11.1999L10.79 13.5999C10.79 13.7656 10.6557 13.8999 10.49 13.8999L9.59004 13.8999C9.42435 13.8999 9.29004 13.7656 9.29004 13.5999L9.29004 11.1999Z"
        fill="#07A4FC"
      />
      <path
        d="M11.6896 11.1999C11.6896 11.0342 11.824 10.8999 11.9896 10.8999L14.0896 10.8999C14.2553 10.8999 14.3896 11.0342 14.3896 11.1999L14.3896 13.5999C14.3896 13.7656 14.2553 13.8999 14.0896 13.8999L11.9896 13.8999C11.824 13.8999 11.6896 13.7656 11.6896 13.5999L11.6896 11.1999Z"
        fill="#07A4FC"
      />
      <path
        d="M15.2904 11.1999C15.2904 11.0342 15.4247 10.8999 15.5904 10.8999L17.3904 10.8999C17.5561 10.8999 17.6904 11.0342 17.6904 11.1999L17.6904 13.5999C17.6904 13.7656 17.5561 13.8999 17.3904 13.8999L15.5904 13.8999C15.4247 13.8999 15.2904 13.7656 15.2904 13.5999L15.2904 11.1999Z"
        fill="#07A4FC"
      />
      <path
        d="M18.5904 11.1999C18.5904 11.0342 18.7247 10.8999 18.8904 10.8999L19.3904 10.8999C19.5561 10.8999 19.6904 11.0342 19.6904 11.1999L19.6904 13.5999C19.6904 13.7656 19.5561 13.8999 19.3904 13.8999L18.8904 13.8999C18.7247 13.8999 18.5904 13.7656 18.5904 13.5999L18.5904 11.1999Z"
        fill="#07A4FC"
      />
      <path
        d="M20.5902 11.1999C20.5902 11.0342 20.7245 10.8999 20.8902 10.8999L22.6902 10.8999C22.8559 10.8999 22.9902 11.0342 22.9902 11.1999L22.9902 13.5999C22.9902 13.7656 22.8559 13.8999 22.6902 13.8999L20.8902 13.8999C20.7245 13.8999 20.5902 13.7656 20.5902 13.5999L20.5902 11.1999Z"
        fill="#07A4FC"
      />
    </SvgIcon>
  );
}
