import { Box } from "@mui/material";
import React from "react";

export default function CustomizeBox({ children }: any) {
  return (
    <Box
      borderRadius={5}
      border={"1px solid #F2F2F2"}
      p={1}
      display={"grid"}
      width={"100%"}
      gap={2}
    >
      {children}
    </Box>
  );
}
