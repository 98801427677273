import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { getMode } from "../../redux/layoutSlice";
import { useSelector } from "react-redux";

function ChatGroupContent({ index, groups }: any) {
  if (groups[index] === undefined) return null;
  const mode = useSelector(getMode);

  return (
    <Box
      sx={{
        backgroundColor: mode === "dark" ? "#FFF" : "#8F92A14D",
        borderRadius: 200,
        margin: "auto",
        padding: 1,
        width: "fit-content",
        opacity: 0.9,
      }}
    >
      <Typography
        variant="caption"
        component={"p"}
        sx={{
          fontWeight: 400,
          color: "#000",
          fontStyle: "normal",
          lineHeight: "normal",
        }}
      >
        {groups[index]}
      </Typography>
    </Box>
  );
}

export default memo(ChatGroupContent);
