function truncateString(str: string, maxLength: number) {
  if (str?.length > maxLength) {
    // Use slice to get a substring up to maxLength characters
    // and concatenate "..." to the end
    return str.slice(0, maxLength) + "...";
  } else {
    // If the string is already shorter than maxLength, return it as is
    return str;
  }
}

const vinRegex = /^[a-hj-npr-z0-9]{17}$/;
function isValidVIN(vin: string) {
  return vinRegex.test(vin);
}

const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
function isValidName(name: string) {
  return nameRegex.test(name);
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
function isValidEmail(email: string) {
  return emailRegex.test(email);
}

const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
function isValidPhoneNumber(phoneNumber: string) {
  return phoneRegex.test(phoneNumber);
}

function capitalizeFullName(fullName: string): string {
  if (!fullName) return ""; // Handle empty or null input
  return fullName
    .split(" ") // Split the name into words
    .map(
      (word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize each word
    )
    .join(" "); // Join the words back into a single string
}

function getContactName(con: { firstName: string; lastName: string; phoneNumber: string }) {
  const name =
    con?.firstName &&
    con?.lastName &&
    con?.firstName !== "null" &&
    con?.firstName !== "Null" &&
    con?.lastName !== "null" &&
    con?.lastName !== "Null" &&
    con?.firstName !== "N/A" &&
    con?.lastName !== "N/A"
      ? (con?.firstName || "") + " " + (con?.lastName || "")
      : con?.phoneNumber;
  return capitalizeFullName(name);
}

const formattedNumber = (number: string) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export {
  truncateString,
  isValidVIN,
  isValidName,
  isValidEmail,
  isValidPhoneNumber,
  capitalizeFullName,
  getContactName,
  formattedNumber,
};
