import React, { useEffect, useMemo } from "react";
import LazyImage from "../lazyImage/lazyImage";
import { getBase64FromFirebase } from "../../utils/gcs";
import { useDispatch, useSelector } from "react-redux";
import { filesSet, getFiles } from "../../redux/userSlice";
import { CircularProgress } from "@mui/material";

export default function ImageDownloader({ value, id }: any) {
  const dispatch = useDispatch();
  const files = useSelector(getFiles);

  const handleAlt = (path: string) => {
    // Split the path by '/' and get the last element for the filename
    const parts = path.split("/");
    const filename = parts[parts.length - 1]; // This will give you 'camera_lense_0.jpeg'
    return filename;
  };

  useEffect(() => {
    const handleSrc = async (mediaURL: string) => {
      const data = mediaURL.split(",");
      const url = data[0];
      try {
        const base64 = await getBase64FromFirebase(url);
        dispatch(filesSet({ id: id, base64: base64 }));
      } catch (e) {
        console.log(e);
      }
    };
    const exists = files?.some((item: { id: string }) => item.id === id);
    if (!exists) {
      handleSrc(value);
    }
    return () => {
      console.log("unmount");
    };
  }, [files, value, dispatch, id]);

  const getFile = useMemo(() => {
    return files?.length > 0 && files?.find((i: { id: any }) => i.id === id);
  }, [files]);

  if (getFile)
    return <LazyImage src={getFile.base64} alt={handleAlt(value)} placeholder={handleAlt(value)} />;
  else return <CircularProgress color="info" />;
}
