import {
  Alert,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import Radio from "@mui/material/Radio";
import AddIcon from "@mui/icons-material/Add";
import ControlledInput from "../../components/input/controlled-input";
import Button from "../../components/button/button";
import ControlledBox from "../../components/box/controlled-box";
import MuiPhone from "../../components/input/mui-phone-input";
import { validateMobileNumber } from "../../utils/utils";

export default function Notifications({ state, setState, onMorePerson, onValidate }: any) {
  const handleChange =
    (index: number) =>
    ({ currentTarget: input }: any) => {
      const value = input.type === "checkbox" ? input.checked : input.value; // Handle checkbox

      // Update the specific item in the state
      setState((prevState: any) => {
        const updatedItems = [...prevState]; // Create a copy of the current state
        updatedItems[index] = {
          ...updatedItems[index], // Spread the existing item
          [input.name]: value, // Update the specific field
        };
        return updatedItems; // Return the new state
      });
    };

  const handleChangePhone = (index: number) => (number: any) => {
    if (validateMobileNumber(number)) onValidate(true);
    else onValidate(false);
    setState((prevState: any) => {
      const updatedItems = [...prevState]; // Create a copy of the current state
      updatedItems[index] = {
        ...updatedItems[index], // Spread the existing item
        phone_number: number, // Update the specific field
      };
      return updatedItems; // Return the new state
    });
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box display={"flex"} flexDirection={"column"}>
        <Typography variant="h6" gutterBottom color={"#505050"} fontWeight={700}>
          Notification
        </Typography>
        <Typography variant="body1" gutterBottom color="#9B9B9B">
          Please add information of the people who you would like to receive notifications through
          text
        </Typography>
      </Box>
      <ControlledBox>
        {state?.length > 0 &&
          state?.map((s: any, index: number) => (
            <Box key={index}>
              <Grid container justifyContent={"left"} spacing={3}>
                <Grid item md={8} xs={12}>
                  <Box display={"flex"} gap={1}>
                    <ControlledInput
                      placeholder="First Name"
                      value={s.first_name}
                      label="First Name"
                      name="first_name"
                      onChange={handleChange(index)} // Pass the index here
                    />
                    <ControlledInput
                      placeholder="Last Name"
                      value={s.last_name}
                      label="Last Name"
                      name="last_name"
                      onChange={handleChange(index)} // Pass the index here
                    />
                  </Box>
                  <Box display={"flex"} gap={1}>
                    <Grid item xs={6}>
                      <FormControl fullWidth={true}>
                        <MuiPhone
                          value={s.phone_number}
                          label={"Phone Number"}
                          id="phone_number"
                          placeholder="Enter your phone number"
                          onChange={handleChangePhone(index)}
                          error={validateMobileNumber(s?.phone_number) ? "" : "error"}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl sx={{ mt: 1 }}>
                        <FormLabel id="demo-radio-buttons-group-label" sx={{ color: "#2F2F2F" }}>
                          Role
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          sx={{ flexDirection: "row" }}
                        >
                          <FormControlLabel
                            value="owner"
                            name="role"
                            control={<Radio />}
                            label="Owner"
                            checked={s.role === "owner"}
                            onChange={handleChange(index)} // Pass the index here
                          />
                          <FormControlLabel
                            value="staff"
                            control={<Radio />}
                            name="role"
                            label="Staff"
                            checked={s.role === "staff"}
                            onChange={handleChange(index)} // Pass the index here
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box display={"flex"} alignItems={"baseline"} flexDirection={"column"}>
                    <Typography color={"#505050"} variant="body1" fontWeight={500}>
                      Notification Option
                    </Typography>
                    <FormControlLabel
                      control={<Checkbox sx={{ pl: 0 }} />}
                      label="Callback Request"
                      labelPlacement="end"
                      checked={s.callback_request}
                      name="callback_request"
                      onChange={handleChange(index)} // Pass the index here
                    />
                    <FormControlLabel
                      control={<Checkbox sx={{ pl: 0 }} />}
                      label="Appointments"
                      labelPlacement="end"
                      name="appointments"
                      checked={s.appointments}
                      onChange={handleChange(index)} // Pass the index here
                    />
                    <FormControlLabel
                      control={<Checkbox sx={{ pl: 0 }} />}
                      label="Hot Leads"
                      labelPlacement="end"
                      name="hot_leads"
                      checked={s.hot_leads}
                      onChange={handleChange(index)} // Pass the index here
                    />
                    <FormControlLabel
                      control={<Checkbox sx={{ pl: 0 }} />}
                      label="First Responder"
                      labelPlacement="end"
                      name="first_responder"
                      checked={s.first_responder}
                      onChange={handleChange(index)} // Pass the index here
                    />
                    <FormControlLabel
                      control={<Checkbox sx={{ pl: 0 }} />}
                      label="Dealer Assistant Request"
                      labelPlacement="end"
                      name="dealer_assistant"
                      checked={s.dealer_assistant}
                      onChange={handleChange(index)} // Pass the index here
                    />
                  </Box>
                </Grid>
              </Grid>
              {state?.length > 1 && <Divider sx={{ my: 2 }} />}
            </Box>
          ))}
        {state?.length < 2 ? (
          <Box display={"flex"}>
            <Button variant="outlined" startIcon={<AddIcon />} onClick={onMorePerson}>
              Add One More Person
            </Button>
          </Box>
        ) : (
          <Alert
            variant="filled"
            severity="info"
            sx={{
              bgcolor: "#CDEDFE",
              color: "#027EC3",
              borderRadius: "8px",
            }}
          >
            To add one more notification number please
            <b style={{ color: "#027EC3" }}> contact support (888) 288-8856.</b>
          </Alert>
        )}
      </ControlledBox>
    </Box>
  );
}
