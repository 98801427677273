import React, { useEffect } from "react";
import { Box, Grid, Tab, Tabs } from "@mui/material";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AImodeSet, getActiveConId, getAIMode, getIsOpenChat } from "../../redux/userSlice";
import Dialog from "../../components/dialog/dialog";
import { ref, update } from "firebase/database";
import { getUid } from "../../redux/authSlice";
import { firebaseDb } from "../../firebase/app";
import Snackbar from "../../components/snackbar/snackbar";
import LeadInfo from "./lead-info";
import AISetting from "./AI-setting";
import RenderCarInfo from "./car-info";
import { getData } from "../../utils/firebase";
import Note from "./note";
import LeadInfoIcon from "../../assets/icon/lead-info";
import NoteIcon from "../../assets/icon/note";
import SummaryIcon from "../../assets/icon/summary";
import Summary from "./summary";

const infiniteData = {
  email: "",
  phoneNumber: "",
  year: "",
  make: "",
  model: "",
  priceOnWebsite: "",
  VINNumber: "",
  mileage: "",
};
export default function ChatInfo({
  correctUser,
  setAnchorSEl,
  handleUpdateLead,
  handleOpenBug,
  openAISetting,
  setOpenAISetting,
  openLeadInfo,
  setOpenLeadInfo,
}: any) {
  // State
  const [isFollow, setISFollow] = useState(true);
  const [lead, setLead] = useState(infiniteData);
  const [message, setMessage] = useState({ message: "", open: false, type: "" });
  const [tabValue, setTabValue] = React.useState(0);

  // Selector
  const activeConId = useSelector(getActiveConId);
  const isOpenChat = useSelector(getIsOpenChat);
  const uid = useSelector(getUid);
  const AIMode = useSelector(getAIMode);

  const dispatch = useDispatch();

  const getLeadData = () => {
    getData(`dealers/${uid}/leads/${activeConId}`).then((r: any) => setLead(r));
  };

  const handleOpenStatusMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorSEl(event.currentTarget);

  function handleChange(e: any) {
    const { name, checked } = e.target;
    const currentRef = ref(firebaseDb, `conversations/${uid}/${activeConId}`);
    if (name === "AIMode") {
      dispatch(AImodeSet(checked));
      update(currentRef, { AIMode: checked });
    }

    if (name === "followUp") {
      update(currentRef, { followUpEnabled: checked });
      setISFollow(checked);
    }
  }

  useEffect(() => {
    dispatch(AImodeSet(correctUser?.AIMode));
    setISFollow(correctUser?.followUpEnabled || false);
  }, [correctUser]);

  useEffect(() => {
    if (activeConId) getLeadData();
  }, [activeConId]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const renderLeadInfo = (
    <LeadInfo
      handleOpenStatusMenu={handleOpenStatusMenu}
      handleOpenBug={handleOpenBug}
      correctUser={correctUser}
      handleUpdateLead={handleUpdateLead}
      lead={lead}
      setMessage={setMessage}
      value={tabValue}
      setValue={setTabValue}
    />
  );

  const renderCarInformation = <RenderCarInfo lead={lead} setMessage={setMessage} />;

  const renderAISetting = (
    <AISetting AIMode={AIMode} handleChange={handleChange} isFollow={isFollow} />
  );

  const renderTab = (
    <Box sx={{ width: "100%", bgcolor: "#F2F2F2" }} borderRadius={"500px"}>
      <Tabs
        value={tabValue}
        onChange={handleChangeTab}
        centered
        TabIndicatorProps={{
          sx: {
            display: "none", // Hides the tab indicator (underline)
          },
        }}
        sx={{
          "& .MuiTabs-flexContainer": {
            alignItems: "center",
          },
        }}
      >
        <Tab
          icon={<LeadInfoIcon />}
          iconPosition="start"
          label="Lead Info"
          sx={{
            borderBottom: tabValue === 0 ? "3px solid #07A4FC" : "",
            minHeight: "40px",
            height: "40px",
            borderRadius: "500px",
            textTransform: "none",
          }}
        />
        <Tab
          icon={<NoteIcon />}
          sx={{
            borderBottom: tabValue === 1 ? "3px solid #07A4FC" : "",
            minHeight: "40px",
            height: "40px",
            borderRadius: "500px",
            textTransform: "none",
          }}
          iconPosition="start"
          label="Note"
        />
        <Tab
          icon={<SummaryIcon />}
          sx={{
            borderBottom: tabValue === 2 ? "3px solid #07A4FC" : "",
            minHeight: "40px",
            height: "40px",
            borderRadius: "500px",
            textTransform: "none",
          }}
          iconPosition="start"
          label="Summery"
        />
      </Tabs>
    </Box>
  );

  const desktopSize = (
    <Grid
      item
      lg={isOpenChat ? 3 : false}
      flexDirection={"column"}
      gap={2}
      p={1}
      display={"flex"}
      height={"100%"}
      width={"100%"}
      sx={{
        display: { xs: "none", lg: isOpenChat ? "flex" : "none" },
        boxShadow: " 0px 0px 4px 0px rgba(0, 0, 0, 0.12)",
        borderRadius: "26px",
        overflowY: "scroll",
      }}
    >
      {renderTab}
      {tabValue === 0 && (
        <>
          {renderLeadInfo}
          {renderCarInformation}
          {renderAISetting}
        </>
      )}
      {tabValue === 1 && <Note />}
      {tabValue === 2 && <Summary content={correctUser?.summaryOfConversation} />}
    </Grid>
  );

  const renderDialogAISetting = (
    <Dialog
      open={openAISetting}
      fullWidth={true}
      handleClose={() => setOpenAISetting(false)}
      renderContent={renderAISetting}
      renderTitle={"A.I. Setting"}
    />
  );

  const renderDialogLeadInfo = (
    <Dialog
      open={openLeadInfo}
      fullWidth={true}
      handleClose={() => setOpenLeadInfo(false)}
      renderContent={
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          {renderTab}
          {tabValue === 0 && (
            <>
              {renderLeadInfo}
              {renderCarInformation}
            </>
          )}
          {tabValue === 1 && <Note />}
          {tabValue === 2 && <Summary content={correctUser?.summaryOfConversation} />}
        </Box>
      }
      renderTitle={"Lead Information"}
    />
  );

  return (
    <>
      {desktopSize}
      {renderDialogAISetting}
      {renderDialogLeadInfo}
      <Snackbar
        handleClose={() => setMessage({ message: "", open: false, type: "" })}
        message={message.message}
        open={message.open}
        type={message.type}
      />
    </>
  );
}
