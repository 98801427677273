import React, { useEffect } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import Dialog from "../../components/dialog/dialog";
import { useState } from "react";
import * as yup from "yup";

import Snackbar from "../../components/snackbar/snackbar";
import { push, ref } from "firebase/database";
import { firebaseDb } from "../../firebase/app";
import { getUid } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import MuiPhone from "../../components/input/mui-phone-input";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Checkbox from "@mui/material/Checkbox";
import { getData } from "../../utils/firebase";
import Button from "../../components/button/button";
import ControlledInput from "../../components/input/controlled-input";
import Textarea from "../../components/textarea/textarea";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("full name is required")
    .min(3, "Full name must be at least 3 characters long")
    .max(20, "Full name must be at maximum 20 characters long"),
  lastName: yup.string().notRequired(),
  phoneNumber: yup.string().required("Phone number is required"),
  email: yup.string().notRequired().email("Invalid email"),
  manualLeadFirstMessage: yup.string().notRequired(),
  description: yup.string().required("description is required").min(3).max(255),
  testDrive: yup.boolean(),
  make: yup.string().required(),
  mileage: yup.number().required(),
  model: yup.string().required(),
  priceOnWebsite: yup.number().required(),
  year: yup.number().required(),
  VINNumber: yup
    .string()
    .required("VIN number is required")
    .matches(/^[A-HJ-NPR-Z0-9]{17}$/, "Invalid VIN number format"), // Adjust the regex as needed
});

const steps = ["Personal Info", "Lead Info", "A.I. Settings"];

interface FormInputs {
  firstName: string;
  lastName?: string | any;
  phoneNumber: string;
  email?: string | any;
  testDrive?: boolean;
  description: string;
  VINNumber: string;
  make: string;
  mileage: number;
  model: string;
  priceOnWebsite: number;
  year: number;
  manualLeadFirstMessage?: string | any;
}

const defaultValues: FormInputs = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  description: "",
  VINNumber: "",
  testDrive: false,
  make: "",
  mileage: 0,
  model: "",
  priceOnWebsite: 0,
  year: 0,
  manualLeadFirstMessage: "",
};

export default function CreateLead({ open, handleClose }: any) {
  // Selector
  const uid = useSelector(getUid);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [lead, setLead] = useState(defaultValues);
  const [message, setMessage] = React.useState({
    text: "",
    type: "",
    open: false,
  });

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    if (activeStep === steps.length - 1) onSubmit();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
  } = useForm<FormInputs>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  function handleCloseDialog() {
    handleClose();
    reset();
    setActiveStep(0);
  }

  const onSubmit = () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      description,
      VINNumber,
      make,
      mileage,
      model,
      priceOnWebsite,
      year,
      manualLeadFirstMessage,
    }: any = getValues();

    const data = {
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      email: email?.trim(),
      phoneNumber,
      description,
      VINNumber,
      testDrive: lead.testDrive,
      make,
      mileage: parseInt(mileage),
      model,
      priceOnWebsite: parseInt(priceOnWebsite),
      year: parseInt(year),
      source: "Manual Entry",
      manualLeadFirstMessage,
      leadStatus: "hot",
    };
    if (manualLeadFirstMessage === "" || manualLeadFirstMessage === undefined)
      delete data.manualLeadFirstMessage;

    setActiveStep(0);
    push(ref(firebaseDb, `dealers/${uid}/leads`), data)
      .then(() => {
        handleCloseDialog();
        setMessage({
          text: "success",
          type: "",
          open: true,
        });
      })
      .catch((e) => {
        handleClose();
        setMessage({
          text: e.message,
          type: "error",
          open: true,
        });
      });
  };

  const handleGetItem = (VINNumber: string) => {
    if ("VINNumber" in errors) return;
    if (VINNumber)
      getData(`/cars/${uid}/${VINNumber}`).then((r: any) => {
        if (r?.make) setValue("make", r?.make);
        if (r?.mileage) setValue("mileage", r?.mileage);
        if (r?.model) setValue("model", r?.model);
        if (r?.priceOnWebsite) setValue("priceOnWebsite", r?.priceOnWebsite);
        if (r?.year) setValue("year", r?.year);
      });
  };

  const renderTitle = "Create Lead";

  const renderTestDrive = (
    <>
      <Box display={"flex"} p={1} justifyContent={"space-between"}>
        <Stack>
          <Typography variant="body2" fontWeight={600}>
            Has he/she already did test drive on this car? (Yes No)
          </Typography>
        </Stack>
      </Box>
      <Box display={"flex"} justifyContent={"start"} gap={1}>
        <FormControlLabel
          sx={{ flexDirection: "row" }}
          value={true}
          control={<Checkbox checked={lead.testDrive != false ? true : false} />}
          label="Yes"
          labelPlacement="bottom"
          checked={lead.testDrive}
          onClick={() => {
            setLead((prev) => ({
              ...prev,
              testDrive: true,
            }));
          }}
        />
        <FormControlLabel
          sx={{ flexDirection: "row" }}
          control={<Checkbox checked={lead.testDrive === false ? true : false} />}
          label="No"
          labelPlacement="bottom"
          onClick={() => {
            setLead((prev) => ({
              ...prev,
              testDrive: false,
            }));
          }}
        />
      </Box>
    </>
  );

  const renderContent = (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps?.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && (
        <Grid container p={1}>
          <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="firstName"
              render={({ field }) => (
                <ControlledInput
                  label={"First Name*"}
                  error={!!errors.firstName}
                  id="firstName"
                  placeholder="Enter your first name"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="lastName"
              render={({ field }) => (
                <ControlledInput
                  label={"Last Name*"}
                  error={!!errors.lastName}
                  id="lastName"
                  placeholder="Enter your last name"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <ControlledInput
                  error={!!errors.email}
                  id="email"
                  label="Email"
                  placeholder="Enter your email"
                  type="email"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field }) => (
                <MuiPhone
                  label={"Phone Number*"}
                  error={!!errors.phoneNumber}
                  id="phoneNumber"
                  placeholder="Enter your phone number"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
      {activeStep === 1 && (
        <Grid container p={1}>
          <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="VINNumber"
              render={({ field }) => {
                useEffect(() => {
                  handleGetItem(field.value);
                }, [field.value]);
                return (
                  <ControlledInput
                    label={"VIN Number*"}
                    error={!!errors.VINNumber}
                    id="VINNumber"
                    placeholder="Enter your VIN Number"
                    type="text"
                    {...field}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="year"
              render={({ field }) => (
                <ControlledInput
                  error={!!errors.year}
                  label="Year*"
                  id="year"
                  placeholder="Enter your year"
                  type="number"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="make"
              render={({ field }) => (
                <ControlledInput
                  error={!!errors.make}
                  label="Make*"
                  id="make"
                  placeholder="Enter your make"
                  type="text"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="model"
              render={({ field }) => (
                <ControlledInput
                  label="Model*"
                  error={!!errors.model}
                  id="model"
                  placeholder="Enter your model"
                  type="text"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="mileage"
              render={({ field }) => (
                <ControlledInput
                  label="Mileage*"
                  error={!!errors.mileage}
                  id="mileage"
                  placeholder="Enter your mileage"
                  type="number"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="priceOnWebsite"
              render={({ field }) => (
                <ControlledInput
                  label="Price On Website*"
                  error={!!errors.priceOnWebsite}
                  id="priceOnWebsite"
                  placeholder="Enter your priceOnWebsite"
                  type="number"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
      {activeStep === 2 && (
        <>
          <Box p={1} minWidth={{ xs: "auto", sm: 400 }} m={1}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: 1,
              }}
            >
              <Controller
                control={control}
                name="description"
                render={({ field }) => (
                  <Textarea
                    rows={8}
                    label="Tell A.I. about this lead*"
                    error={!!errors.description}
                    id="description"
                    placeholder="Enter your description"
                    type="text"
                    {...field}
                  />
                )}
              />
            </Box>
            {renderTestDrive}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                p: 1,
              }}
            >
              <Controller
                control={control}
                name="manualLeadFirstMessage"
                render={({ field }) => (
                  <Textarea
                    label="First message (optional)"
                    rows={8}
                    error={!!errors.manualLeadFirstMessage}
                    id="manualLeadFirstMessage"
                    placeholder="Enter your first message"
                    type="text"
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );

  const renderActions = (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>

        <Button
          disabled={activeStep === 2 ? !isValid : false}
          onClick={handleNext}
          variant="contained"
        >
          {activeStep === steps.length - 1 ? "Create" : "Next"}
        </Button>
      </Box>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        handleClose={() => setMessage({ text: "", open: false, type: "" })}
        message={message.text}
        open={message.open}
        type={message.type}
      />
      <Dialog
        onSubmit={handleSubmit(onSubmit)}
        open={open}
        handleClose={handleCloseDialog}
        renderTitle={renderTitle}
        renderContent={renderContent}
        renderActions={renderActions}
      />
    </>
  );
}
