import { Route, Routes } from "react-router-dom";
import Login from "../pages/login";
import PrivateRoute from "../utils/private-router";
import React from "react";
import PageLoading from "../components/pageLoading/pageLoading";
import NotFound from "../components/not-found";
import Appointment from "../pages/appointment";
import Report from "../pages/report";
import DashboardLayout from "../layout/dashboard-layout";
import Setting from "../pages/setting";
import Cars from "../pages/cars";
import TrainingRequest from "../pages/training-request";
import Dealership from "../sections/setting/dealership";
import BusinessHours from "../sections/setting/business-hours";
import Lead from "../sections/setting/lead";
import Holidays from "../sections/setting/holidays";
import Inbox from "../sections/dashboard/inbox";

export default function Router() {
  return (
    <Routes>
      <Route path="" element={<PrivateRoute />}>
        <Route
          path=""
          element={
            <React.Suspense fallback={<PageLoading />}>
              <DashboardLayout>
                <Inbox />
              </DashboardLayout>
            </React.Suspense>
          }
        />
        <Route
          path=":id"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <DashboardLayout>
                <Inbox />
              </DashboardLayout>
            </React.Suspense>
          }
        />
        <Route
          path="appointment"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <DashboardLayout>
                <Appointment />
              </DashboardLayout>
            </React.Suspense>
          }
        />
        <Route
          path="report"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <DashboardLayout>
                <Report />
              </DashboardLayout>
            </React.Suspense>
          }
        />
        <Route
          path="setting"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <DashboardLayout>
                <Setting />
              </DashboardLayout>
            </React.Suspense>
          }
        >
          <Route path="dealership" element={<Dealership />} />
          <Route path="business-hours" element={<BusinessHours />} />
          <Route path="lead" element={<Lead />} />
          <Route path="holidays" element={<Holidays />} />
        </Route>
        <Route
          path="training-request"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <DashboardLayout>
                <TrainingRequest />
              </DashboardLayout>
            </React.Suspense>
          }
        />
        <Route
          path="cars"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <DashboardLayout>
                <Cars />
              </DashboardLayout>
            </React.Suspense>
          }
        />
      </Route>
      <Route
        path="login"
        element={
          <React.Suspense fallback={<PageLoading />}>
            <Login />
          </React.Suspense>
        }
      />
      {/* Place the catch-all route at the end */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
