import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { memo } from "react";

import { Backdrop, Box, useTheme } from "@mui/material";
import IconButton from "../icon-button/icon-button";

function Viewer({ children, onClose, open }: any) {
  const { zIndex } = useTheme();

  return (
    <Backdrop
      sx={{
        backgroundColor: "rgba(0,0,0,0.8)",
        color: "white",
        overflow: "auto",
        zIndex: zIndex.tooltip + 1,
      }}
      open={open}
    >
      {children}
      <Box
        sx={{
          right: 0,
          position: "fixed",
          top: 0,
          zIndex: zIndex.tooltip + 1,
        }}
      >
        <IconButton
          icon={<CloseRoundedIcon fontSize="large" color="info" />}
          onClick={onClose}
          placement="top"
        />
      </Box>
    </Backdrop>
  );
}

export default memo(Viewer);
