import React, { useEffect, useState } from "react";
import ControlledBox from "../../components/box/controlled-box";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Button from "../../components/button/button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "../../components/dialog/dialog";
import IconButton from "../../components/icon-button/icon-button";
import Selector from "../../components/select/select";
import dayjs, { Dayjs } from "dayjs";
import { convertDate, convertTo24Hour, generateClock, groupBy } from "../../utils/utils";
import DateRangePicker from "../../components/date-range-picker/date-range-picker";
import axios from "axios";
import { useSelector } from "react-redux";
import { getToken, getUid } from "../../redux/authSlice";
import { getData } from "../../utils/firebase";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Snackbar from "../../components/snackbar/snackbar";

type HolidaysProps = {
  day: "";
  fullDate: "";
  start: "8:00";
  end: "5:00";
  morning: "am";
  midnight: "pm";
  status: string; //appointment_only, closed, custom
};

const handleChangeFormat = (array: any[]) => {
  // Use map to create a new array with an added property
  const updatedArrays = array.map(
    ({ start, end, morning, midnight, fullDate, status, ...rest }: any) => {
      const dateString = fullDate;
      const date = new Date(dateString);
      // Format the date to your desired format
      const options: any = { year: "numeric", month: "2-digit", day: "2-digit" };
      const formattedDate = date.toLocaleDateString("en-US", options);
      return {
        ...rest, // Spread the existing properties
        time_range: `${start}${morning.toUpperCase()}-${end}${midnight.toUpperCase()}`, // Optional (if not closed)
        appointment_only: status === "appointment_only",
        closed: status === "closed",
        date: formattedDate,
      };
    }
  );
  return updatedArrays;
};

export default function Holidays() {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [selectedDays, setSelectedDays] = React.useState<Dayjs[]>([]);
  const [monthDaysList, setMonthDaysList] = React.useState<any[]>([]);
  const token = useSelector(getToken);
  const uid = useSelector(getUid);
  const [holidays, setHolidays]: HolidaysProps | any = useState([]);
  const [data, setData]: any = useState([]);
  const [notify, setNotify] = useState({ message: "", open: false, type: "" });

  const toggleDialog = () => {
    setOpen(!open);
  };

  const formatSelectedDays = () => {
    const updatesItems: any = [];
    selectedDays.map((day: { format: (arg0: string) => any }) => {
      const data = {
        day: day.format("dddd"),
        fullDate: day.format("MMMM D, YYYY"),
        start: "8:00",
        end: "5:00",
        morning: "am",
        midnight: "pm",
      };
      updatesItems.push(data);
    });
    setHolidays(updatesItems);
  };

  useEffect(() => {
    formatSelectedDays();
  }, [selectedDays]);

  const sendCustomDays = async () => {
    // Create an Axios instance with a base URL
    const apiClient = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL, // Replace with your base URL
      headers: {
        Authorization: ` ${token}`, // Replace with your actual token
        "Content-Type": "application/json",
      },
    });
    const value = [
      {
        section: "time_operation",
        data: {
          special_days: handleChangeFormat(holidays),
        },
      },
    ];
    try {
      const { data }: any = await apiClient.post("", value);
      setNotify({ message: data.message, open: true, type: "" });
      getSpecialDaysData();
      setIndex(0);
      setSelectedDays([]);
    } catch (error: any) {
      console.log(error);
      setNotify({ message: error.response?.data?.error, open: true, type: "error" });
    }
  };

  const deleteCustomDay = async (key: any) => {
    // Create an Axios instance with a base URL
    const apiClient = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    // Prepare the delete payload
    const deleteValue = [
      {
        section: "time_operation",
        data: {
          special_days: [{ id: key }], // Directly use key
        },
      },
    ];

    try {
      const response = await apiClient.delete("", { data: deleteValue });
      getSpecialDaysData();
      console.log("Delete successful:", response.data);
    } catch (error: any) {
      console.error("Delete failed:", error.response?.data || error.message);
    }
  };

  const getSpecialDaysData = async () => {
    const monthDaysMap: { [key: string]: { days: number[]; monthNumber: number } } = {};
    getData(`dealers/${uid}/info/special_days`).then((r: any) => {
      if (!r) return;
      setData(r);
      Object.values(r).forEach((item: any) => {
        const dateString = item.date;
        const date = dayjs(dateString, "MM/DD/YYYY"); // Parse the date string
        const monthName = date.format("MMMM"); // Get the full month name
        const monthNumber = date.month() + 1; // Get the month number (1-12)
        const dayOfMonth = date.date(); // Get the day of the month

        // Initialize the array for the month if it doesn't exist
        if (!monthDaysMap[monthName]) {
          monthDaysMap[monthName] = { days: [], monthNumber };
        }
        // Add the day to the corresponding month
        monthDaysMap[monthName].days.push(dayOfMonth);
      });
      // Convert the object to an array of month-day pairs
      const monthDaysList = Object.entries(monthDaysMap).map(([month, { days, monthNumber }]) => ({
        month,
        monthNumber,
        days,
      }));
      setMonthDaysList(monthDaysList);
    });
  };

  useEffect(() => {
    getSpecialDaysData();
  }, []);

  const renderContent =
    index === 0 ? (
      <DateRangePicker
        selectedDays={selectedDays}
        setSelectedDays={setSelectedDays}
        monthDaysList={monthDaysList}
        initialValue={dayjs().format("YYYY-MM-DD")}
      />
    ) : (
      <Box display={"flex"} flexDirection={"column"}>
        {holidays.map((h: HolidaysProps, i: number) => (
          <Box width={"100%"} display={"flex"} gap={1} key={i} mb={1}>
            <Box
              display={"grid"}
              sx={{
                border: "1px solid #E3E3E3",
                p: 1,
                borderRadius: 2,
                width: 150,
              }}
            >
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography fontWeight={700}>{h.day}</Typography>
                <IconButton sx={{ p: 0 }} icon={<CloseIcon />} />
              </Box>
              <Typography fontWeight={400}>{h.fullDate}</Typography>
              <Typography
                display={"flex"}
                variant="body2"
                color={"#7D7F80"}
                bgcolor={"#E6E6E6"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={40}
              >
                {convertTo24Hour(h.start, h.morning)} - {convertTo24Hour(h.end, h.midnight)}
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="closed"
                  control={<Radio />}
                  checked={h.status === "closed"}
                  name="closed"
                  label="Dealership will be closed on the selected dates"
                  onChange={() => {
                    setHolidays((prevItems: any) => {
                      const updatedItems = [...prevItems];
                      updatedItems[i] = { ...updatedItems[i], status: "closed" }; // Update the specific item
                      return updatedItems;
                    });
                  }}
                />
                <FormControlLabel
                  value="appointment_only"
                  control={<Radio />}
                  checked={h.status === "appointment_only"}
                  name="appointment_only"
                  label="i want to appointment only"
                  onChange={() => {
                    setHolidays((prevItems: any) => {
                      const updatedItems = [...prevItems];
                      updatedItems[i] = { ...updatedItems[i], status: "appointment_only" }; // Update the specific item
                      return updatedItems;
                    });
                  }}
                />

                <FormControlLabel
                  value="custom"
                  checked={h.status === "custom"}
                  control={<Radio />}
                  label="i want to change hours."
                  onChange={() => {
                    setHolidays((prevItems: any) => {
                      const updatedItems = [...prevItems];
                      updatedItems[i] = { ...updatedItems[i], status: "custom" }; // Update the specific item
                      return updatedItems;
                    });
                  }}
                />
              </RadioGroup>
            </Box>
            <Box display={"flex"}>
              <Box ml={1}>
                <Typography>From</Typography>
                <Box display={"flex"} gap={1}>
                  <Selector
                    data={generateClock()}
                    disabled={h.status === "closed"}
                    width={85}
                    value={h.start}
                    onChange={(e: SelectChangeEvent) => {
                      setHolidays((prevItems: any) => {
                        const updatedItems = [...prevItems];
                        updatedItems[i] = { ...updatedItems[i], start: e.target.value }; // Update the specific item
                        return updatedItems;
                      });
                    }}
                  />
                  <Selector
                    data={["am", "pm"]}
                    width={85}
                    value={h.morning}
                    disabled={h.status === "closed"}
                    onChange={(e: SelectChangeEvent) => {
                      setHolidays((prevItems: any) => {
                        const updatedItems = [...prevItems];
                        updatedItems[i] = { ...updatedItems[i], morning: e.target.value }; // Update the specific item
                        return updatedItems;
                      });
                    }}
                  />
                </Box>
              </Box>
              <Box ml={2}>
                <Typography>To</Typography>
                <Box display={"flex"} gap={1}>
                  <Selector
                    data={generateClock()}
                    width={85}
                    value={h.end}
                    disabled={h.status === "closed"}
                    onChange={(e: SelectChangeEvent) => {
                      setHolidays((prevItems: any) => {
                        const updatedItems = [...prevItems];
                        updatedItems[i] = { ...updatedItems[i], end: e.target.value }; // Update the specific item
                        return updatedItems;
                      });
                    }}
                  />
                  <Selector
                    data={["am", "pm"]}
                    width={85}
                    value={h.midnight}
                    disabled={h.status === "closed"}
                    onChange={(e: SelectChangeEvent) => {
                      setHolidays((prevItems: any) => {
                        const updatedItems = [...prevItems];
                        updatedItems[i] = { ...updatedItems[i], midnight: e.target.value }; // Update the specific item
                        return updatedItems;
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );

  return (
    <>
      <Box display={"block"}>
        <Typography variant="h6">Add Holidays</Typography>
        <Typography color={"#9B9B9B"} variant="body1">
          Specify dates you are closed due to holidays or other reasons
        </Typography>
      </Box>
      <ControlledBox sx={{ justifyContent: "center" }} display={"flex"}>
        <Button variant="outlined" color="primary" onClick={toggleDialog}>
          Add custom time and date
        </Button>
      </ControlledBox>
      <ControlledBox sx={{ justifyContent: "center" }} display={"flex"} flexWrap="wrap">
        {data &&
          Object.entries(groupBy(data)).map(([key, value]: any) => {
            return (
              <Box key={key} width={"100%"}>
                <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                  <Typography variant="h6">{convertDate(key)}</Typography>
                </Box>
                <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                  {value?.closed && (
                    <Box>
                      <Box display={"flex"} alignItems={"center"} gap={1}>
                        <Typography variant="body1">Closed</Typography>
                        <Box border={"1px solid #E5E5E5"} width={"80%"} height={0} />
                      </Box>
                      <Box display={"flex"} flexWrap={"wrap"}>
                        {value?.closed?.map((a: any, item: any) => (
                          <Box
                            key={item}
                            p={1}
                            m={1}
                            borderRadius={2}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDirection={"column"}
                            gap={1}
                            border={"1px solid #E3E3E3"}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              width={"100%"}
                            >
                              <Typography variant="body2" fontWeight={700}>
                                {a.day}
                              </Typography>
                              <IconButton
                                icon={<CloseRoundedIcon />}
                                onClick={() => deleteCustomDay(a.id)}
                              />
                            </Box>
                            <Box justifyContent={"start"} width={"100%"}>
                              <Typography variant="body2" fontWeight={400}>
                                {a.date}
                              </Typography>
                              <Typography
                                bgcolor={"#FFE9E9"}
                                color={"#FF0004"}
                                variant="body2"
                                fontWeight={400}
                                px={2}
                                borderRadius={"40px"}
                              >
                                Closed
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                  {value?.appointment_only && (
                    <Box>
                      <Box display={"flex"} alignItems={"center"} gap={1}>
                        <Typography variant="body1">Appointment only</Typography>
                        <Box border={"1px solid #E5E5E5"} width={"80%"} height={0} />
                      </Box>
                      <Box display={"flex"} flexWrap={"wrap"}>
                        {value?.appointment_only?.map((a: any, item: any) => (
                          <Box
                            key={item}
                            p={1}
                            m={1}
                            borderRadius={2}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDirection={"column"}
                            gap={1}
                            border={"1px solid #E3E3E3"}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              width={"100%"}
                            >
                              <Typography variant="body2" fontWeight={700}>
                                {a.day}
                              </Typography>
                              <IconButton
                                icon={<CloseRoundedIcon />}
                                onClick={() => deleteCustomDay(a.id)}
                              />
                            </Box>
                            <Box justifyContent={"start"} width={"100%"}>
                              <Typography variant="body2" fontWeight={400}>
                                {a.date}
                              </Typography>
                              <Typography
                                px={2}
                                bgcolor={"#CDECEC"}
                                variant="body2"
                                color={"#2EA071"}
                                fontWeight={400}
                                borderRadius={"40px"}
                              >
                                Appt Only
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                  {value?.other && (
                    <Box>
                      <Box display={"flex"} alignItems={"center"} gap={1}>
                        <Typography variant="body1">Hours changed</Typography>
                        <Box border={"1px solid #E5E5E5"} width={"80%"} height={0} />
                      </Box>
                      <Box display={"flex"} flexWrap={"wrap"}>
                        {value?.other?.map((a: any, item: any) => (
                          <Box
                            key={item}
                            p={1}
                            m={1}
                            borderRadius={2}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDirection={"column"}
                            gap={1}
                            border={"1px solid #E3E3E3"}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              width={"100%"}
                            >
                              <Typography variant="body2" fontWeight={700}>
                                {a.day}
                              </Typography>
                              <IconButton
                                icon={<CloseRoundedIcon />}
                                onClick={() => deleteCustomDay(a.id)}
                              />
                            </Box>
                            <Box justifyContent={"start"} width={"100%"}>
                              <Typography variant="body2" fontWeight={400}>
                                {a.date}
                              </Typography>
                              <Typography
                                variant="body2"
                                fontWeight={400}
                                px={2}
                                bgcolor={"#E5E5E5"}
                                color={"#7B7B7B"}
                                borderRadius={"40px"}
                              >
                                {a.time_range}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box border={"1px solid #E3E3E3"} m={1} borderRadius={1}></Box>
              </Box>
            );
          })}
      </ControlledBox>
      <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
        <Button variant="outlined" color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={sendCustomDays}>
          Save Change
        </Button>
      </Box>
      <Dialog
        maxWidth={"lg"}
        open={open}
        handleClose={toggleDialog}
        fullWidth
        renderContent={
          <Box mt={2} width={"100%"}>
            {renderContent}
          </Box>
        }
        renderTitle={
          <Box display={"block"}>
            <Typography variant="h6" fontWeight={700}>
              {index === 0 ? "1. Choose Dates" : "2. Choose Options"}
            </Typography>
            <Typography variant="body1" color={"#686868"} fontWeight={500}>
              {index === 0
                ? " You can choose one or more different days."
                : "You can apply the desired options for your selected days."}
            </Typography>
          </Box>
        }
        renderActions={
          <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
            <Button variant="outlined" color="primary" onClick={() => setIndex(0)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setIndex(1);
                if (index === 1) {
                  sendCustomDays();
                  toggleDialog();
                }
              }}
            >
              {index === 1 ? "Save" : "Next"}
            </Button>
          </Box>
        }
      />
      <Snackbar
        handleClose={() => setNotify({ message: "", open: false, type: "" })}
        message={notify.message}
        open={notify.open}
        type={notify.type}
      />
    </>
  );
}
