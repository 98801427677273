import React from "react";
import { Typography, Box } from "@mui/material";
import Dialog from "../../components/dialog/dialog";
import { ref, update } from "firebase/database";
import { firebaseDb } from "../../firebase/app";
import { useSelector } from "react-redux";
import { getUid } from "../../redux/authSlice";
import Button from "../../components/button/button";

export default function FinanceApplicableDialog({
  open,
  handleClose,
  vin,
  setData,
  financeable,
}: any) {
  const uid = useSelector(getUid);

  const handleSold = () => {
    const currentUserRef = ref(firebaseDb, `cars/${uid}/${vin}`);
    update(currentUserRef, { financeApplicable: !financeable })
      .then(() => {
        setData((prevCars: any[]) =>
          prevCars.map((car) =>
            car.vin === vin ? { ...car, financeApplicable: !financeable } : car
          )
        );
      })
      .catch((e) => console.log(e));
    handleClose();
  };

  const renderTitle = "Financing Status";

  const renderContent = (
    <Typography variant="body2" fontWeight={700}>
      Are you sure you want to change finance status?
    </Typography>
  );

  const renderActions = (
    <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
      <Button variant="contained" color="primary" onClick={handleSold} sx={{ width: "100%" }}>
        Confirm
      </Button>
    </Box>
  );

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      renderActions={renderActions}
      renderContent={renderContent}
      renderTitle={renderTitle}
    />
  );
}
