import { Fab as MuiFab } from "@mui/material";
import React from "react";

export default function Fab({ icon, ...props }: any) {
  return (
    <MuiFab sx={{ borderRadius: "16px", width: 48, height: 48, boxShadow: "none" }} {...props}>
      {icon}
    </MuiFab>
  );
}
