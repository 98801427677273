import { createTheme } from "@mui/material/styles";

// A custom theme for this app
export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      contrastText: "#EFF2FE",
      dark: "#0572d1", // hover
      light: "#E7EDF2",
      main: "#07A4FC",
    },
    info: {
      main: "#F2F6FC",
      light: "#F4F4F6",
      dark: "#07c8fc",
    },
    secondary: {
      main: "#E6F6FF",
      light: "#CEEBF8",
      dark: "#353535", // hover
      contrastText: "#F2F2F2",
    },
    background: {
      default: "#F5F5F5",
      paper: "#fff",
    },
    common: {
      white: "#F1F7FB",
      black: "#FAFAFA",
    },
    text: {
      primary: "#000",
      secondary: "#0000003b",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Apply textTransform: none to all buttons
        },
      },
    },
  },
});

// Define the custom dark theme
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      contrastText: "#151515",
      main: "#07A4FC",
      dark: "#0572d1", // hover
      light: "#008CDB",
    },
    secondary: {
      main: "#404659",
      light: "#4D5EA4",
      dark: "#0E0E0E",
      contrastText: "#2C3349",
    },
    info: {
      dark: "#07c8fc",
      main: "#0C1118",
      light: "#1F2021",
    },
    text: {
      primary: "#ffffff",
      secondary: "#2e50b8",
    },
    common: {
      white: "#282A31",
      black: "#202431",
    },
    background: {
      default: "#18171D",
      paper: "#222029",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    h1: {
      fontSize: "4rem",
      fontWeight: 700,
      letterSpacing: "-0.01562em",
      color: "#ffffff", // White heading 1 color
    },
    h2: {
      fontSize: "3rem",
      fontWeight: 700,
      letterSpacing: "-0.00833em",
      color: "#c2c2c2", // Light gray heading 2 color
    },
    h3: {
      fontSize: "2.25rem",
      fontWeight: 700,
      letterSpacing: "0",
      color: "#e0e0e0", // Slightly lighter gray heading 3 color
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: "0.00735em",
      color: "#ffffff", // White heading 4 color
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 600,
      letterSpacing: "0.00714em",
      color: "#ffffff", // White heading 5 color
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
      letterSpacing: "0.0075em",
      color: "#ffffff", // White heading 6 color
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
      letterSpacing: "0.00938em",
      color: "#b3b3b3", // Light gray subtitle 1 color
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      letterSpacing: "0.00714em",
      color: "#a0a0a0", // Gray subtitle 2 color
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      letterSpacing: "0.00938em",
      color: "#ffffff", // Light gray body 1 color
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      letterSpacing: "0.01071em",
      color: "#ffffff", // White heading 1 color
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      letterSpacing: "0.03333em",
      color: "#a0a0a0", // Gray caption color
    },
    overline: {
      fontSize: "0.625rem",
      fontWeight: 400,
      letterSpacing: "0.16667em",
      color: "#ffffff", // White overline color
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Apply textTransform: none to all buttons
        },
      },
    },
  },
});
