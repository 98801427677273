import { SvgIcon } from "@mui/material";
import React from "react";

export default function ErrorUploadIcon({ ...props }) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      sx={{ width: 60, height: 60 }}
      {...props}
    >
      <g clipPath="url(#clip0_3414_36341)">
        <path
          d="M34.3138 23.1774C33.3672 21.5161 30.6312 21.5161 29.6844 23.1774L8.35105 60.5107C7.87905 61.3347 7.88168 62.3481 8.35905 63.1721C8.83643 63.9961 9.71642 64.5001 10.6657 64.5001H53.3323C54.2817 64.5001 55.1617 63.9961 55.6389 63.1721C56.1163 62.3481 56.1189 61.3347 55.6469 60.5107L34.3138 23.1774ZM31.9991 56.5001C30.5271 56.5001 29.3324 55.3054 29.3324 53.8334C29.3324 52.3614 30.5271 51.1668 31.9991 51.1668C33.4711 51.1668 34.6657 52.3614 34.6657 53.8334C34.6657 55.3054 33.4711 56.5001 31.9991 56.5001ZM34.6658 45.8334C34.6658 47.3081 33.4738 48.5001 31.9992 48.5001C30.5246 48.5001 29.3326 47.3081 29.3326 45.8334V40.5001C29.3326 39.0254 30.5246 37.8334 31.9992 37.8334C33.4738 37.8334 34.6658 39.0254 34.6658 40.5001V45.8334Z"
          fill="#F87171"
        />
        <path
          d="M55.848 19.7642C54.6294 10.5909 46.2294 0.500244 36.128 0.500244C30.712 0.500244 25.2054 3.16162 21.528 7.43087C20.352 7.00149 19.1066 6.78024 17.8374 6.78024C12.0908 6.78024 7.38138 11.3082 7.09338 16.9856C2.848 19.7216 0 24.7749 0 29.8336C0 33.0016 1.05063 36.5162 2.88263 39.4736C4.67463 42.3696 7.576 44.4496 10.8772 45.343L25.0532 20.5324C26.4373 18.1057 29.0986 16.5964 31.9999 16.5964C34.9012 16.5964 37.5625 18.1057 38.9465 20.535L53.2106 45.4976C58.8266 44.295 63.304 39.735 63.912 34.103C63.9706 33.5776 64 33.0416 64 32.5004C64 26.3776 60.864 21.5856 55.848 19.7642Z"
          fill="#F87171"
        />
      </g>
      <defs>
        <clipPath id="clip0_3414_36341">
          <rect width="64" height="64" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
