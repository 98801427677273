import { SvgIcon } from "@mui/material";
import React from "react";

export default function PdfFileIcon({ ...props }: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      sx={{ width: 50, height: 50 }}
      {...props}
    >
      <rect width="56" height="56" transform="translate(0.307617 -0.000976562)" fill="#EAF7FE" />
      <path
        d="M50.4013 11.5664H44.0576C43.0863 11.5664 42.2988 10.7789 42.2988 9.80766V3.69141C42.5263 3.79641 42.7363 3.93641 42.9201 4.11141L49.9551 10.9364C50.1388 11.1202 50.2963 11.3302 50.4013 11.5664Z"
        fill="#0285CF"
      />
      <path
        d="M50.4009 13.3166H44.0571C42.1234 13.3166 40.5484 11.7416 40.5484 9.80781V3.69156C40.5484 3.63031 40.5659 3.56906 40.5746 3.50781H16.0309C14.9109 3.50781 13.9834 4.42656 13.9834 5.55531V24.2891H36.6021C39.3234 24.2891 41.5371 26.5028 41.5371 29.2241V40.6078C41.5371 43.3291 39.3234 45.5428 36.6021 45.5428H13.9834V50.4428C13.9834 51.5628 14.9021 52.4903 16.0309 52.4903H48.5634C49.6834 52.4903 50.6109 51.5716 50.6109 50.4428V13.2903C50.5409 13.2903 50.4796 13.3166 50.4096 13.3166H50.4009ZM24.0809 12.9928H20.1696C19.6884 12.9928 19.2946 12.5991 19.2946 12.1178C19.2946 11.6366 19.6884 11.2428 20.1696 11.2428H24.0809C24.5621 11.2428 24.9559 11.6366 24.9559 12.1178C24.9559 12.5991 24.5621 12.9928 24.0809 12.9928ZM30.0046 9.23906H20.1784C19.6971 9.23906 19.3034 8.84531 19.3034 8.36406C19.3034 7.88281 19.6971 7.48906 20.1784 7.48906H30.0046C30.4859 7.48906 30.8796 7.88281 30.8796 8.36406C30.8796 8.84531 30.4859 9.23906 30.0046 9.23906Z"
        fill="#0285CF"
      />
      <path
        d="M22.7168 31.3416H22.183C22.113 31.3416 22.0605 31.3941 22.0605 31.4641V38.3678C22.0605 38.4378 22.113 38.4903 22.183 38.4903H22.9093C23.9855 38.4903 24.9918 38.0178 25.6743 37.1866C26.3655 36.3466 26.628 35.2791 26.418 34.1853C26.0943 32.5403 24.5368 31.3416 22.7168 31.3416Z"
        fill="#0285CF"
      />
      <path
        d="M15.3488 31.3503H13.6426V35.7953H15.3488C16.5738 35.7953 17.5713 34.7978 17.5713 33.5728C17.5713 32.3478 16.5738 31.3503 15.3488 31.3503Z"
        fill="#0285CF"
      />
      <path
        d="M36.6027 26.0391H9.20648C7.44773 26.0391 6.02148 27.4653 6.02148 29.2241V40.6078C6.02148 42.3666 7.44773 43.7928 9.20648 43.7928H36.6027C38.3615 43.7928 39.7877 42.3666 39.7877 40.6078V29.2241C39.7877 27.4653 38.3615 26.0391 36.6027 26.0391ZM15.349 37.5453H13.6427V39.3653C13.6427 39.8466 13.249 40.2403 12.7677 40.2403C12.2865 40.2403 11.8927 39.8466 11.8927 39.3653V30.4753C11.8927 29.9941 12.2865 29.6003 12.7677 29.6003H15.349C17.5365 29.6003 19.3215 31.3853 19.3215 33.5728C19.3215 35.7603 17.5365 37.5453 15.349 37.5453ZM27.0215 38.3066C26.0065 39.5403 24.5015 40.2491 22.909 40.2491H22.1827C21.1502 40.2491 20.3102 39.4091 20.3102 38.3766V31.4728C20.3102 30.4403 21.1502 29.6003 22.1827 29.6003H22.7165C25.3677 29.6003 27.6515 31.3941 28.1327 33.8616C28.4477 35.4541 28.0365 37.0816 27.0215 38.3153V38.3066ZM34.1877 34.0453C34.669 34.0453 35.0627 34.4391 35.0627 34.9203C35.0627 35.4016 34.669 35.7953 34.1877 35.7953H31.134V39.3653C31.134 39.8466 30.7402 40.2403 30.259 40.2403C29.7777 40.2403 29.384 39.8466 29.384 39.3653V30.4753C29.384 29.9941 29.7777 29.6003 30.259 29.6003H34.1877C34.669 29.6003 35.0627 29.9941 35.0627 30.4753C35.0627 30.9566 34.669 31.3503 34.1877 31.3503H31.134V34.0453H34.1877Z"
        fill="#0285CF"
      />
    </SvgIcon>
  );
}
