import { SvgIcon } from "@mui/material";
import React from "react";

export default function PriceIcon() {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
    >
      <path
        d="M17.1035 6.90424L10.7794 0.73341C10.2302 0.18341 9.44021 -0.085757 8.67271 0.024243L2.93271 0.809243C2.47688 0.871743 2.15771 1.29174 2.22021 1.74758C2.28271 2.20341 2.70188 2.52174 3.15938 2.46008L8.90021 1.67508C9.15521 1.64008 9.41855 1.72758 9.60938 1.91841L15.9344 8.08924C16.8052 8.96008 16.8902 10.3226 16.2035 11.2951C16.0085 10.9059 15.7677 10.5351 15.4444 10.2117L9.21521 4.03508C8.66688 3.48674 7.88438 3.22258 7.10855 3.32591L1.36771 4.11091C0.98938 4.16258 0.69438 4.46508 0.652713 4.84508L0.0152131 10.6084C-0.0681202 11.3676 0.193546 12.1117 0.735213 12.6526L6.91521 18.7801C7.70188 19.5676 8.74771 20.0001 9.86021 20.0001H9.87438C10.9927 19.9967 12.041 19.5567 12.8269 18.7609L15.466 16.0876C16.2385 15.3051 16.6269 14.2884 16.6519 13.2642L17.1327 12.7842C18.7369 11.1592 18.7277 8.52424 17.106 6.90341L17.1035 6.90424ZM4.16521 8.33424C3.70521 8.33424 3.33188 7.96091 3.33188 7.50091C3.33688 6.41091 4.99355 6.41174 4.99855 7.50091C4.99855 7.96091 4.62521 8.33424 4.16521 8.33424Z"
        fill="#07A4FC"
      />
    </SvgIcon>
  );
}
