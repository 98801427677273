import { SvgIcon } from "@mui/material";
import React from "react";

export default function ReportIssueIcon({ ...props }) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
      {...props}
    >
      <svg>
        <path
          d="M21.5908 0.5H8.05047C4.00094 0.5 0.707031 3.79438 0.707031 7.84344V23.7964C0.707031 24.35 1.31547 24.6819 1.78047 24.3941L5.37719 22.1647C5.79109 21.9078 6.26734 21.7723 6.75437 21.7723H14.9514C19 21.7723 22.2939 18.4784 22.2939 14.4294V1.20312C22.2939 0.815 21.9794 0.5 21.5908 0.5ZM11.5005 17.0052C11.1119 17.0052 10.7973 16.6902 10.7973 16.302C10.7973 15.9139 11.1119 15.5989 11.5005 15.5989C11.8886 15.5989 12.2036 15.9139 12.2036 16.302C12.2036 16.6902 11.8886 17.0052 11.5005 17.0052ZM12.2036 13.4642C12.2036 13.8523 11.8886 14.1673 11.5005 14.1673C11.1119 14.1673 10.7973 13.8523 10.7973 13.4642V5.27422C10.7973 4.88609 11.1119 4.57109 11.5005 4.57109C11.8886 4.57109 12.2036 4.88609 12.2036 5.27422V13.4642Z"
          fill="#07A4FC"
        />
      </svg>
    </SvgIcon>
  );
}
