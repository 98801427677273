import { Box, Typography } from "@mui/material";
import React from "react";

export default function Summary({ content }: any) {
  return (
    <Box boxShadow={"0px 0px 4px 0px rgba(0, 0, 0, 0.12)"} borderRadius={3} p={1}>
      <Typography variant="body2" fontWeight={400}>
        {content}
      </Typography>
    </Box>
  );
}
