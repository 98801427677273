import { SvgIcon } from "@mui/material";
import React from "react";

export default function UploadFileIcon({ ...props }) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M21.6 5.60186V4.71429C21.5987 3.53141 20.704 2.57285 19.6 2.57143H12.8V2.14286C12.7987 0.959977 11.904 0.00141691 10.8 0H2C0.895979 0.00141691 0.00132245 0.959977 0 2.14286V21C0.00176346 22.6561 1.25433 23.9981 2.8 24H21.2C22.7457 23.9981 23.9982 22.6561 24 21V8.57143C23.9983 7.08076 22.9768 5.81681 21.6 5.60186ZM23.2 21C23.1987 22.1829 22.304 23.1414 21.2 23.1429H2.8C1.69598 23.1414 0.801322 22.1829 0.8 21V2.14286C0.8 1.43278 1.33726 0.857143 2 0.857143H10.8C11.4627 0.857143 12 1.43278 12 2.14286V3C12 3.23669 12.1791 3.42857 12.4 3.42857H19.6C20.2627 3.42857 20.8 4.00421 20.8 4.71429V5.57143H9.6C9.37909 5.57143 9.2 5.76331 9.2 6C9.2 6.23669 9.37909 6.42857 9.6 6.42857H21.2C22.304 6.42999 23.1987 7.38855 23.2 8.57143V21Z"
        fill="#07A4FC"
      />
      <path
        d="M7.99961 6.0001C7.99961 5.76341 7.82052 5.57153 7.59961 5.57153H5.99961C5.7787 5.57153 5.59961 5.76341 5.59961 6.0001C5.59961 6.2368 5.7787 6.42868 5.99961 6.42868H7.59961C7.82052 6.42868 7.99961 6.2368 7.99961 6.0001Z"
        fill="#07A4FC"
      />
      <path
        d="M17.6002 8.5715H19.2002C19.4211 8.5715 19.6002 8.37962 19.6002 8.14293C19.6002 7.90623 19.4211 7.71436 19.2002 7.71436H17.6002C17.3793 7.71436 17.2002 7.90623 17.2002 8.14293C17.2002 8.37962 17.3793 8.5715 17.6002 8.5715Z"
        fill="#07A4FC"
      />
      <path
        d="M21.6004 7.71436H20.8004C20.5795 7.71436 20.4004 7.90623 20.4004 8.14293C20.4004 8.37962 20.5795 8.5715 20.8004 8.5715H21.6004C21.8213 8.5715 22.0004 8.37962 22.0004 8.14293C22.0004 7.90623 21.8213 7.71436 21.6004 7.71436Z"
        fill="#07A4FC"
      />
      <path
        d="M18.4004 9.85704C18.4004 9.62034 18.2213 9.42847 18.0004 9.42847H16.8004C16.5795 9.42847 16.4004 9.62034 16.4004 9.85704C16.4004 10.0937 16.5795 10.2856 16.8004 10.2856H18.0004C18.2213 10.2856 18.4004 10.0937 18.4004 9.85704Z"
        fill="#07A4FC"
      />
      <path
        d="M21.6002 9.42847H19.6002C19.3793 9.42847 19.2002 9.62034 19.2002 9.85704C19.2002 10.0937 19.3793 10.2856 19.6002 10.2856H21.6002C21.8211 10.2856 22.0002 10.0937 22.0002 9.85704C22.0002 9.62034 21.8211 9.42847 21.6002 9.42847Z"
        fill="#07A4FC"
      />
      <path
        d="M21.6 11.1428H18.4C18.1791 11.1428 18 11.3347 18 11.5714C18 11.8081 18.1791 12 18.4 12H21.6C21.8209 12 22 11.8081 22 11.5714C22 11.3347 21.8209 11.1428 21.6 11.1428Z"
        fill="#07A4FC"
      />
      <path
        d="M16.8002 15.8572C16.7418 15.0852 16.4144 14.365 15.883 13.8399L13.683 11.4828L15.4678 9.74447C15.5514 9.66382 15.5995 9.54917 15.6002 9.42862C15.5993 8.58643 15.1381 7.82288 14.4214 7.47708C13.7047 7.13129 12.8653 7.26737 12.2762 7.8249L7.8802 12.1068C7.44852 12.5131 7.20081 13.0987 7.2002 13.7143C7.25855 14.4863 7.58599 15.2065 8.1174 15.7316L10.3174 18.0888L8.53139 19.8258C8.4479 19.9069 8.40026 20.0221 8.4002 20.1429C8.40152 21.3258 9.29617 22.2843 10.4002 22.2858C10.9126 22.2832 11.4052 22.0729 11.779 21.6973C17.8958 15.7402 15.6642 17.9135 16.1718 17.4198C16.5742 17.0146 16.8017 16.4488 16.8002 15.8572ZM12.8074 8.46562C13.1385 8.15327 13.6028 8.06071 14.0165 8.22459C14.4303 8.38847 14.7268 8.78236 14.7886 9.25033L11.0478 12.8932C10.79 12.2283 10.2388 11.7457 9.5794 11.6075L12.8074 8.46562ZM9.2118 20.3212L10.883 18.6948C10.905 18.7376 11.5802 19.3882 11.6002 20.1463C11.5932 20.4974 11.4589 20.8317 11.2258 21.0785C11.0013 21.302 10.7067 21.427 10.4002 21.4286C9.80175 21.4286 9.29478 20.9562 9.2118 20.3212ZM15.6274 16.7915L12.3846 19.9475C12.2762 19.2516 11.9598 18.6123 11.483 18.1256L8.683 15.1256C8.30303 14.7596 8.06034 14.258 8.0002 13.7143C8.00101 13.3437 8.15085 12.9913 8.4114 12.7475C8.62879 12.5409 8.90961 12.4274 9.2002 12.4286C9.86294 12.4286 10.4002 13.0042 10.4002 13.7143C10.4003 13.884 10.4938 14.0375 10.6386 14.1061C10.7834 14.1746 10.9523 14.1452 11.0694 14.031L13.0918 12.0596L15.3174 14.4442C15.6977 14.8106 15.9404 15.3129 16.0002 15.8572C16.0013 16.2103 15.8664 16.5483 15.6274 16.7915Z"
        fill="#07A4FC"
      />
    </SvgIcon>
  );
}
