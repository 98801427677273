import React, { useState } from "react";
import Dialog from "../../components/dialog/dialog";
import { Box, Chip, Grid, Typography } from "@mui/material";
import ControlledInput from "../../components/input/controlled-input";
import Button from "../../components/button/button";
import { push, ref } from "firebase/database";
import { getActiveConId } from "../../redux/userSlice";
import { useSelector } from "react-redux";
import { getUid } from "../../redux/authSlice";
import { firebaseDb } from "../../firebase/app";
import TextEditor from "../../components/text-editor/text-editor";

export default function SendEmailModal({ open, handleClose }: any) {
  const [toEmail, setToEmail] = useState("");
  const [CC, setCC] = useState("");
  const [CCs, setCCs]: string[] | any = useState([]);
  const [emailTitle, setEmailTitle] = useState("");
  const [content, setContent] = useState("");
  const uid = useSelector(getUid);
  const activeConId = useSelector(getActiveConId);

  const renderTitle = "New Mail";

  const sendMessageHandler = () => {
    const emailList = [toEmail, ...CCs];
    // Define a Set of strings
    const mySets: Set<string> = new Set(emailList);
    // Convert Set to Array using Array.from()
    const uniqueArrays: string[] = Array.from(mySets);

    const data = {
      emailContent: content.trim(),
      receive: false,
      read: true,
      sender: "Sender",
      toEmail: uniqueArrays,
      emailTitle,
      service: {
        SMS: false,
        email: true,
        widget: false,
      },
    };
    push(ref(firebaseDb, `messages/${uid}/${activeConId}`), data);
    setToEmail("");
    setCC("");
    setCCs("");
    setEmailTitle("");
    setContent("");
    handleClose();
  };

  const checkButton = () => {
    if (toEmail === "") return true;
    else if (emailTitle === "") return true;
    else if (content === "") return true;
    else return false;
  };

  const handleKeyDown = (event: { key: string; target: { value: any } }) => {
    if (event.key === "Enter") {
      const value = event?.target.value;
      setCCs((prevArray: any) => [...prevArray, value]);
      setCC("");
    }
  };

  const handleDelete = (index: number) => {
    setCCs((prevItems: string[]) => prevItems.filter((_: string, i: number) => i !== index));
  };

  const renderContent = (
    <Box>
      <ControlledInput
        startAdornment={<Typography color={"gray"}>To: </Typography>}
        value={toEmail}
        onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
          setToEmail(e.target.value)
        }
      />
      <ControlledInput
        value={CC}
        startAdornment={<Typography color={"gray"}>Cc: </Typography>}
        onChange={(e: { target: { value: React.SetStateAction<string> } }) => setCC(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      {CCs?.length > 0 &&
        CCs?.map((c: string, id: number) => (
          <Box component={"span"} key={id}>
            <Chip label={c} onDelete={() => handleDelete(id)} sx={{ mb: 1 }} />
          </Box>
        ))}
      <ControlledInput
        value={emailTitle}
        placeholder="Title:"
        onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
          setEmailTitle(e.target.value)
        }
      />
      <TextEditor
        value={content}
        onChange={(value: React.SetStateAction<string>) => setContent(value)}
      />
    </Box>
  );

  const renderActions = (
    <Box display={"flex"} justifyContent={"center"} width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Button variant="outlined" sx={{ width: "100%" }}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={8}>
          <Button
            variant="contained"
            sx={{ width: "100%" }}
            onClick={sendMessageHandler}
            disabled={checkButton()}
          >
            Send Email
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      fullWidth
      renderContent={renderContent}
      renderTitle={renderTitle}
      renderActions={renderActions}
    />
  );
}
