import lottie from "lottie-web";
import React, { memo, useEffect, useRef } from "react";

function Animation({ animationData, autoplay, loop, style, ...rest }: any) {
  const container: any = useRef();

  useEffect(() => {
    lottie.loadAnimation({
      animationData,
      autoplay,
      container: container.current,
      loop,
      renderer: "svg",
    });

    return () => {
      lottie.destroy();
    };
  }, [animationData, autoplay, loop, style]);

  return <div ref={container} style={style} {...rest} />;
}

export default memo(Animation);
