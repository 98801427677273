function formatTimeFromTimestamp(timestamp: any) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedHours = (hours % 12 === 0 ? 12 : hours % 12).toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const period = hours < 12 ? "AM" : "PM";

  return `${formattedHours}:${formattedMinutes} ${period}`;
}

export function formatDateFrom(timestamp: any) {
  const date = new Date(timestamp);

  // Format the date and time
  return `${date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })} - ${date.toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric" })}`;
}

function formatMonthFromTimestamp(timestamp: any) {
  const dateObj = new Date(timestamp);
  const options: any = {
    month: "long",
    day: "numeric",
    timeZone: getUserTimeZone(),
  };
  return dateObj.toLocaleString("en-US", options);
}

function getUserTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function convertTimestamp(timestamp: any) {
  const dateObj = new Date(timestamp);
  const options: any = {
    weekday: "long",
    // year: 'numeric',
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    // second: 'numeric',
    timeZone: getUserTimeZone(),
  };
  return dateObj.toLocaleString("en-US", options);
}

const isToday = (timestamp: any) => {
  const today = new Date();
  const date = new Date(timestamp);
  return today.toDateString() === date.toDateString();
};

const sortUsersByTimestampRange = (users: any[]) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const sortedUsers: any = {
    today: [],
    yesterday: [],
    previous7days: [],
    previous30days: [],
    previousMonth: [],
  };

  users.forEach((user) => {
    const lastMessageTimestamp = new Date(user?.lastMessage?.timestamp);

    if (lastMessageTimestamp.toDateString() === today.toDateString()) {
      sortedUsers.today.push(user);
    } else if (lastMessageTimestamp.toDateString() === yesterday.toDateString()) {
      sortedUsers.yesterday.push(user);
    } else if (lastMessageTimestamp > sevenDaysAgo && lastMessageTimestamp <= yesterday) {
      sortedUsers.previous7days.push(user);
    } else if (lastMessageTimestamp > thirtyDaysAgo && lastMessageTimestamp <= yesterday) {
      sortedUsers.previous30days.push(user);
    } else if (lastMessageTimestamp < firstDayOfCurrentMonth) {
      sortedUsers.previousMonth.push(user);
    }
  });

  return sortedUsers;
};

export {
  isToday,
  formatTimeFromTimestamp,
  convertTimestamp,
  formatMonthFromTimestamp,
  sortUsersByTimestampRange,
};
