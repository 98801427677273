import { Alert, Box, OutlinedInput, Typography } from "@mui/material";
import Dialog from "../../components/dialog/dialog";
import React, { useState } from "react";
import { push, ref } from "firebase/database";
import { firebaseDb } from "../../firebase/app";
import { getUid } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import Snackbar from "../../components/snackbar/snackbar";
import Button from "../../components/button/button";

export default function ContactUs({ open, handleClose }: any) {
  const uid = useSelector(getUid);

  const [message, setMessage] = React.useState({
    text: "",
    type: "",
    open: false,
  });
  const [state, setState] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    content: "",
  });

  const handleChange = ({ currentTarget: input }: any) => {
    const value = String(input.value);
    const { name } = input;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    push(ref(firebaseDb, `/contactUs/${uid}`), state)
      .then(() => {
        handleClose();
        setMessage({
          text: "success",
          type: "",
          open: true,
        });
      })
      .catch((e) => {
        setMessage({
          text: e.message,
          type: "error",
          open: true,
        });
        handleClose();
      });
  };
  const renderTitle = "Contact Us";
  const renderContent = (
    <Box display={"grid"} gap={"15px"} mx={"auto"} my={"1rem"} width={"400px"}>
      <Typography variant="body2" fontWeight={700}>
        Here at Drivee AI, Our Contact Us section allows you to easily reach out to us by phone, in
        person, or through a convenient online form.
      </Typography>
      <Box bgcolor={(theme) => theme.palette.background.default} p={1}>
        <Box display={"flex"} gap={1}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <label htmlFor="full name*">Full name</label>
            <OutlinedInput
              id="name"
              name="name"
              placeholder="Enter your name"
              value={state.name}
              onChange={handleChange}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <label htmlFor="phoneNumber*">Phone Number</label>
            <OutlinedInput
              id="phoneNumber"
              placeholder="Enter your phone"
              type="number"
              value={state.phoneNumber}
              onChange={handleChange}
              name="phoneNumber"
            />
          </Box>
        </Box>
        <Box display={"flex"} gap={1} my={1}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <label htmlFor="email*">email</label>
            <OutlinedInput
              id="email"
              name="email"
              placeholder="Enter your email"
              value={state.email}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <label htmlFor="content*">Detail</label>
          <OutlinedInput
            id="content"
            placeholder="Enter your content"
            value={state.content}
            onChange={handleChange}
            name="content"
            multiline
            rows={4}
          />
        </Box>
      </Box>
      <Alert variant="filled" severity="info">
        For Immediate/Emergency assistance please call us at 888-288-8856.
      </Alert>
    </Box>
  );
  const renderActions = (
    <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
      <Button onClick={handleClose} variant="outlined">
        Cancel
      </Button>
      <Button onClick={handleSubmit} variant="contained">
        Submit
      </Button>
    </Box>
  );
  return (
    <>
      <Dialog
        open={open}
        handleClose={handleClose}
        renderTitle={renderTitle}
        renderContent={renderContent}
        renderActions={renderActions}
      />
      {message.open && (
        <Snackbar
          handleClose={() => setMessage({ text: "", open: false, type: "" })}
          message={message.text}
          open={message.open}
          type={message.type}
        />
      )}
    </>
  );
}
