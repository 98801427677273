import { Box, Typography } from "@mui/material";
import React from "react";
import { CustomBox } from "../../components/box/custom-box";
import CustomizedSwitches from "../../components/switch/customize-switch";
import CustomizeBox from "../../components/customize-box";
import { useResponsive } from "../../hooks/use-media-query";

export default function AISetting({ AIMode, handleChange, isFollow }: any) {
  const { isTabletAndMobile } = useResponsive();

  const renderAISetting = (
    <CustomizeBox display={"flex"} flexDirection={"column"} borderRadius={1} p={1} width={"100%"}>
      {!isTabletAndMobile && (
        <Typography variant="body2" fontWeight={"500"} color={"#8F92A1"} mb={1}>
          A.I. Setting
        </Typography>
      )}

      <CustomBox
        sx={{
          flexDirection: "column",
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"} p={1} alignItems={"center"}>
          <Box>
            <Typography variant="body2" fontWeight={"bold"}>
              A.I. Enabled
            </Typography>
            <Typography color={"#8F92A1"} variant="caption">
              A.I. is ready to respond to this lead
            </Typography>
          </Box>
          <CustomizedSwitches name="AIMode" checked={AIMode} onChange={handleChange} />
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} p={1} alignItems={"center"}>
          <Box>
            <Typography variant="body2" fontWeight={"bold"}>
              A.I. Follow Up
            </Typography>
            <Typography color={"#8F92A1"} variant="caption">
              A.I. will follow up with this lead
            </Typography>
          </Box>
          <CustomizedSwitches name="followUp" checked={isFollow} onChange={handleChange} />
        </Box>
      </CustomBox>
    </CustomizeBox>
  );
  return renderAISetting;
}
