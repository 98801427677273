import { SvgIcon } from "@mui/material";
import React from "react";

export default function ReengagedIcon({ fill }: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M18.3336 11.625C18.1678 11.625 18.0088 11.5592 17.8916 11.4419C17.7744 11.3247 17.7086 11.1658 17.7086 11C17.7086 9.47537 17.2566 7.98496 16.4096 6.71725C15.5626 5.44954 14.3587 4.46148 12.9501 3.87802C11.5416 3.29456 9.99161 3.1419 8.49628 3.43937C7.00096 3.73683 5.62742 4.47105 4.54939 5.54917C4.29098 5.80794 4.05095 6.08445 3.83106 6.37667C3.7316 6.50928 3.58354 6.59694 3.41945 6.62039C3.25535 6.64383 3.08867 6.60112 2.95606 6.50167C2.82345 6.40221 2.73578 6.25415 2.71234 6.09006C2.6889 5.92596 2.7316 5.75928 2.83106 5.62667C3.08682 5.28873 3.36524 4.96855 3.66439 4.66833C4.917 3.4152 6.51307 2.56166 8.25078 2.21564C9.98849 1.86963 11.7898 2.04669 13.4269 2.72443C15.0639 3.40217 16.4633 4.55015 17.4479 6.02319C18.4326 7.49623 18.9583 9.22818 18.9586 11C18.9586 11.1658 18.8927 11.3247 18.7755 11.4419C18.6583 11.5592 18.4993 11.625 18.3336 11.625Z"
        fill={fill}
      />
      <path
        d="M9.99984 19.9583C8.82335 19.9586 7.65835 19.727 6.57137 19.2769C5.4844 18.8267 4.49675 18.1669 3.66485 17.335C2.83295 16.5031 2.17309 15.5154 1.72297 14.4285C1.27285 13.3415 1.04129 12.1765 1.0415 11C1.0415 10.8342 1.10735 10.6753 1.22456 10.5581C1.34177 10.4408 1.50074 10.375 1.6665 10.375C1.83226 10.375 1.99124 10.4408 2.10845 10.5581C2.22566 10.6753 2.2915 10.8342 2.2915 11C2.29142 12.5246 2.74346 14.015 3.59045 15.2827C4.43745 16.5505 5.64136 17.5385 7.04993 18.122C8.45849 18.7054 10.0085 18.8581 11.5038 18.5606C12.9991 18.2632 14.3726 17.529 15.4507 16.4508C15.709 16.1925 15.9487 15.9162 16.1682 15.6242C16.2709 15.501 16.417 15.422 16.5763 15.4034C16.7356 15.3848 16.896 15.4281 17.0243 15.5243C17.1526 15.6206 17.2391 15.7624 17.2659 15.9205C17.2926 16.0787 17.2577 16.2411 17.1682 16.3742C16.9127 16.7126 16.6343 17.0331 16.3348 17.3333C15.5048 18.1679 14.5176 18.8296 13.4302 19.2802C12.3428 19.7308 11.1769 19.9613 9.99984 19.9583Z"
        fill={fill}
      />
      <path
        d="M17.0709 18.6958C16.9051 18.6958 16.7461 18.63 16.6289 18.5128C16.5117 18.3956 16.4459 18.2366 16.4459 18.0708V16.3392H14.7134C14.5476 16.3392 14.3886 16.2733 14.2714 16.1561C14.1542 16.0389 14.0884 15.8799 14.0884 15.7142C14.0884 15.5484 14.1542 15.3894 14.2714 15.2722C14.3886 15.155 14.5476 15.0892 14.7134 15.0892H17.0709C17.2366 15.0892 17.3956 15.155 17.5128 15.2722C17.63 15.3894 17.6959 15.5484 17.6959 15.7142V18.0708C17.6959 18.2366 17.63 18.3956 17.5128 18.5128C17.3956 18.63 17.2366 18.6958 17.0709 18.6958Z"
        fill={fill}
      />
      <path
        d="M5.28587 6.91084H2.9292C2.76344 6.91084 2.60447 6.84499 2.48726 6.72778C2.37005 6.61057 2.3042 6.4516 2.3042 6.28584V3.92917C2.3042 3.76341 2.37005 3.60444 2.48726 3.48723C2.60447 3.37002 2.76344 3.30417 2.9292 3.30417C3.09496 3.30417 3.25393 3.37002 3.37114 3.48723C3.48835 3.60444 3.5542 3.76341 3.5542 3.92917V5.66084H5.28587C5.45163 5.66084 5.6106 5.72668 5.72781 5.84389C5.84502 5.9611 5.91087 6.12008 5.91087 6.28584C5.91087 6.4516 5.84502 6.61057 5.72781 6.72778C5.6106 6.84499 5.45163 6.91084 5.28587 6.91084Z"
        fill={fill}
      />
      <path
        d="M10 5.375C8.88748 5.375 7.79995 5.7049 6.87492 6.32298C5.94989 6.94107 5.22892 7.81957 4.80318 8.84741C4.37744 9.87524 4.26604 11.0062 4.48309 12.0974C4.70013 13.1885 5.23586 14.1908 6.02253 14.9775C6.8092 15.7641 7.81148 16.2999 8.90262 16.5169C9.99376 16.734 11.1248 16.6226 12.1526 16.1968C13.1804 15.7711 14.0589 15.0501 14.677 14.1251C15.2951 13.2001 15.625 12.1125 15.625 11C15.6232 9.5087 15.03 8.07898 13.9755 7.02447C12.921 5.96996 11.4913 5.37676 10 5.375ZM12.0833 11.625H10C9.83424 11.625 9.67527 11.5592 9.55806 11.4419C9.44085 11.3247 9.375 11.1658 9.375 11V8.5C9.375 8.33424 9.44085 8.17527 9.55806 8.05806C9.67527 7.94085 9.83424 7.875 10 7.875C10.1658 7.875 10.3247 7.94085 10.4419 8.05806C10.5592 8.17527 10.625 8.33424 10.625 8.5V10.375H12.0833C12.2491 10.375 12.4081 10.4408 12.5253 10.5581C12.6425 10.6753 12.7083 10.8342 12.7083 11C12.7083 11.1658 12.6425 11.3247 12.5253 11.4419C12.4081 11.5592 12.2491 11.625 12.0833 11.625Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
