import { SvgIcon } from "@mui/material";
import React from "react";

export default function PhoneIcon({ ...props }: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0967 8.81985C12.9568 8.81985 12.8107 8.77522 12.6708 8.74335C12.3876 8.68077 12.1094 8.59768 11.8382 8.49474C11.2275 8.27193 10.5476 8.54688 10.2618 9.1322L10.122 9.41906C9.50291 9.07367 8.93403 8.64469 8.43127 8.14413C7.93216 7.63991 7.50443 7.06936 7.16004 6.44847L7.427 6.26998C8.01062 5.98339 8.28477 5.30151 8.06261 4.68906C7.96169 4.4165 7.87888 4.13753 7.81472 3.85398C7.78294 3.71374 7.75752 3.56712 7.73845 3.4205C7.57898 2.49285 6.77011 1.81953 5.8316 1.83321H3.92476C3.37046 1.82799 2.84134 2.06494 2.4752 2.48233C2.10905 2.89973 1.94213 3.45626 2.01792 4.00697C2.71451 9.50083 7.03788 13.821 12.5183 14.4997H12.7598C13.2285 14.5004 13.6811 14.3279 14.031 14.0152C14.4378 13.6503 14.6692 13.1282 14.6666 12.5809V10.6685C14.6506 9.75537 13.993 8.98105 13.0967 8.81985ZM13.3333 12.5342C13.3331 12.7143 13.2551 12.8856 13.1192 13.0045C12.9773 13.1275 12.7894 13.1845 12.6027 13.1612C7.75022 12.5419 3.93649 8.72729 3.33735 3.89384C3.3171 3.70824 3.37399 3.52247 3.49482 3.37969C3.61422 3.24438 3.7863 3.16673 3.96722 3.1665H5.85682C6.15896 3.15981 6.42329 3.36769 6.48669 3.66185C6.51188 3.83323 6.54337 4.00253 6.58116 4.16973C6.65393 4.50027 6.75076 4.82509 6.8709 5.14162L5.98909 5.54918C5.83681 5.61874 5.71864 5.74582 5.66072 5.90232C5.6028 6.05882 5.6099 6.23185 5.68046 6.38312C6.58696 8.31608 8.1478 9.86987 10.0895 10.7723C10.2429 10.835 10.4149 10.835 10.5682 10.7723C10.7269 10.7158 10.8562 10.5983 10.9272 10.4462L11.3178 9.5684C11.6434 9.68432 11.9757 9.78064 12.313 9.85683C12.4809 9.89445 12.651 9.9258 12.8231 9.95088C13.1186 10.014 13.3275 10.2771 13.3207 10.5779L13.3333 12.5342Z"
        fill="#07A4FC"
      />
    </SvgIcon>
  );
}
