import { SvgIcon } from "@mui/material";
import React from "react";

export default function HotIcon({ fill }: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
    >
      <path
        d="M16.4031 6.51167C13.4514 1.17 4.7889 0 4.7889 0C4.7889 0 8.78724 4.45 3.67724 7.9725C0.448071 10.1942 -0.831096 13.0808 0.552237 16.2342C1.61057 18.65 3.85224 19.6308 6.26974 20C5.6489 18.805 5.49307 17.0792 5.95224 15.31C5.99807 15.1333 6.0489 14.9483 6.10724 14.7808C6.78474 15.8292 7.8964 16.4025 8.9814 16.1375C10.4672 15.7842 11.3389 13.9867 10.9331 12.1392C10.8406 11.7383 10.6897 11.3533 10.4864 10.9958C10.5447 11.01 10.6031 11.015 10.6631 11.035C12.5172 11.5642 13.4139 14.3367 12.6656 17.2308C12.4439 18.1075 12.0697 18.9383 11.5606 19.685C14.7439 18.66 16.8614 16.0925 17.4439 13.805C18.0581 11.4242 17.5922 8.65 16.4031 6.51167Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
