import React, { useState } from "react";
import { Popover } from "@mui/material";
import { firebaseDb } from "../../firebase/app";
import { push, ref } from "firebase/database";
import { useSelector } from "react-redux";
import { getActiveConId } from "../../redux/userSlice";
import { getUid } from "../../redux/authSlice";
import BasicDateTimePicker from "../../components/picker/date-time-picker";

export default function ScheduleMessage({ anchorEl, handleClose, value, setMessage }: any) {
  const conId = useSelector(getActiveConId);
  const uId = useSelector(getUid);
  const [timestamp, setTimestamp] = useState(0);

  const handleSend = () => {
    const data = {
      content: value,
      conversationId: conId,
      service: "email",
      timestamp: timestamp,
      status: 0,
    };
    push(ref(firebaseDb, `/scheduledMessage/${uId}`), data)
      .then(() => {
        handleClose();
        setMessage("");
      })
      .catch();
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: "16px",
          },
        },
      }}
    >
      <BasicDateTimePicker onSend={handleSend} onTimestamp={setTimestamp} />
    </Popover>
  );
}

// import React, { useState } from "react";
// import { Box, Popover, SelectChangeEvent, Typography } from "@mui/material";
// import Button from "../../components/button/button";
// import { firebaseDb } from "../../firebase/app";
// import { push, ref } from "firebase/database";
// import { useSelector } from "react-redux";
// import { getActiveConId } from "../../redux/userSlice";
// import { getUid } from "../../redux/authSlice";
// import Selector from "../../components/select/select";
// import { generateClock } from "../../utils/utils";
// import BasicDateTimePicker from "./new-schedule-message";

// export default function ScheduleMessage({ anchorEl, handleClose }: any) {
//   const [day, setDay] = React.useState("Today");
//   const [clock, setClock] = React.useState("0:00");
//   const [dayStatus, setDayStatus] = React.useState("am");
//   const conId = useSelector(getActiveConId);
//   const uId = useSelector(getUid);
//   const [timestamp, setTimestamp] = useState(0);
//   const [open, setOpen] = useState(false);

//   // const handleOpen = () => setOpen(!open);
//   // const handleChange = (event: SelectChangeEvent) => {
//   //   setDay(event.target.value as string);
//   //   if (event.target.value === "Other") handleOpen();
//   // };

//   const handleSend = () => {
//     // let hours = clock.split(":").map(Number)[0];
//     // const minutes = clock.split(":").map(Number)[1];
//     // const today = new Date();
//     // const currentTimestamp = Date.now();
//     // if (day === "Today") {
//     //   if (dayStatus.toLowerCase() === "pm" && hours < 12) {
//     //     hours += 12;
//     //   } else if (dayStatus.toLowerCase() === "am" && hours === 12) {
//     //     hours = 0;
//     //   }
//     //   today.setHours(hours);
//     //   today.setMinutes(minutes);
//     //   today.setSeconds(0);
//     //   today.setMilliseconds(0);
//     //   const timestamp = today.getTime();
//     //   setTimestamp(timestamp);
//     //   if (timestamp < currentTimestamp) alert("Time has been past");
//     // } else if (day === "Tomorrow") {
//     //   // Create a new Date object for tomorrow
//     //   const tomorrow = new Date(today);
//     //   tomorrow.setDate(today.getDate() + 1);
//     //   tomorrow.setHours(hours);
//     //   tomorrow.setMinutes(minutes);
//     //   tomorrow.setSeconds(0);
//     //   tomorrow.setMilliseconds(0);
//     //   const timestamp = tomorrow.getTime();
//     //   setTimestamp(timestamp);
//     //   if (timestamp < currentTimestamp) alert("Time has been past");
//     // } else if (day === "A week later") {
//     //   const nextWeek = new Date(today);
//     //   nextWeek.setDate(today.getDate() + 7);
//     //   nextWeek.setDate(today.getDate() + 1);
//     //   nextWeek.setHours(hours);
//     //   nextWeek.setMinutes(minutes);
//     //   nextWeek.setSeconds(0);
//     //   nextWeek.setMilliseconds(0);
//     //   const timestamp = nextWeek.getTime();
//     //   setTimestamp(timestamp);
//     //   if (timestamp < currentTimestamp) alert("Time has been past");
//     // }
//     const data = {
//       content: "test",
//       conversationId: conId,
//       service: "email",
//       timestamp: timestamp,
//       status: 0,
//     };
//     push(ref(firebaseDb, `/scheduledMessage/${uId}`), data)
//       .then(() => handleClose())
//       .catch();
//   };

//   return (
//     <Popover
//       open={Boolean(anchorEl)}
//       anchorEl={anchorEl}
//       onClose={handleClose}
//       anchorOrigin={{
//         vertical: "bottom",
//         horizontal: "center",
//       }}
//       transformOrigin={{
//         vertical: "top",
//         horizontal: "center",
//       }}
//       slotProps={{
//         paper: {
//           sx: {
//             borderRadius: "16px",
//           },
//         },
//       }}
//     >
//       {open && <BasicDateTimePicker onSend={handleSend} onTimestamp={setTimestamp} open={open} />}
//       {/* <Box p={1} m={1} width={"20rem"} gap={1} display={"flex"} flexDirection={"column"}>
//         <Box>
//           <Typography variant="body1" fontWeight={500}>
//             Schedule Message
//           </Typography>
//           <Typography variant="body1" color={"#686868"}>
//             To send, please select a time.
//           </Typography>
//         </Box>
//         <Box display="flex" alignItems="center" gap={2}>
//           <Selector
//             data={["Today", "Tomorrow", "A week later", "Other"]}
//             width={"100%"}
//             value={day}
//             onChange={handleChange}
//           />
//           <Box ml={1}>
//             <Box display={"flex"} gap={1}>
//               <Selector
//                 data={generateClock()}
//                 width={85}
//                 value={clock}
//                 onChange={(e: SelectChangeEvent) => setClock(e.target.value)}
//               />
//               <Selector
//                 data={["am", "pm"]}
//                 width={85}
//                 value={dayStatus}
//                 onChange={(e: SelectChangeEvent) => setDayStatus(e.target.value)}
//               />
//             </Box>
//           </Box>
//         </Box>
//         <Box display={"flex"} justifyContent={"space-between"}>
//           <Button variant="outlined">Cancel</Button>
//           <Button variant="contained" onClick={handleSend}>
//             Save
//           </Button>
//         </Box>
//       </Box> */}
//     </Popover>
//   );
// }
