import {
  Avatar,
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getData } from "../../utils/firebase";

export default function Lead({ data, id, leadsNum }: any) {
  const [name, setName] = useState("");
  const [logoLink, setLogoLink] = useState("");

  useEffect(() => {
    getData(`front/adPlatforms/${data.key}`).then((r: any) => {
      setLogoLink(r?.logoLink);
      setName(r?.name);
    });
  }, [data]);

  return (
    <ListItem
      disablePadding
      secondaryAction={<Typography variant="h6">{data.count}</Typography>}
      sx={{ bgcolor: (theme) => (id % 2 === 0 ? "" : theme.palette.primary.contrastText), pl: 1 }}
    >
      <ListItemAvatar>
        <Avatar
          src={logoLink}
          variant="rounded"
          sx={{
            "& img": {
              border: "2px solid #333",
              objectFit: "contain",
            },
          }}
        />
      </ListItemAvatar>
      <ListItemText primary={name} secondary={`#${id + 1}`} />
      <Box display={"flex"} mt={5} mr={1}>
        <Typography variant="caption" color={"green"}>
          {((data.count / leadsNum) * 100).toFixed(2)}%
        </Typography>
      </Box>
      <Divider />
    </ListItem>
  );
}
