import { Container } from "@mui/material";
import React from "react";

const TrainingRequest = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{ bgcolor: (theme) => theme.palette.background.paper, borderRadius: 4, height: "100dvh" }}
    >
      <iframe
        src="https://driveeai.pipedrive.com/scheduler/Ol48v3T8/drivee-ai-advance-sales-agent-training-session"
        title="Pipedrive Scheduler Embed"
        height="100%"
        width="100%"
        style={{
          border: "inherit",
        }}
      />
    </Container>
  );
};

export default TrainingRequest;
