import React, { useEffect, useState } from "react";
import { getData, updateData } from "../../utils/firebase";
import { useSelector } from "react-redux";
import { getUid } from "../../redux/authSlice";
import { Box, Typography } from "@mui/material";
import Dialog from "../../components/dialog/dialog";
import Button from "../../components/button/button";
export default function Notify() {
  const uid = useSelector(getUid);
  const [notification, setNotification]: any = useState();

  useEffect(() => {
    const getNotification = () => {
      getData(`dealers/${uid}/info/notification`).then((r: any) => {
        if (!r) return;
        const notificationEntry = Object.entries(r).find(([key, item]: any) => {
          if (item.role === "system") {
            const updateData = { ...item, key };
            if (!item.read) return updateData;
            const remainedDay = convertTime(item?.timestamp);
            if (remainedDay < item?.seenTimestamp) {
              // Convert timestamps to Date objects
              const date1: any = new Date();
              const date2: any = new Date(item?.seenTimestamp);
              // Calculate the difference in milliseconds
              const differenceInMilliseconds = Math.abs(date2 - date1);
              // Convert milliseconds to days
              const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
              if (differenceInDays > 1) return updateData;
            } else return null;
          }
        });
        // If found, setNotification
        if (notificationEntry) {
          const [key, item]: any = notificationEntry; // Destructure the found entry
          const updatedNotification = { ...item, id: key }; // Create the updated notification
          setNotification(updatedNotification);
        }
      });
    };
    getNotification();
  }, []);

  const handleClose = () => {
    setNotification(null);
    handleData();
  };
  const handleData = () => {
    const currentTimestamp = Date.now();
    const path = `dealers/${uid}/info/notification/${notification?.id}`;
    const data = { seenTimestamp: currentTimestamp, read: true };
    updateData(path, data);
  };
  const convertTime = (timestamp: string) => {
    const futureTimestamp = new Date(timestamp).getTime();
    // Current timestamp
    const currentTimestamp = Date.now(); // or new Date().getTime()
    // Calculate the difference in milliseconds
    const differenceInMilliseconds = futureTimestamp - currentTimestamp;
    // Convert the difference to days
    return Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  };
  const convertText = (text: string) => {
    const differenceInDays = convertTime(notification?.timestamp);
    return text?.replace("{}", String(differenceInDays)); // Replace '{}' with 'differenceInDays'
  };
  const renderTitle = (
    <Typography fontSize={"20px"} fontWeight={700}>
      Contact Support
    </Typography>
  );
  const renderContent = <Typography align="left">{convertText(notification?.text)}</Typography>;
  const renderActions = (
    <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
      <Button onClick={handleClose} variant="outlined">
        Cancel
      </Button>
      <Button onClick={handleClose} variant="contained">
        ok
      </Button>
    </Box>
  );
  return (
    <Dialog
      open={Boolean(notification)}
      handleClose={handleClose}
      fullWidth
      renderContent={renderContent}
      renderTitle={renderTitle}
      renderActions={renderActions}
    />
  );
}
