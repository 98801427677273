import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextEditor from "../../components/text-editor/text-editor";
import Button from "../../components/button/button";
import { push, ref } from "firebase/database";
import { useSelector } from "react-redux";
import { getUid } from "../../redux/authSlice";
import { getActiveConId } from "../../redux/userSlice";
import { firebaseDb } from "../../firebase/app";
import { getData } from "../../utils/firebase";
import MessageWithClickableLink from "../../utils/message-with-clickable-link";
import { formatMonthFromTimestamp } from "../../utils/time-convert";

export default function Note() {
  const [content, setContent] = useState("");
  const uid = useSelector(getUid);
  const activeConId = useSelector(getActiveConId);
  const [notes, setNotes] = useState({});

  const getNotes = () => {
    getData(`conversationNote/${uid}/${activeConId}`)
      .then((res: any) => setNotes(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getNotes();
  }, []);

  const onSubmit = () => {
    const timestamp = Date.now();
    const data = {
      timestamp: timestamp,
      content: content,
    };
    push(ref(firebaseDb, `conversationNote/${uid}/${activeConId}`), data)
      .then(() => {
        getNotes();
        setContent("");
      })
      .catch();
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-around"}
      height={"100%"}
      overflow={"hidden"}
    >
      <Box
        display={"flex"}
        flex={1}
        flexDirection={"column"}
        sx={{
          overflowY: "scroll",
        }}
        p={1}
      >
        <Typography variant="body1" fontWeight={700}>
          Previous Notes
        </Typography>
        {notes &&
          Object.values(notes).map((r: any, key) => (
            <Box key={key}>
              <Box border={"1px solid rgba(0, 0, 0, 0.05)"} borderRadius={3} p={1}>
                <MessageWithClickableLink messageContent={r?.content} />
              </Box>
              <Box display={"flex"} justifyContent={"end"}>
                <Typography variant="caption" color={"#99B2C6"} fontWeight={500}>
                  {formatMonthFromTimestamp(r.timestamp)}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
      <Box gap={1} display={"grid"}>
        <Typography variant="body1" fontWeight={700}>
          New Note
        </Typography>
        <TextEditor
          value={content}
          onChange={(value: React.SetStateAction<string>) => setContent(value)}
        />
        <Box display={"flex"} justifyContent={"center"} gap={1}>
          <Button variant="outlined">Cancel</Button>
          <Button variant="contained" onClick={onSubmit}>
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
