import { SvgIcon } from "@mui/material";
import React from "react";

export default function StarFillIcon({ fill }: any) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1043 4.67701L14.9317 8.32776C15.1108 8.68616 15.4565 8.93467 15.8573 8.99218L19.9453 9.58062C20.9554 9.72644 21.3573 10.9505 20.6263 11.6519L17.6702 14.4924C17.3797 14.7718 17.2474 15.1733 17.3162 15.5676L18.0138 19.5778C18.1856 20.5698 17.1298 21.3267 16.227 20.8574L12.5732 18.9627C12.215 18.7768 11.786 18.7768 11.4268 18.9627L7.773 20.8574C6.87023 21.3267 5.81439 20.5698 5.98724 19.5778L6.68385 15.5676C6.75257 15.1733 6.62033 14.7718 6.32982 14.4924L3.37368 11.6519C2.64272 10.9505 3.04464 9.72644 4.05466 9.58062L8.14265 8.99218C8.54354 8.93467 8.89028 8.68616 9.06937 8.32776L10.8957 4.67701C11.3477 3.77433 12.6523 3.77433 13.1043 4.67701Z"
        fill={fill}
        stroke={fill}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
