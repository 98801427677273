import React, { useEffect, useMemo, useState } from "react";
import ControlledBox from "../../components/box/controlled-box";
import { Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import Button from "../../components/button/button";
import axios from "axios";
import { useSelector } from "react-redux";
import { getToken, getUid } from "../../redux/authSlice";
import { getData } from "../../utils/firebase";
import Snackbar from "../../components/snackbar/snackbar";
import { transformName } from "../../utils/utils";

const initialState = {
  ace_spfi: false,
  appointments: false,
  auto_list: false,
  auto_source: false,
  buy_sell_auto_mart: false,
  car_soup: false,
  car_zing: false,
  cars: false,
  cars_for_sale: false,
  cars_inc: false,
  claz: false,
  craige_list: false,
  dealer_assistan_request: false,
  facebook_marketplacet: false,
  first_responder: false,
  hot_leads: false,
  james_edition: false,
  kbb_listing: false,
  manual_entry: false,
  msn_autos: false,
  offer_up: false,
  true_cars: false,
  v12: false,
};

export default function Lead() {
  const [leadData, setLeadData] = useState(initialState);
  const token = useSelector(getToken);
  const uid = useSelector(getUid);
  const [notify, setNotify] = useState({ message: "", open: false, type: "" });

  const sendData = async () => {
    // Create an Axios instance with a base URL
    const apiClient = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL, // Replace with your base URL
      headers: {
        Authorization: ` ${token}`, // Replace with your actual token
        "Content-Type": "application/json",
      },
    });
    const value = [
      {
        section: "leads_source",
        data: leadData,
      },
    ];
    try {
      await apiClient.post("", value);
      setNotify({ message: "success", open: true, type: "" });
    } catch (error: any) {
      setNotify({ message: error.response?.data?.error, open: true, type: "error" });
    }
  };

  const getLeadData = async () => {
    getData(`dealers/${uid}/info/lead_source`).then((res: any) => {
      if (res) {
        // Check if the extra_sources exists and remove it
        Object.keys(res).forEach((key) => {
          if (key === "extra_sources") {
            delete res[key];
          }
        });
        setLeadData(res);
      }
    });
  };

  useEffect(() => {
    getLeadData();
  }, []);

  const updateLeadData = (source: string) => {
    setLeadData((prevData: any): any => {
      if (!prevData) return {}; // Return an empty object if prevData is undefined

      const updatedData: any = { ...prevData }; // Start with a copy of prevData

      // Check if source exists in the main object
      if (Object.prototype.hasOwnProperty.call(prevData, source)) {
        // Toggle the value of the source key
        updatedData[source] = !prevData[source];
      }
      // Check if source exists in extra_sources
      else if (
        prevData?.extra_sources &&
        Object.prototype.hasOwnProperty.call(prevData.extra_sources, source)
      ) {
        // Toggle the value of the source key in extra_sources
        updatedData.extra_sources = {
          ...prevData.extra_sources,
          [source]: !prevData.extra_sources[source],
        };
      }

      return updatedData; // Return the updated data
    });
  };

  const renderLead = useMemo(
    () =>
      leadData &&
      Object.entries(leadData).map(([key, item]: any) => {
        return (
          <Grid item md={4} xs={6} key={key}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <FormControlLabel
                onChange={() => updateLeadData(key)}
                control={<Checkbox checked={item} />}
                label=""
              />
              <Typography variant="body1">{transformName(key)}</Typography>
            </Box>
          </Grid>
        );
      }),
    [leadData]
  );

  return (
    <>
      <ControlledBox>
        <Box>
          <Typography color={"#505050"} variant="h6">
            Leads Source
          </Typography>
          <Typography color={"#9B9B9B"} variant="body1">
            Control how this number works a differ times of day
          </Typography>
        </Box>
        <Grid container spacing={1} my={2}>
          {renderLead}
        </Grid>
      </ControlledBox>
      <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
        <Button variant="outlined" color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={sendData}>
          Save Change
        </Button>
      </Box>
      <Snackbar
        handleClose={() => setNotify({ message: "", open: false, type: "" })}
        message={notify.message}
        open={notify.open}
        type={notify.type}
      />
    </>
  );
}
