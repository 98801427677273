import { FormControl, Input as MuiInput, Typography } from "@mui/material";

import React, { memo } from "react";

function Textarea({ error, fullWidth = true, label, sx, ...rest }: any) {
  return (
    <FormControl fullWidth={fullWidth} sx={{ mb: 1 }}>
      {label && (
        <Typography color={error ? "red" : "#2F2F2F"} fontWeight={500} mb={1} variant="body1">
          {label}
        </Typography>
      )}
      <MuiInput
        multiline
        variant="outlined"
        sx={{
          background: (theme) => theme.palette.background.paper,
          borderRadius: 2,
          fontSize: "small",
          padding: (theme) => theme.spacing(1, 2),
          border: "1px solid #E5E5E5",
          ...sx, // Merge the sx prop here
        }}
        disableUnderline
        {...rest}
      />
      {error && <Typography fontSize={"small"}>{error}</Typography>}
    </FormControl>
  );
}

export default memo(Textarea);
